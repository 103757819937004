import Http from '../core/http/http.service';
import UpdatePointOfSaleCustomLineItemWebRequest = Vertica.LouisPoulsen.Application.Commerce.Requests.UpdatePointOfSaleCustomLineItemWebRequest;
import AddServiceToBasketWebRequest = Vertica.LouisPoulsen.Application.Commerce.Requests.AddServiceToBasketWebRequest;
import UpdatePointOfSaleLineItemWebRequest = Vertica.LouisPoulsen.Application.Commerce.Requests.UpdatePointOfSaleLineItemWebRequest;
import UpdatePointOfSaleShippingDiscountWebRequest = Vertica.LouisPoulsen.Application.Commerce.Requests.UpdatePointOfSaleShippingDiscountWebRequest;

class PointOfSaleBasketService {
    public updateLineItem(request: UpdatePointOfSaleLineItemWebRequest) {
        return Http.post('basketpos/updateLineItem', request);
    }

    public updateShippingDiscount(request: UpdatePointOfSaleShippingDiscountWebRequest) {
        return Http.post('basketpos/updateShippingDiscount', request);
    }

    public addService(request: AddServiceToBasketWebRequest) {
        return Http.post('basketpos/addService', request);
    }

    public updateCustomLineItem(request: UpdatePointOfSaleCustomLineItemWebRequest) {
        return Http.post('basketpos/updateCustomLineItem', request);
    }
}

export default new PointOfSaleBasketService();
