var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_vm.to ? 'router-link' : _vm.href ? 'a' : 'button',{tag:"component",staticClass:"lpbutton c-btn u-pointer-events-auto",class:{
               'u-flex u-items-center u-justify-center': _vm.centered,
               'c-btn--primary': !_vm.color && !_vm.ghost && !_vm.invert,
               'c-btn--primary-inverse': !_vm.color && _vm.invert && !_vm.ghost,
               'c-btn--ghost': !_vm.color && _vm.ghost && !_vm.invert,
               'c-btn--ghost-inverse': !_vm.color && _vm.ghost && _vm.invert,
               'c-btn--inherit': _vm.color && !_vm.ghost && !_setup.isTooBright,
               'c-btn--transparent-inherit': _vm.color && _vm.ghost && !_setup.isTooBright,
               'c-btn--inherit-inverse': _vm.color && !_vm.ghost && _setup.isTooBright,
               'c-btn--transparent-inherit-inverse': _vm.color && _vm.ghost && _setup.isTooBright,
               'is-spinning': _vm.spinner,
               'u-border-transparent': !_vm.border
           },style:({'color': _vm.color}),attrs:{"to":_vm.to,"href":_vm.href,"target":_vm.target,"disabled":!!(_vm.disabled || _vm.spinner)},on:{"click":function($event){_vm.spinner ? _vm.$emit('clickWhileSpinning') : _vm.$emit('click')}},nativeOn:{"click":function($event){_vm.spinner ? _vm.$emit('clickWhileSpinning') : _vm.$emit('click')}}},[(_vm.icon)?_c('span',{staticClass:"c-btn__icon u-text-icon u-text-current-color",class:{
              'u-w-half': !_setup.hasText && _vm.icon && _vm.iconRight,
              'lpbutton-icon-left__bordered': _setup.hasText,
              'u-text-icon': !_setup.hasText
          }},[(_vm.isSvgIcon)?_c(_vm.icon,{tag:"component"}):_c('i',{staticClass:"material-icons",domProps:{"innerHTML":_vm._s(_vm.icon)}})],1):_vm._e(),(_setup.hasText)?_c('span',{class:{
              'u-text-left md:u-text-center': !_vm.icon && _vm.iconRight,
              'u-flex-grow': _vm.icon
          },attrs:{"data-epi-property-name":_vm.epiPropertyName,"data-epi-property-edittype":_vm.epiPropertyEdittype}},[_vm._t("default")],2):_vm._e(),(_vm.iconRight)?_c('span',{staticClass:"c-btn__icon u-text-icon --icon-right u-text-current-color",class:{
              'u-w-half': !_setup.hasText && _vm.icon && _vm.iconRight,
              'u-right-0': !_setup.hasText && _vm.icon && _vm.iconRight,
              'u-inset-x-0': !_setup.hasText && !_vm.icon,
              'u-ml-2': _setup.hasText
          }},[(_vm.iconRight === 'ruler')?_c('icon-base',{attrs:{"width":28,"height":28}},[_c('icon-ruler')],1):_c('i',{staticClass:"material-icons",domProps:{"innerHTML":_vm._s(_vm.iconRight)}})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }