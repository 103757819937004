
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class SelectCtrlMultipleOption extends Vue {
    @Prop({
        type: [String, Object],
        required: true
    }) option: string | object;

    @Prop({
        type: String,
        default: null,
        required: false
    }) identificationKey: string | null;

    @Prop({
        type: String,
        default: null,
        required: false
    }) displayKey: string | null;

    @Prop({
        type: Array,
        default: () => [],
        required: false
    }) selectedOptions: string[] | object[] | null;

    @Prop({
        type: Boolean,
        default: false,
        required: false
    }) isSelectAllOption: boolean;

    @Prop({
        type: Boolean,
        default: null,
        required: false
    }) isSelectedOverride: boolean | null;

    get optionIdentificationValue(): string | any[] | number {
        return this.option[this.identificationKey] as string;
    }

    get optionDisplayValue(): string {
        return this.option[this.displayKey] as string;
    }

    get indexInSelection(): number {
        // "this.selectedOptions" is null when using v-model with a default value of null.
        if (this.selectedOptions === null) {
            return -1;
        }
        if (this.identificationKey) {
            // When using "identificationKey" the array of selections will only be the value(s) that correspond to that key
            return this.selectedOptions.findIndex(selection => selection === this.optionIdentificationValue);
        }
        if (this.displayKey) {
            return this.selectedOptions.findIndex(selection => selection[this.displayKey] === this.optionDisplayValue);
        }
        return this.selectedOptions.findIndex(selection => selection === this.optionDisplayValue);
    }

    get isSelected(): boolean {
        return this.isSelectedOverride || this.indexInSelection >= 0;
    }

    mounted(): void {
        this.$parent.$el.addEventListener('keydown', this.keydownEvent);
    }

    beforeDestroy(): void {
        this.$parent.$el.removeEventListener('keydown', this.keydownEvent);
    }

    keydownEvent(ev: KeyboardEvent): void {
        if (ev.key !== 'Enter') {
            return;
        }
        if (this.$el.parentElement.classList.contains('vs__dropdown-option--highlight')) {
            this.onClick();
        }
    }

    onClick(): void {
        if (this.identificationKey !== null) {
            this.$emit('optionClicked', this.optionIdentificationValue, this.indexInSelection);
            return;
        }
        // With the current setup with vue-select. if options is only a string value, they get interpreted to be an object with a "key" of null and a "value" of the option's string value (eg. {null: '1234'})
        if (this.displayKey === null) {
            this.$emit('optionClicked', this.optionDisplayValue, this.indexInSelection);
            return;
        }
        this.$emit('optionClicked', this.option, this.indexInSelection);
    }
}
