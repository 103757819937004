import Vue from "vue";

class Emitter {

    private events: any = {};
    private empty: any = [];

    public on(type: string, handler: (data: any) => void, ctx?: string) {
        (this.events[type] = this.events[type] || []).push([handler, ctx]);
    }

    public emit(type: string, data?: any) {
        const e = this.events[type] || this.empty;
        const list = e.length > 0 ? e.slice(0, e.length) : e;
        let i = 0;
        let j;
        // tslint:disable-next-line:no-conditional-assignment
        while (j = list[i++]) { j[0].apply(j[1], this.empty.slice.call(arguments, 1)); }
    }

    // tslint:disable-next-line:ban-types
    public off(type: string, handler: Function) {
        // tslint:disable-next-line:no-unused-expression
        type || (this.events = {});
        const list = this.events[type] || this.empty;
        let i = list.length = handler ? list.length : 0;
        // tslint:disable-next-line:no-unused-expression
        while (i--) { handler === list[i][0] && list.splice(i, 1); }
    }
}
export const emitterEvents = {
    ApiErrorEvent: "ApiErrorEvent",
    ApiSuccessEvent: "ApiSuccessEvent",
    NotificationEvent: "NotificationEvent"
};

export default new Emitter();
