
    import Vue from "vue";
    import Component from "vue-class-component";
    import Masonry from 'masonry-layout';
    import LegacyBrowserService from '../../../core/legacybrowser.service';

    @Component({
        name: "grid-fallback"
    })

    export default class GridFallback extends Vue {

        mounted(): void {
            if (!LegacyBrowserService.isBrowserGridSupported()){
                this.initMasonry();
            }
        }

        private initMasonry(): void{
            const fallbackCSSclass: string = ' -legacy';
            const gridClassSelector: string = 'o-grid';
            const gridItemCSSclassSelector: string = '.o-grid-item';

            let gridElements: any = document.getElementsByClassName(gridClassSelector);

            for (let i = 0; i < gridElements.length; i++) {
                gridElements[i].className += fallbackCSSclass;
            }

            new Masonry('.' + gridClassSelector,  {
                itemSelector: gridItemCSSclassSelector,
                columnWidth: 378,
                horizontalOrder: false
            });
        }
    }


