import Http from "../core/http/http.service";


class GdprformService {
    submitFormOptions(request: GdprFormBlockWebRequest ): Promise<GdprFormBlockWebRequest > {
        return Http.post<GdprFormBlockWebRequest >("gdprformblock/sendgdprform", request);
    }
}

export default new GdprformService();

