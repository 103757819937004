
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { CheckoutGetter, CheckoutAction, BasketGetter } from '../../../store';
import BasketViewModel = Vertica.LouisPoulsen.Application.Commerce.ViewModels.Consumer.BasketViewModel;

declare let Klarna: any;

@Component({
    name: 'paymentProviderKlarna'
})
export default class PaymentProviderKlarna extends Vue {
    @Prop({
        required: true
    })
    public selectedDeliveryMethod: any;

    @CheckoutAction
    public initializeKlarna: () => Promise<InitializeKlarnaSessionViewModel>;

    @CheckoutAction
    public setKlarnaPaymentIdentifier: (identifier) => void;

    @CheckoutGetter
    public getKlarnaToken: string;

    @CheckoutGetter
    public getKlarnaPaymentIdentifier: string;

    @BasketGetter
    public basket: BasketViewModel;

    public klarnaOptions: any = {
        paymentMethods: []
    };

    @Watch('selectedDeliveryMethod')
    updateKlarna() {
        this.initKlarna();
    }

    mounted() {
        const klarnaScript = 'klarna-script';

        if (!document.getElementById(klarnaScript)) {
            const script = document.createElement('script');
            script.id = klarnaScript;
            script.src = 'https://x.klarnacdn.net/kp/lib/v1/api.js';
            script.async = true;
            script.onload = this.initKlarna;
            document.body.appendChild(script);
        } else {
            this.initKlarna();
        }
    }

    private initKlarna() {
        this.initializeKlarna().then(response => {
            this.klarnaOptions = response;
            Klarna.Payments.init({
                client_token: response.klarnaToken
            });
            // Wait for DOM to finish rendering template
            this.$nextTick(() => {
                const identifier = this.getKlarnaPaymentIdentifier ? this.getKlarnaPaymentIdentifier : this.klarnaOptions.paymentMethods[0].identifier;
                this.loadKlarna(identifier);
            });
        });
    }

    public loadKlarna(method: string): void {
        this.setKlarnaPaymentIdentifier(method);
        const klarnaObj = {
            container: '#klarna_container_' + method,
            payment_method_category: method
        };
        const order = this.initKlarnaBasket();
        Klarna.Payments.load(klarnaObj, order, (res) => {
        });
    }

    public showPaymentMethod(identifier): boolean {
        return identifier === this.getKlarnaPaymentIdentifier;
    }

    private initKlarnaBasket(): any {
        if (this.basket) {
            const orderLines = [];

            this.basket.lineItems.forEach((item) => {
                orderLines.push({
                    name: item.productName,
                    quantity: item.quantity,
                    unit_price: this.convertToCent(item.unitPriceRaw),
                    total_amount: this.convertToCent(item.totalRaw),
                    total_tax_amount: this.convertToCent(item.totalVatRaw),
                    total_discount_amount: this.convertToCent(item.totalDiscountAmount),
                    tax_rate: this.convertToCent(this.basket.vatPercent),
                    type: 'physical'
                });
            });

            if (this.basket.shippingPrice > 0) {
                orderLines.push({
                    name: 'Shipping',
                    quantity: 1,
                    unit_price: this.convertToCent(this.basket.shippingPrice),
                    total_amount: this.convertToCent(this.basket.shippingPrice),
                    total_tax_amount: this.convertToCent(this.calculateVat(this.basket.shippingPrice, this.basket.vatPercent)),
                    total_discount_amount: 0,
                    tax_rate: this.convertToCent(this.basket.vatPercent),
                    type: 'shipping_fee'
                });
            }

            return {
                order_amount: this.convertToCent(this.basket.totalRaw),
                order_tax_amount: this.convertToCent(this.calculateVat(this.basket.totalRaw, this.basket.vatPercent)),
                tax_rate: this.convertToCent(this.basket.vatPercent),
                order_lines: orderLines
            };
        }
        return undefined;
    }

    private convertToCent(value: number): number {
        return value * 100;
    }

    private calculateVat(amount: number, vatPercent: number): number {
        const totalExVat = amount / (vatPercent / 100 + 1);
        return amount - totalExVat;
    }
}

