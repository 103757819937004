
import Vue from "vue";
import ViewportEventsService from "../../core/responsive/viewport/viewport-events.service";

Vue.directive("in-viewport", {
    inserted(el, binding) {
        const offset = parseInt((binding.arg || "0"), 10);
        const callbackIndex = ViewportEventsService.setCallback(() => {
            isElementInView(el, offset).then(isInView => {
                if (isInView) {
                    binding.value();
                    ViewportEventsService.removeCallback(callbackIndex);
                }
            });
        });
        el.setAttribute("viewport-index", callbackIndex + "");
    },
    unbind(el) {
        const callbackIndex = el.getAttribute("viewport-index");
        ViewportEventsService.removeCallback(parseInt(callbackIndex, 10));
    }
});

function isElementInView(element: HTMLElement, buffer?: number): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
        //delay this calculation cause we are not gaurenteed that element is in dom on inserted hook
        setTimeout(() => {

            if (buffer === undefined) {
                buffer = 0;
            }

            /** Abort, no element provided or no getBoundingClientRect */
            if (element === undefined || !element.getBoundingClientRect) {
                resolve(false);
            }
            const rect = element.getBoundingClientRect();
            /** Abort, element has not been rendered yet */
            if (rect.height === 0 && rect.width === 0) {
                resolve(false);
            }

            /** Some portion of the element is within the viewport */
            if (rect.bottom > -buffer && rect.top < window.innerHeight + buffer) {
                resolve(true);
            }

            resolve(false);
        }, 50);
    });

}
