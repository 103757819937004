import Http from '@/core/http/http.service';
import NotificationService from '@/core/notification.service';
import DictionaryService from '@/core/dictionary.service';
import AddressValidationViewModel = Vertica.LouisPoulsen.Application.AddressValidation.ViewModels.AddressValidationViewModel;
import AddressValidationWebRequest = Vertica.LouisPoulsen.Application.AddressValidation.Api.Requests.AddressValidationWebRequest;

class AddressValidationService {
    public async validateAddress(customAddress: AddressValidationWebRequest) : Promise<string> {
        const response = await Http.post<AddressValidationViewModel>('address-validation', customAddress);
        if (!response.isValidAddress && !response.skipMessage) {
            NotificationService.error(
                DictionaryService.get('Pages.BasketB2B.InvalidAddressHeader'),
                DictionaryService.get('Pages.BasketB2B.InvalidAddressText'));
        }
        return response.responseId;
    }
}

export default new AddressValidationService();
