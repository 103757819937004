
    import Vue from "vue";
    import Component from "vue-class-component";

    @Component({
        name: "product-feature-text"
    })
    export default class ProductFeatureText extends Vue {

        get isEmpty() {
            const slot = this.$slots["text"];
            return !slot ? true : false;
        }
    }
