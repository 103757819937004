
import Vue from 'vue';
import Component from 'vue-class-component';
import Http from '@/core/http/http.service';
import ServerContextService from '@/core/server-context.service';
import BasketViewModel = Vertica.LouisPoulsen.Application.Commerce.ViewModels.Consumer.BasketViewModel;
import { BasketGetter } from '@/store';
import PageService from '@/core/page.service';
import InitializeAdyenViewModel = Vertica.LouisPoulsen.Application.Commerce.ViewModels.InitializeAdyenViewModel;
import AdyenPaymentViewModel = Vertica.LouisPoulsen.Application.Commerce.ViewModels.AdyenPaymentViewModel;
import QueryParamService from '@/core/query-params.service';
import DictionaryService from '@/core/dictionary.service';
import Emaerket from '@/ui/badges/Emaerket.vue';

@Component({
    name: 'payment-provider-adyen',
    components: {
        Emaerket
    }
})
export default class PaymentProviderAdyen extends Vue {
    refs!: {
        dropin: HTMLElement
    };

    @BasketGetter
    public basket: BasketViewModel;

    public adyenPaymentMethods: any = null;
    public adyenCheckout: any = null;
    public adyenDropin: any = null;
    public adyenPaymentInformation: string | null = null;

    public showLoading: boolean = true;

    public showError: boolean = false;
    public errorMessage: string = '';

    mounted() {
        this.initAdyenCheckout();

        if (QueryParamService.getParam('failed') === '1') {
            this.showError = true;
            this.errorMessage = DictionaryService.get('Pages.Checkout.Adyen.Failed');
        } else if (QueryParamService.getParam('refused') === '1') {
            this.showError = true;
            this.errorMessage = DictionaryService.get('Pages.Checkout.Adyen.Refused');
        }
    }

    async initAdyenCheckout() {
        const response = await Http.get('/paymentb2c/initializeadyen') as InitializeAdyenViewModel;

        await this.appendStyleAndScript(response.environment);

        this.adyenPaymentMethods = response.paymentMethods;
        const locale = ServerContextService.languages.find(l => l.isSelected).code;
        const isJapanMarket = locale.toLowerCase() === 'ja-JP'.toLowerCase();

        const configuration = {
            locale,
            environment: response.environment,
            clientKey: response.clientKey,
            paymentMethodsResponse: this.adyenPaymentMethods,
            amount: {
                value: this.basket.totalRaw,
                currency: this.basket.currency
            },
            showPayButton: false,
            paymentMethodsConfiguration: {
                applepay: {
                    amount: {
                        value: !isJapanMarket ? this.basket.totalRaw * 100 : this.basket.totalRaw,
                        currency: this.basket.currency
                    },
                    onSubmit: (state) => {
                        this.processPayment(state.data);
                    }
                },
                card: {
                    name: DictionaryService.get('Shared.PaymentProviders.Adyen')
                }
            }
        };

        this.adyenCheckout = new (window as any).AdyenCheckout(configuration);

        this.adyenDropin = this.adyenCheckout.create('dropin', {
            onSubmit: (state, dropin) => {
                this.processPayment(state.data);
            },
            onAdditionalDetails: (state, dropin) => {
                this.processAdditionalDetails(state.data);
            },
            onChange: (state, component) => {
                if (state.isValid) {
                    this.adyenPaymentInformation = JSON.stringify(state.data.paymentMethod);
                } else {
                    this.adyenPaymentInformation = null;
                }
            }
        }).mount(this.$refs.dropin);

        this.showLoading = false;
    }

    async appendStyleAndScript(environment) {
        return new Promise<void>((resolve, reject) => {
            const adyenStyle = 'adyen-style';
            const adyenScript = 'adyen-script';

            if (!document.getElementById(adyenStyle)) {
                const link = document.createElement('link');
                link.id = adyenStyle;
                link.rel = 'stylesheet';
                link.href = `https://checkoutshopper-${environment}.adyen.com/checkoutshopper/sdk/4.3.1/adyen.css`;
                document.head.appendChild(link);
            }

            if (!document.getElementById(adyenScript)) {
                const script = document.createElement('script');
                script.id = adyenScript;
                script.src = `https://checkoutshopper-${environment}.adyen.com/checkoutshopper/sdk/4.3.1/adyen.js`;
                script.async = true;
                script.onload = () => resolve();
                document.body.appendChild(script);
            } else {
                resolve();
            }
        });
    }

    processPayment(data) {
        if (data && data.paymentMethod && data.paymentMethod.type == 'applepay') {
            if (data.paymentMethod.applePayToken) {
                data.paymentMethod['applepay.token'] = data.paymentMethod.applePayToken;
            } else {
                return;
            }
        }

        Http.post('/paymentb2c/adyenpayment', data).then((response: AdyenPaymentViewModel) => {
            this.handleResponse(response);
        });
    }

    processAdditionalDetails(data) {
        Http.post('/paymentb2c/adyenadditionaldetails', data).then((response: AdyenPaymentViewModel) => {
            this.handleResponse(response);
        });
    }

    handleResponse(response: AdyenPaymentViewModel) {
        this.showError = false;
        this.errorMessage = null;

        if (!response.success) {
            this.showError = true;
            this.errorMessage = response.errorMessage;

            // Some errors destroys the Adyen Drop-In component
            // We check if the component still exists
            // and initalizes it again if it's not there.
            const dropinElement = (this.$refs.dropin as HTMLElement);
            if (dropinElement.children.length === 0) {
                this.initAdyenCheckout();
            }
            return;
        }

        if (response.action) {
            this.adyenDropin.handleAction(response.action);
            return;
        }

        if (response.complete) {
            // We use hardRedirect here to be able to track the "transactionComplete" event.
            // CookieGuardedMessage that Vertica.AnalyticsTracker uses doesn't work with our SPA redirect.
            // We should fix it in Vertica.AnalyticsTracker, but for now we just use a hard redirect.
            PageService.hardRedirect(response.redirectUrl);
        }
    }

    submitPayment() {
        this.adyenDropin.submit();
    }
}
