
import { Component, Prop } from 'vue-property-decorator';
import {
    DynamicScroller,
    DynamicScrollerItem
} from '@/../node_modules/vue-virtual-scroller';
import DownloadCenterImage from '@/ui/views/download-center/DownloadCenterImage.vue';
import { BreakpointsMixin } from '@/core/responsive/breakpoints/breakpoint.mixin';
import { Mixins } from 'vue-mixin-decorator';
import ViewportEventsService from '@/core/responsive/viewport/viewport-events.service';

@Component({
    components: {
        DownloadCenterImage,
        DynamicScroller,
        DynamicScrollerItem
    }
})
export default class DownloadCenterImagesWrapper extends Mixins<BreakpointsMixin>(BreakpointsMixin) {
    @Prop({
        type: Array,
        required: true
    }) result: FileReferenceViewObject[];

    @Prop({
        type: String,
        required: true
    }) typeId: string;

    viewportEventsService = ViewportEventsService;

    get chunkSize(): number {
        return this.breakpointIsActive.small ? 1 : 3;
    }

    get chunkCount(): number {
        return Math.ceil(this.result.length / this.chunkSize);
    }

    get resultChunks(): FileReferenceViewObject[] {
        const resultChunks = [];
        for (let i = 0; i < this.chunkCount; i++) {
            resultChunks.push({
                id: i,
                chunkItems: this.result.slice(i * this.chunkSize, (i + 1) * this.chunkSize)
            });
        }
        return resultChunks;
    }
}
