
import Component from 'vue-class-component';
import QueryParamsService, { QueryParams } from '../../../core/query-params.service';
import { Mixins, Prop } from 'vue-property-decorator';
import { router } from '@/router';
import Http from '@/core/http/http.service';
import ArticleTile from './ArticleTile.vue';
import { AppGetter } from '@/store';
import { BreakpointsMixin } from '@/core/responsive/breakpoints/breakpoint.mixin';

@Component({
    name: 'reflections-page',
    components: {
        ArticleTile
    }
})
export default class ReflectionsPage extends Mixins<BreakpointsMixin>(BreakpointsMixin) {
    @AppGetter public selectedLanguage: MarketViewObject;
    @Prop({ type: Array, required: true }) tags: TagViewObject[];
    @Prop({ type: String }) headlineText: string;

    public isSearching: boolean;
    public total = 0;

    selectedTag: TagViewObject | null = null;
    public accumulatedTiles: ArticleTileViewModel[] = [];

    public request: ReflectionsSearchWebRequest = {
        size: 12,
        from: 0,
        tag: null,
        language: ''
    };

    mounted() {
        this.initialize();
    }

    get showLoadMore() {
        return this.request.from < this.total;
    }

    get selectedTagName() {
        return this.selectedTag ? this.selectedTag.name : null;
    }

    get pageSize() {
        return this.isLargeBreakpoint ? 12 : 6;
    }

    async initialize() {
        this.initializeRequestFromQuery();
        this.request.language = this.selectedLanguage.code;

        await this.searchAndAddResults();

        this.initializeSelectedValuesAfterFirstSearch();
    }

    initializeRequestFromQuery() {
        this.request.size = parseInt(QueryParamsService.getParam(QueryParams.ReflectionsSearchLoad), 10) || this.pageSize;
        this.request.tag = QueryParamsService.getParam(QueryParams.ReflectionsSearchTag) || '';
    }

    initializeSelectedValuesAfterFirstSearch() {
        const found = this.tags.find(c => c.name === this.request.tag);
        if (found !== undefined) {
            this.selectedTag = found;
        }
    }

    async onTagChange() {
        this.request.from = 0;
        this.request.size = this.pageSize;
        this.request.tag = this.selectedTag.name;
        this.accumulatedTiles = [];
        await this.searchAndAddResults();
    }

    async loadNextPage() {
        this.request.size = this.pageSize;
        await this.searchAndAddResults();
    }

    async searchAndAddResults() : Promise<void> {
        const result = await this.search();
        this.accumulatedTiles = this.accumulatedTiles.concat(result.articles);
    }

    async search() : Promise<ReflectionsSearchResultViewModel> {
        this.isSearching = true;
        const query = QueryParamsService.appendQueryParams([
            { name: QueryParams.ReflectionsSearchTag, value: this.request.tag }
        ]);
        router.replace({ query });
        try {
            const result = await Http.get<ReflectionsSearchResultViewModel>('reflections/search', this.request);
            this.total = result.total;
            this.isSearching = false;
            this.updateQueryParamsPostSearch();
            return result;
        } catch (error) {
            this.isSearching = false;
            throw error;
        }
    }

    updateQueryParamsPostSearch() {
        this.request.from += this.request.size;
        const query = QueryParamsService.appendQueryParams([
            { name: QueryParams.ReflectionsSearchLoad, value: Math.min(this.request.from, this.total).toString() }
        ]);
        router.replace({ query });
    }
}
