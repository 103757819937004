import Http from '@/core/http/http.service';
import ConfirmAccountEmailWebRequest = Vertica.LouisPoulsen.Application.Authentication.Requests.ConfirmAccountEmailWebRequest;
import ConfirmAccountEmailViewModel = Vertica.LouisPoulsen.Application.Authentication.ViewModels.ConfirmAccountEmailViewModel;

class ConfirmAccountEmailService {
    public async confirmAccountEmail(token: string): Promise<ConfirmAccountEmailViewModel> {
        const request: ConfirmAccountEmailWebRequest = {
            token
        };

        return Http.post('authentication/confirm-account-email', request)
            .then((response: ConfirmAccountEmailViewModel) => {
                return response;
            });
    }
}

export default new ConfirmAccountEmailService();
