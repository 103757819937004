export function lowercaseFirstChar(text: string): string {
    if (!text) return '';
    return text.substr(0, 1).toLowerCase() + text.substring(1);
}
​
export function uppercaseFirstChar(text: string): string {
    if (!text) return '';
    return text.substr(0, 1).toUpperCase() + text.substring(1);
}
​
export function newLineToBr(text: string): string {
    return text.replace(/\n/g, '<br>');
}
​
export function isUrlExternal(uri:string): boolean {
    const external = RegExp('^((f|ht)tps?:)?//(?!' + location.host + ')');
    return external.test(uri);
}
