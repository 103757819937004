import { StorageService } from '@/core';
import { Action } from './registeredproductaction.interface';
import IRegisteredProductAction from './registeredproductaction.interface';

class ProductRegistrationActionService {
    private Key: string = 'registered-product-action';

    SetActionContext(action: Action, name: string): void {
        StorageService.setItemAs<IRegisteredProductAction>(this.Key, {
            action,
            name
        });
    }

    GetActionContext(): IRegisteredProductAction {
        return StorageService.getItemAs<IRegisteredProductAction>(this.Key);
    }

    DeleteActionContext(): void {
        StorageService.removeItem(this.Key);
    }
}

export default new ProductRegistrationActionService();
