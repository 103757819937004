import PageService from "../page.service";
import ServerContextService from "../server-context.service";

export default (response) => {
    if (response.headers) {
        const version = response.headers["lp-app-version"];
        if (version && version !== ServerContextService.version) {
            PageService.hardReload();
        }
    }
    return response;
};
