import { render, staticRenderFns } from "./BasketCustomLineItemName.vue?vue&type=template&id=f8232f82&"
import script from "./BasketCustomLineItemName.vue?vue&type=script&lang=ts&setup=true&"
export * from "./BasketCustomLineItemName.vue?vue&type=script&lang=ts&setup=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports