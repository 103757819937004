import { GetterTree, MutationTree, ActionTree } from 'vuex';
import BasketService from '../services/basket.service';
import PointOfSaleBasketService from '../services/basket.pos.service';
import CheckoutService from '../services/checkout.service';
import { namespace } from 'vuex-class';
import UpdateLineItemWebRequest = Vertica.LouisPoulsen.Application.Commerce.Requests.UpdateLineItemWebRequest;
import UpdateGiftWithPurchaseVariantWebRequest = Vertica.LouisPoulsen.Application.Commerce.Requests.UpdateGiftWithPurchaseVariantWebRequest;
import AddServiceToBasketWebRequest = Vertica.LouisPoulsen.Application.Commerce.Requests.AddServiceToBasketWebRequest;
import UpdatePointOfSaleLineItemWebRequest = Vertica.LouisPoulsen.Application.Commerce.Requests.UpdatePointOfSaleLineItemWebRequest;
import RemoveFromBasketWebRequest = Vertica.LouisPoulsen.Application.Commerce.Requests.RemoveFromBasketWebRequest;
import UpdateOrderDeliveryTypeWebRequest = Vertica.LouisPoulsen.Application.Commerce.Requests.UpdateOrderDeliveryTypeWebRequest;
import UpdateOrderReferenceWebRequest = Vertica.LouisPoulsen.Application.Commerce.Requests.UpdateOrderReferenceWebRequest;
import UpdateLineReferenceWebRequest = Vertica.LouisPoulsen.Application.Commerce.Requests.UpdateLineReferenceWebRequest;
import UpdateOrderDeliveryDateWebRequest = Vertica.LouisPoulsen.Application.Commerce.Requests.UpdateOrderDeliveryDateWebRequest;
import UpdatePaymentMethodWebRequest = Vertica.LouisPoulsen.Application.Commerce.Requests.UpdatePaymentMethodWebRequest;
import CalculateSalesTaxWebRequest = Vertica.LouisPoulsen.Application.Commerce.Requests.Consumer.CalculateSalesTaxWebRequest;
import CalculateSalesTaxResultViewModel = Vertica.LouisPoulsen.Application.Commerce.ViewModels.Consumer.CalculateSalesTaxResultViewModel;
import UpdatePointOfSaleShippingDiscountWebRequest = Vertica.LouisPoulsen.Application.Commerce.Requests.UpdatePointOfSaleShippingDiscountWebRequest;
import UpdatePointOfSaleCustomLineItemWebRequest = Vertica.LouisPoulsen.Application.Commerce.Requests.UpdatePointOfSaleCustomLineItemWebRequest;
import BasketB2CViewModel = Vertica.LouisPoulsen.Application.Commerce.ViewModels.Consumer.BasketViewModel;
import BasketB2BViewModel = Vertica.LouisPoulsen.Application.Commerce.ViewModels.Business.BasketViewModel;
import IBasketViewModel = Vertica.LouisPoulsen.Application.Commerce.ViewModels.IBasketViewModel;

enum MutationMethod {
    setBasket = 'setBasket',
    toggleIsBasketLocked = 'toggleIsBasketLocked',
    setCouponAdded = 'setCouponAdded',
    setCouponSubmitted = 'setCouponSubmitted',
    variantBeingAdded = 'variantBeingAdded'
}

const storeState: IBasketState = {
    basket: {
        lineItems: []
    } as IBasketViewModel,
    isBasketLocked: false,
    isCouponSubmitted: false,
    variantBeingAdded: null,
    promotionBanner: null
};

const getters: GetterTree<IBasketState, any> = {
    basket: state => state.basket as IBasketViewModel,
    basketB2C: state => state.basket as BasketB2CViewModel,
    basketB2B: state => state.basket as BasketB2BViewModel,
    lineItems: state => state.basket.lineItems,
    lineItemsCount: state => state.basket.lineItems.reduce((prev, lineitem) => prev + lineitem.quantity, 0),
    isBasketLocked: state => state.isBasketLocked,
    isCouponSubmitted: state => state.isCouponSubmitted,
    variantBeingAdded: state => state.variantBeingAdded,
    promotionBanner: state => state.promotionBanner,
    hasPromotionBanner: state => state.promotionBanner && (!!state.promotionBanner.image || !!state.promotionBanner.bodyText),
    lineItemById: state => id => state.basket.lineItems.find(l => l.variantId === id)
};

const actions: ActionTree<IBasketState, any> = {
    async addToBasket({ commit }, payload: AddToBasketWebRequest) {
        try {
            commit(MutationMethod.toggleIsBasketLocked);
            commit(MutationMethod.variantBeingAdded, payload.id);
            const basket = await BasketService.add(payload);
            commit(MutationMethod.setBasket, basket);
            commit(MutationMethod.toggleIsBasketLocked);
            commit(MutationMethod.variantBeingAdded, null);
        } catch (error) {
            console.log(error);
            commit(MutationMethod.variantBeingAdded, null);
            commit(MutationMethod.toggleIsBasketLocked);
        }
    },

    async addPointOfSaleService({ commit }, payload: AddServiceToBasketWebRequest) {
        try {
            commit(MutationMethod.toggleIsBasketLocked);
            const basket = await PointOfSaleBasketService.addService(payload || ({} as AddServiceToBasketWebRequest));
            commit(MutationMethod.setBasket, basket);
            commit(MutationMethod.toggleIsBasketLocked);
        } catch (error) {
            console.log(error);
            commit(MutationMethod.toggleIsBasketLocked);
        }
    },

    async updateLineItem({ commit }, payload: UpdateLineItemWebRequest) {
        try {
            commit(MutationMethod.toggleIsBasketLocked);
            commit(MutationMethod.variantBeingAdded, payload.id);
            const basket = await BasketService.update(payload);
            commit(MutationMethod.setBasket, basket);
            commit(MutationMethod.toggleIsBasketLocked);
            commit(MutationMethod.variantBeingAdded, null);
        } catch (error) {
            console.log(error);
            commit(MutationMethod.variantBeingAdded, null);
            commit(MutationMethod.toggleIsBasketLocked);
        }
    },

    async updatePointOfSaleLineItem({ commit }, payload: UpdatePointOfSaleLineItemWebRequest & { throwError: boolean }) {
        try {
            commit(MutationMethod.toggleIsBasketLocked);
            commit(MutationMethod.variantBeingAdded, payload.id);
            const basket = await PointOfSaleBasketService.updateLineItem(payload);
            commit(MutationMethod.setBasket, basket);
            commit(MutationMethod.toggleIsBasketLocked);
            commit(MutationMethod.variantBeingAdded, null);
        } catch (error) {
            console.log(error);
            commit(MutationMethod.variantBeingAdded, null);
            commit(MutationMethod.toggleIsBasketLocked);
            if (payload.throwError) {
                throw error;
            }
        }
    },

    async updatePointOfSaleCustomLineItem({ commit }, payload: UpdatePointOfSaleCustomLineItemWebRequest) {
        try {
            commit(MutationMethod.toggleIsBasketLocked);
            const basket = await PointOfSaleBasketService.updateCustomLineItem(payload);
            commit(MutationMethod.setBasket, basket);
            commit(MutationMethod.toggleIsBasketLocked);
        } catch (error) {
            console.log(error);
            commit(MutationMethod.toggleIsBasketLocked);
        }
    },

    async updatePointOfSaleShippingDiscount({ commit }, payload: UpdatePointOfSaleShippingDiscountWebRequest) {
        try {
            commit(MutationMethod.toggleIsBasketLocked);
            const basket = await PointOfSaleBasketService.updateShippingDiscount(payload);
            commit(MutationMethod.setBasket, basket);
            commit(MutationMethod.toggleIsBasketLocked);
        } catch (error) {
            console.log(error);
            commit(MutationMethod.toggleIsBasketLocked);
        }
    },

    async updateGiftWithPurchaseItem({ commit }, payload: UpdateGiftWithPurchaseVariantWebRequest) {
        try {
            commit(MutationMethod.toggleIsBasketLocked);
            commit(MutationMethod.variantBeingAdded, payload.variantId);
            const basket = await BasketService.updateGift(payload);
            commit(MutationMethod.setBasket, basket);
            commit(MutationMethod.toggleIsBasketLocked);
            commit(MutationMethod.variantBeingAdded, null);
        } catch (error) {
            console.log(error);
            commit(MutationMethod.variantBeingAdded, null);
            commit(MutationMethod.toggleIsBasketLocked);
        }
    },

    async removeFromBasket({ commit }, payload: RemoveFromBasketWebRequest) {
        try {
            commit(MutationMethod.toggleIsBasketLocked);
            commit(MutationMethod.variantBeingAdded, payload.id);
            const basket = await BasketService.remove(payload);
            commit(MutationMethod.setBasket, basket);
            commit(MutationMethod.toggleIsBasketLocked);
            commit(MutationMethod.variantBeingAdded, null);
        } catch (error) {
            console.log(error);
            commit(MutationMethod.toggleIsBasketLocked);
        }
    },

    async fetchBasket({ commit }) {
        const basket = await BasketService.get(null);
        commit(MutationMethod.setBasket, basket);
        return basket;
    },

    async addCoupon({ commit }, code: string) {
        commit(MutationMethod.toggleIsBasketLocked);

        return CheckoutService.addCoupon(code).then((response) => {
            commit(MutationMethod.setBasket, response.basketViewModel);
            commit(MutationMethod.setCouponSubmitted, true);
            commit(MutationMethod.toggleIsBasketLocked);
        })
            .catch(() => {
                commit(MutationMethod.toggleIsBasketLocked);
            });
    },

    async calculateSalesTax({ commit }, request: CalculateSalesTaxWebRequest) :Promise<CalculateSalesTaxResultViewModel> {
        commit(MutationMethod.toggleIsBasketLocked);

        const promise = BasketService.calculateSalesTax(request);
        promise.then((response) => {
            commit(MutationMethod.setBasket, response.basket);
        });
        promise.finally(() => {
            commit(MutationMethod.toggleIsBasketLocked);
        });
        return promise;
    },

    async removeCoupon({ commit }, code: string) {
        commit(MutationMethod.toggleIsBasketLocked);

        return CheckoutService.removeCoupon(code).then((response) => {
            commit(MutationMethod.setBasket, response);
            commit(MutationMethod.setCouponSubmitted, false);
            commit(MutationMethod.toggleIsBasketLocked);
        }).catch(() => {
            commit(MutationMethod.toggleIsBasketLocked);
        });
    },

    async updateOrderReference({ commit }, request: UpdateOrderReferenceWebRequest) {
        commit(MutationMethod.toggleIsBasketLocked);

        return CheckoutService.updateOrderReference(request).then((response) => {
            commit(MutationMethod.setBasket, response);
            commit(MutationMethod.toggleIsBasketLocked);
        }).catch(() => {
            commit(MutationMethod.toggleIsBasketLocked);
        });
    },

    async updatePaymentMethod({ commit }, request: UpdatePaymentMethodWebRequest) {
        commit(MutationMethod.toggleIsBasketLocked);

        return CheckoutService.updatePaymentMethod(request).then((response) => {
            commit(MutationMethod.setBasket, response);
            commit(MutationMethod.toggleIsBasketLocked);
        }).catch(() => {
            commit(MutationMethod.toggleIsBasketLocked);
        });
    },

    async updateOrderDeliveryType({ commit }, request: UpdateOrderDeliveryTypeWebRequest) {
        commit(MutationMethod.toggleIsBasketLocked);

        return CheckoutService.updateOrderDeliveryType(request).then((response) => {
            commit(MutationMethod.setBasket, response);
            commit(MutationMethod.toggleIsBasketLocked);
        }).catch(() => {
            commit(MutationMethod.toggleIsBasketLocked);
        });
    },

    async updateOrderDeliveryDate({ commit }, request: UpdateOrderDeliveryDateWebRequest) {
        commit(MutationMethod.toggleIsBasketLocked);

        return CheckoutService.updateOrderDeliveryDate(request).then((response) => {
            commit(MutationMethod.setBasket, response);
            commit(MutationMethod.toggleIsBasketLocked);
        }).catch(() => {
            commit(MutationMethod.toggleIsBasketLocked);
        });
    },

    async updateLineReference({ commit }, request: UpdateLineReferenceWebRequest) {
        commit(MutationMethod.toggleIsBasketLocked);

        return BasketService.updateLineReference(request).then((response) => {
            commit(MutationMethod.setBasket, response);
            commit(MutationMethod.toggleIsBasketLocked);
        }).catch(() => {
            commit(MutationMethod.toggleIsBasketLocked);
        });
    },

    async ensureValidForCheckout({ commit }): Promise<ValidationResultViewModel> {
        commit(MutationMethod.toggleIsBasketLocked);

        const promise = BasketService.ensureValidForCheckout({ basketType: null });
        promise.finally(() => {
            commit(MutationMethod.toggleIsBasketLocked);
        });
        return promise;
    },

    async addFreeItem({ commit }) {
        try {
            commit(MutationMethod.toggleIsBasketLocked);
            const basket = await BasketService.addFreeItem();
            commit(MutationMethod.setBasket, basket);
            commit(MutationMethod.toggleIsBasketLocked);
        } catch (error) {
            console.log(error);
            commit(MutationMethod.toggleIsBasketLocked);
        }
    },

    async updateDeliveryAddress({ commit }, request) {
        return CheckoutService.updateB2BDeliveryAddress(request).then((response) => {
            commit(MutationMethod.setBasket, response);
            commit(MutationMethod.toggleIsBasketLocked);
        }).catch(() => {
            commit(MutationMethod.toggleIsBasketLocked);
        });
    },

    async updateCustomDeliveryAddress({ commit }, request) {
        return CheckoutService.updateB2BCustomDeliveryAddress(request).then((response) => {
            commit(MutationMethod.setBasket, response);
            commit(MutationMethod.toggleIsBasketLocked);
        }).catch(() => {
            commit(MutationMethod.toggleIsBasketLocked);
        });
    },

    async UpdateShippingCostB2B({ commit }) {
        try {
            const basket = await BasketService.UpdateShippingCostB2B();
            commit(MutationMethod.setBasket, basket);
        } catch (error) {
            console.log(error);
        }
    }
};

const mutations: MutationTree<IBasketState> = {
    [MutationMethod.setBasket](state, basket) {
        state.basket = basket;
    },
    [MutationMethod.toggleIsBasketLocked](state) {
        state.isBasketLocked = !state.isBasketLocked;
    },
    [MutationMethod.variantBeingAdded](state, variantId) {
        state.variantBeingAdded = variantId;
    },
    [MutationMethod.setCouponSubmitted](state, isAdded) {
        state.isCouponSubmitted = isAdded;
    }
};

export default {
    namespaced: true,
    state: storeState,
    getters,
    actions,
    mutations
};

const moduleName = 'basket';
const moduleObject = namespace(moduleName);

export const BasketGetter = moduleObject.Getter;
export const BasketMutation = moduleObject.Mutation;
export const BasketAction = moduleObject.Action;
export const BasketState = moduleObject.State;

export interface IAddToBasketPayload {
    productId: string;
    quantity: number;
}
