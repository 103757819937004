class UserAgentService {
    // @ts-ignore
    isIOS: boolean = /iP(hone|od|ad)/.test(navigator.userAgent) && !window.MSStream;
    isAndroid: boolean = /android/i.test(navigator.userAgent);
    isPrerender: boolean = /prerender/.test(navigator.userAgent.toLowerCase()); 

    get androidVersion(): string {
        const match = navigator.userAgent.toLowerCase().match(/android\s([0-9\.]*)/i);
        return match ? match[1] : undefined;
    };

    get androidVersionNumber(): number {
        return parseInt(this.androidVersion, 10);
    };
}
export default new UserAgentService();
