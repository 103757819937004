import { ActionTree } from "vuex";
import { namespace } from "vuex-class";
import GdprformService from "../services/gdprform.service";


const actions: ActionTree<any, any> = {
    async submitFormOptions({commit}, request: GdprFormBlockWebRequest ) {
        return await GdprformService.submitFormOptions(request);
    }
};


export default {
    namespaced: true,
    actions,
};

const moduleName = "gdprform";
const moduleObject = namespace(moduleName);

export const GdprFormAction = moduleObject.Action;
