
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import ProductTenderInfoItem from './ProductTenderInfoItem.vue';
import TrackingService from '@/core/tracking.service';
import { AppGetter } from '@/store';

@Component({
    name: 'product-tender-info',
    components: {
        ProductTenderInfoItem
    }
})
export default class ProductTenderInfo extends Vue {
    @Prop({ type: String })
    public title: string;

    @Prop()
    public model: TenderInfosViewObject;

    @AppGetter public theme: ColorTheme;

    public showModal: boolean = false;

    get hasMultipleLanguages() {
        return this.model.tenderInfos.length > 1;
    }

    openModal() {
        this.showModal = true;
        TrackingService.event('Mini page', 'Tender information', this.model.tenderInfos[0].language);
    }
}
