
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { TranslateMixin } from '@/core/translate.mixin';
import LedCalculatorFixtureViewObject = Vertica.LouisPoulsen.Application.LedCalculator.ViewModels.LedCalculatorFixtureViewObject;
import { fixtureDetailsDataModel } from '@/ui/shared/blocks/LedCalculator/LedCalculatorTypes';

interface detailsBoxInfo {
    label: string,
    value: string | null,
    suffix: string
}
interface detailsBox {
    SystemEffectNormal: detailsBoxInfo,
    SystemEffectNight: detailsBoxInfo,
    MaintenanceFactor: detailsBoxInfo,
    LightSourceLumen: detailsBoxInfo,
    NettoLumen: detailsBoxInfo,
    OperatingTimeNormal: detailsBoxInfo,
    OperatingTimeNight: detailsBoxInfo
}

@Component
export default class BlockLedCalculatorFixture extends Mixins<TranslateMixin>(TranslateMixin) {
    @Prop({
        type: String,
        required: true
    }) title: string;

    @Prop({
        type: String,
        required: true
    }) selectInputLabel: string;

    @Prop({
        type: [Array, null],
        default: null,
        required: false
    }) selectInputOptions: LedCalculatorFixtureViewObject[] | null;

    @Prop({
        type: String,
        required: true
    }) kwhDescription: string;

    @Prop({
        type: String,
        required: true
    }) priceDescription: string;

    @Prop({
        type: [Object, null],
        default: null,
        required: false
    }) fixtureData: fixtureDetailsDataModel | null;

    @Prop({
        type: String,
        required: true
    }) operatingTimeNormal: string;

    @Prop({
        type: String,
        required: true
    }) operatingTimeNight: string;

    @Prop({
        type: Boolean,
        required: true
    }) isDirty: boolean;

    @Prop({
        type: Boolean,
        required: false,
        default: false
    }) showOrderId: boolean;

    get details(): detailsBox | null {
        if (this.fixtureData === null) {
            return null;
        }
        return {
            SystemEffectNormal: {
                label: this.t('Shared.LedCalculator.Details.SystemEffectNormal'),
                value: this.fixtureData.systemEffectNormal,
                suffix: this.t('Shared.LedCalculator.Details.WattageSuffix')
            },
            SystemEffectNight: {
                label: this.t('Shared.LedCalculator.Details.SystemEffectNight'),
                value: this.fixtureData.systemEffectNight,
                suffix: this.t('Shared.LedCalculator.Details.WattageSuffix')
            },
            MaintenanceFactor: {
                label: this.t('Shared.LedCalculator.Details.MaintenanceFactor'),
                value: this.fixtureData.maintenanceFactor,
                suffix: this.t('Shared.LedCalculator.Details.LLMFSuffix')
            },
            LightSourceLumen: {
                label: this.t('Shared.LedCalculator.Details.LightSourceLumen'),
                value: this.fixtureData.lightSourceLumen,
                suffix: this.t('Shared.LedCalculator.Details.LumenSuffix')
            },
            NettoLumen: {
                label: this.t('Shared.LedCalculator.Details.NettoLumen'),
                value: this.fixtureData.nettoLumen,
                suffix: this.t('Shared.LedCalculator.Details.LumenSuffix')
            },
            OperatingTimeNormal: {
                label: this.t('Shared.LedCalculator.Details.OperatingTimeNormal'),
                value: this.operatingTimeNormal,
                suffix: this.t('Shared.LedCalculator.Details.TimeSuffix')
            },
            OperatingTimeNight: {
                label: this.t('Shared.LedCalculator.Details.OperatingTimeNight'),
                value: this.operatingTimeNight,
                suffix: this.t('Shared.LedCalculator.Details.TimeSuffix')
            }
        };
    };

    selectedFixture: LedCalculatorFixtureViewObject | null = null;

    get selectedOptionImage(): string {
        return this.selectedFixture.image + '?format=png&width=150&quality=90';
    }

    get hasOptions(): boolean {
        return this.selectInputOptions !== null && this.selectInputOptions.length > 0;
    }

    created(): void {
        if (this.hasOptions) {
            this.selectedFixture = this.selectInputOptions[0];
        } else {
            this.selectedFixture = null;
        }
    }

    @Watch('selectInputOptions', { deep: true })
    onOptionsChange(): void {
        if (!this.hasOptions) {
            this.selectedFixture = null;
            return;
        }
        this.selectedFixture = this.selectInputOptions[0];
    }

    @Watch('selectedFixture')
    onSelectedFixtureChange(): void {
        this.$emit('change', this.selectedFixture);
    }

    onSelectFixtureInputChange(event: Event) {
        this.selectedFixture = this.selectInputOptions[(event.target as HTMLSelectElement).selectedIndex];
    }
}
