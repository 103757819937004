import { ref, watch } from 'vue';

// We seriously need to consider a different third-party library, preferably self-hosted
export function useAddressAutoComplete() {
    const script = ref(null);
    const yubinBangoLoaded = ref<boolean>(false);

    watch(() => yubinBangoLoaded.value, reloadYubinBango);

    function setUpAutocompleteAddressForJapanMarket() {
        script.value.onload = () => { yubinBangoLoaded.value = true; };
        script.value.setAttribute('src', 'https://yubinbango.github.io/yubinbango/yubinbango.js');
        script.value.setAttribute('charset', 'UTF-8');
    }

    function reloadYubinBango(loaded: boolean) {
        if (loaded) {
            // @ts-ignore
            // eslint-disable-next-line no-new
            new YubinBango.MicroformatDom();
        }
    }

    function setUpHiddenInputChange(input: HTMLInputElement, changeFunction: () => void) : void {
        const descriptor = Object.getOwnPropertyDescriptor(Object.getPrototypeOf(input), 'value');

        Object.defineProperty(input, 'value', {
            set: function(...t) {
                changeFunction();
                return descriptor.set.apply(this, t);
            },
            get: function() {
                return descriptor.get.apply(this);
            }
        });
    }

    return {
        script,
        yubinBangoLoaded,
        reloadYubinBango,
        setUpAutocompleteAddressForJapanMarket,
        setUpHiddenInputChange
    };
}
