import Vue from 'vue';
import { findIndex } from 'lodash';
import { ProductConfiguratorMultiColorProperty } from '@/store';
import MultiColorViewObject = Vertica.LouisPoulsen.Application.Content.Pages.Product.MultiColorViewObject;

Vue.filter('configOptions', (options: ProfessionalVariantViewObject[] | MultiColorViewObject[], property:string, selectedOptionValue:string, threshold?: number) => {
    const optionCopy = [...options];

    if (threshold) {
        optionCopy.length = Math.min(optionCopy.length, threshold);
    }
    const propertyLookUpName = property === ProductConfiguratorMultiColorProperty ? 'code' : property;
    // @ts-ignore
    // options is either ProfessionalVariantViewObject[] or MultiColorViewObject[] which is handled by propertyLookUpName
    const selectedOptionIndex = findIndex(options, { [propertyLookUpName]: selectedOptionValue });
    const indexBasedThreshold = threshold - 1;

    if (selectedOptionIndex > indexBasedThreshold) {
        optionCopy[indexBasedThreshold] = options[selectedOptionIndex];
    }

    return optionCopy;
});
