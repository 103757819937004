
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import TrackingService from '@/core/tracking.service';
import { downloadCenterAction, downloadCenterGetter } from '@/store';
import userAgent from '@/services/userAgent.service';

@Component({
    name: 'download-center-image'
})
export default class DownloadCenterImage extends Vue {
    @downloadCenterGetter
    public isFileSelected: (typeId: string, file: string) => boolean;

    @downloadCenterAction
    public selectFile: (payload: {typeId: string, file: string}) => void;

    @Prop({
        type: Object,
        required: true
    }) model: FileReferenceViewObject;

    @Prop({
        type: String,
        required: true
    }) typeId: string;

    inView: boolean = userAgent.isPrerender;
    imageReady = false;
    showErrorImage = false;
    showSpinner = false;

    get selected(): boolean {
        return this.isFileSelected(this.typeId, this.model.id);
    }

    set selected(value) {
        this.selectFile({ typeId: this.typeId, file: this.model.id });
    }

    mounted() {
        if (!this.model.previewUrl) {
            this.imageError();
        }
    }

    public setInView(): void {
        this.inView = true;
        this.delayPreloadSpinner();
    }

    private delayPreloadSpinner() {
        setTimeout(() => {
            this.showSpinner = true; // Delay show spinner if images hasn't loaded after 300ms
        }, 300);
    }

    public imageSuccess() {
        this.imageReady = true;
        this.showErrorImage = false;
        this.showSpinner = false;
    }

    public imageError() {
        this.imageReady = true;
        this.showErrorImage = true;
        this.showSpinner = false;
    }

    private imageClicked() {
        TrackingService.event('Ecommerce', 'Download center', 'Download: ' + this.typeId);
    }
}

