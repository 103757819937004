import Http from '../core/http/http.service';
import { StorageService } from '@/core';
import UpdateOrderReferenceWebRequest = Vertica.LouisPoulsen.Application.Commerce.Requests.UpdateOrderReferenceWebRequest;
import UpdateOrderDeliveryTypeWebRequest = Vertica.LouisPoulsen.Application.Commerce.Requests.UpdateOrderDeliveryTypeWebRequest;
import UpdatePaymentMethodWebRequest = Vertica.LouisPoulsen.Application.Commerce.Requests.UpdatePaymentMethodWebRequest;
import UpdateOrderDeliveryDateWebRequest = Vertica.LouisPoulsen.Application.Commerce.Requests.UpdateOrderDeliveryDateWebRequest;
import B2CTaxTotalViewModel = Vertica.LouisPoulsen.Application.Commerce.ViewModels.Consumer.TaxTotalViewModel;
import POSTaxTotalViewModel = Vertica.LouisPoulsen.Application.Commerce.ViewModels.PointOfSale.TaxTotalViewModel;

declare let Klarna: any;

class CheckoutService {
    public customerLocalStorageKey = 'checkout_customer';
    public deliveryInfoLocalStorageKey = 'checkout_deliveryInfo';
    public alternativeDeliveryAddressKey = 'checkout_useAlternativeDeliveryAddress';

    public getPickupShops(request: FindGlsShopWebRequest): Promise<PickupShopViewModel> {
        return Http.get('checkoutb2c/pickupshops', request);
    }

    public submitPOSOrder(request: Vertica.LouisPoulsen.Application.Commerce.Requests.PointOfSale.SaveCheckoutWebRequest): Promise<Vertica.LouisPoulsen.Application.Commerce.ViewModels.PointOfSale.SaveCheckoutViewModel> {
        return Http.post('checkoutpos/savecheckout', request);
    }

    public submitB2COrder(request: Vertica.LouisPoulsen.Application.Commerce.Requests.Consumer.SaveCheckoutWebRequest): Promise<Vertica.LouisPoulsen.Application.Commerce.ViewModels.Consumer.SaveCheckoutViewModel> {
        return Http.post('checkoutb2c/savecheckout', request);
    }

    public submitB2BCheckout(request: Vertica.LouisPoulsen.Application.Commerce.Requests.Business.SaveCheckoutB2BWebRequest): Promise<Vertica.LouisPoulsen.Application.Commerce.ViewModels.Business.SaveCheckoutViewModel> {
        return Http.post('checkoutb2b/savecheckoutb2b', request);
    }

    public addCoupon(code: string) {
        const request: CouponCodeWebRequest = { couponCode: code };
        return Http.post<AddCouponCodeViewModel>('checkoutb2c/AddCouponCode', request);
    }

    public removeCoupon(code: string) {
        const request: CouponCodeWebRequest = { couponCode: code };
        return Http.post<IBasketViewModel>('checkoutb2c/RemoveCouponCode', request);
    }

    public updateOrderReference(request: UpdateOrderReferenceWebRequest) {
        return Http.post('checkoutb2b/updateorderreference', request);
    }

    public updatePaymentMethod(request: UpdatePaymentMethodWebRequest) {
        return Http.post('checkoutb2b/updatepaymentmethod', request);
    }

    public updateOrderDeliveryType(request: UpdateOrderDeliveryTypeWebRequest) {
        return Http.post('checkoutb2b/updateorderdeliverytype', request);
    }

    public updateOrderDeliveryDate(request: UpdateOrderDeliveryDateWebRequest) {
        return Http.post('checkoutb2b/updateorderdeliverydate', request);
    }

    public updateB2BDeliveryAddress(request: Vertica.LouisPoulsen.Application.Commerce.Requests.Business.UpdateDeliveryAddressWebRequest): Promise<any> {
        return Http.post('checkoutb2b/updatedeliveryaddress', request);
    }

    public updateB2BCustomDeliveryAddress(request: Vertica.LouisPoulsen.Application.Commerce.Requests.Business.UpdateCustomDeliveryAddressWebRequest): Promise<any> {
        return Http.post('checkoutb2b/updatecustomdeliveryaddress', request);
    }

    public initializeKlarna(): Promise<InitializeKlarnaSessionViewModel> {
        return Http.post<InitializeKlarnaSessionViewModel>('checkoutb2c/Initializeklarna');
    }

    public getCountryTaxTotal(countryCode: string): Promise<B2CTaxTotalViewModel> {
        return Http.get('checkoutb2c/getCountryTaxTotal', { countryCode });
    }

    public getPOSCountryTaxTotal(countryCode: string): Promise<POSTaxTotalViewModel> {
        return Http.get('checkoutpos/getCountryTaxTotal', { countryCode });
    }

    public clearCheckoutData(): void {
        StorageService.removeItem(this.customerLocalStorageKey);
        StorageService.removeItem(this.deliveryInfoLocalStorageKey);
        StorageService.removeItem(this.alternativeDeliveryAddressKey);
    }
}

export default new CheckoutService();
