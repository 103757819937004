
import { Component, Prop, Watch } from 'vue-property-decorator';
import SelectCtrlMultipleOption from '@/ui/shared/form/SelectCtrlMultipleOption.vue';
import { Mixins } from 'vue-mixin-decorator';
import { TranslateMixin } from '@/core/translate.mixin';
import { uniq } from 'lodash';

export interface selectedOption {
    key: string,
    value: string | object | string[] | object[]
}

@Component({
    components: {
        SelectCtrlMultipleOption
    }
})
export default class SelectCtrlMultiple extends Mixins<TranslateMixin>(TranslateMixin) {
    @Prop({
        type: Array,
        default: () => [],
        required: false
    }) public value: string[] | object[];

    @Prop({
        type: Array,
        required: true
    }) options: string[] | object[];

    @Prop({
        type: String,
        default: null,
        required: false
    }) label: string | null;

    @Prop({
        type: String,
        default: null,
        required: false
    }) id: string | null;

    @Prop({
        type: String,
        default: null,
        required: false
    }) identificationKey: string | null;

    @Prop({
        type: String,
        default: null,
        required: false
    }) displayKey: string | null;

    @Prop({
        type: Boolean,
        default: false,
        required: false
    }) addSelectAllOption: boolean;

    @Prop({
        type: String,
        default: '',
        required: false
    }) addSelectAllOptionLabel: string;

    @Prop({
        type: Boolean,
        default: false,
        required: false
    }) appendToBody: boolean;

    @Prop({
        type: Boolean,
        default: true,
        required: false
    }) filterable: boolean;

    @Prop({
        type: Boolean,
        default: false,
        required: false
    }) listFillParentWidth: boolean;

    @Prop({
        type: String,
        default: null,
        required: false
    }) nestedItemsKey: string | null;

    @Prop({
        type: String,
        default: null,
        required: false
    }) inputClass: string;

    // concat is used so we only get a copy of value and not a binding between "selectedOptions" and "value"
    selectedOptions: any[] = [];
    hasNestedItemsKey = 'hasNestedItems';
    isNestedItemKey = 'isNestedItem';
    parentIdKey = 'parentId';
    nestedItemsValuesKey = 'nestedItemsValues';

    mounted() {
        this.selectedOptions = this.value.concat();
    }

    get searchKey(): string { return this.displayKey || 'text'; }
    get isAllOptionsSelected(): boolean {
        return this.selectedOptions.length === this.options.length;
    }

    set isAllOptionsSelected(value) {
        if (value) {
            this.selectedOptions = (this.options as any[]).map(option => this.getOptionValueForSelection(option)) as any[];
        } else {
            this.selectedOptions = [];
        }
        this.onSelectedOptionsChange();
    }

    get selectAllOption(): object {
        const selectAllOption = {};
        selectAllOption[this.displayKey] = this.addSelectAllOptionLabel || this.t('Shared.Dropdowns.SelectAll');
        return selectAllOption;
    }

    get selectableOptions(): string[] | object[] {
        const options = [];
        if (this.addSelectAllOption) {
            options.push((this.selectAllOption as any));
        }
        if (this.nestedItemsKey !== null) {
            this.options.forEach(option => {
                options.push({
                    ...option,
                    [this.hasNestedItemsKey]: option[this.nestedItemsKey].length >= 0,
                    [this.nestedItemsValuesKey]: option[this.nestedItemsKey].map(nestedItem => nestedItem[this.identificationKey])
                });
                options.push(...(option[this.nestedItemsKey] as any[]).map(nestedItem => ({
                    ...nestedItem,
                    [this.isNestedItemKey]: true,
                    [this.parentIdKey]: option[this.identificationKey]
                })));
            });
        } else {
            options.push(...this.options);
        }
        return options;
    }

    get selectedOptionsCount(): number | null {
        if (this.selectedOptions) {
            return (this.selectedOptions as any[]).length || null;
        }
        return null;
    };

    get placeholderText(): string {
        if (this.selectedOptionsCount) {
            return `${this.label} (${this.selectedOptionsCount})`;
        }
        return this.label;
    }

    $refs: {
        vSelect: HTMLElement
    };

    @Watch('value')
    onValueChange(): void {
        // concat is used so we only get a copy of value and not a binding between "selectedOptions" and "value"
        this.selectedOptions = this.value.concat();
    }

    onSelectedOptionsChange(): void {
        this.$emit('input', (this.selectedOptions as any[]));
        if (this.id !== null) {
            this.$emit('select', { key: this.id, value: this.selectedOptions } as selectedOption);
        }
    }

    filterOptions(options: any[], search: string): string[] {
        const searchString = search.toLowerCase();
        const optionsIsObject: boolean = options.every(option => typeof option === 'object') && options[0][this.searchKey];
        let filteredOptions;
        if (optionsIsObject) {
            filteredOptions = options.filter(option => option[this.searchKey].toString().toLowerCase().indexOf(searchString) >= 0);
        } else {
            // We use .toString() to ensure that even if a value is a number, it will be converted to string.
            filteredOptions = options.filter(option => option.toString().toLowerCase().indexOf(searchString) >= 0);
        }
        if (filteredOptions.length === 0) {
            return [];
        }
        if (this.nestedItemsKey !== null) {
            filteredOptions.sort((a, b) => {
                return a[this.searchKey].toLowerCase().indexOf(searchString) - b[this.searchKey].toLowerCase().indexOf(searchString);
            });
            const uniqNestedOptionParentIds = uniq(filteredOptions.filter(option => option[this.parentIdKey] !== undefined).map(option => option[this.parentIdKey]));
            uniqNestedOptionParentIds.forEach(nestedOptionParentId => {
                const parentOption = options.find(option => option[this.identificationKey] === nestedOptionParentId);
                const parentOptionIndex = filteredOptions.indexOf(parentOption);
                if (parentOptionIndex === -1) {
                    const firstOptionIndex = filteredOptions.findIndex(option => option[this.parentIdKey] === nestedOptionParentId);
                    filteredOptions.splice(firstOptionIndex, 0, parentOption);
                } else {
                    const nestedOptionsInSelection = filteredOptions.filter(option => option[this.parentIdKey] === nestedOptionParentId);
                    nestedOptionsInSelection.forEach(nestedOption => {
                        filteredOptions.splice(filteredOptions.indexOf(nestedOption), 1);
                        filteredOptions.splice(parentOptionIndex, 0, nestedOption);
                    });
                }
            });
            return filteredOptions;
        }
        // We use array.sort to sort all the options by the index of the searchString.
        return filteredOptions.sort((a, b) => {
            if (optionsIsObject) {
                return a[this.searchKey].toLowerCase().indexOf(searchString) - b[this.searchKey].toLowerCase().indexOf(searchString);
            }
            return a.toLowerCase().indexOf(searchString) - b.toLowerCase().indexOf(searchString);
        });
    }

    onOptionSelected(option, indexInSelection): void {
        if (indexInSelection >= 0) {
            this.selectedOptions.splice(indexInSelection, 1);
        } else {
            this.selectedOptions.push(option);
        }
        this.onSelectedOptionsChange();
        this.$emit('optionClicked', option);
    }

    onSelectAllOptionsClick():void {
        this.isAllOptionsSelected = !this.isAllOptionsSelected;
    }

    onOptionWithNestedItemsSelected(nestedItemsValues): void {
        const isAllOptionsSelectedOnClick = this.getIsNestedOptionsSelected(nestedItemsValues);
        nestedItemsValues.forEach(nestedItemValue => {
            const indexInSelection = this.selectedOptions.indexOf(nestedItemValue);
            if (indexInSelection >= 0) {
                this.selectedOptions.splice(indexInSelection, 1);
            }
        });
        if (!isAllOptionsSelectedOnClick) {
            this.selectedOptions.push(...nestedItemsValues as any);
        }
        this.onSelectedOptionsChange();
    }

    getOptionValueForSelection(option: string | object): string | object {
        if (this.identificationKey !== null) {
            return (option as object)[this.identificationKey];
        }
        return option;
    }

    public clearSelection(): void {
        this.selectedOptions = [];
        this.onSelectedOptionsChange();
        this.$emit('clear', []);
    }

    onOpen(): void {
        this.$emit('open', this.selectedOptions);
    }

    onClose(): void {
        this.$emit('close', this.selectedOptions);
    }

    getIsNestedOptionsSelected(nestedItemsValues): boolean {
        return nestedItemsValues.every(nestedItemValue => this.selectedOptions.indexOf(nestedItemValue) >= 0);
    }
}
