
import Vue from 'vue';
import Component from 'vue-class-component';
import breakpointService from '@/core/responsive/breakpoints/breakpoint.service';

import { Prop } from 'vue-property-decorator';

    @Component({
        name: 'image-compare',
        components: {}
    })
export default class ImageCompare extends Vue {
        @Prop({
            required: true,
            type: String
        })
    public before: string;

        @Prop({
            required: true,
            type: String
        })
        public after: string;

        @Prop({
            required: false,
            default: 'after',
            type: String
        })
        public imageAfterAltText: string;

        @Prop({
            required: false,
            default: 'before',
            type: String
        })
        public imageBeforeAltText: string;

        @Prop({
            required: false,
            type: Number,
            default: 0.5
        })
        public offset: number;

        public imgOffset: ClientRect = null;
        public slideOffset: number = 0;
        public sliding: boolean = false;
        public containerStyle: object = {};
        public overlayStyle: object = {};

        get beforeImgStyle() {
            return { clip: `rect(0, ${this.x}px, ${this.h}px, 0)` };
        }

        get handleStyle() {
            const size = 30;
            return {
                width: `${size}px`,
                height: `${size}px`,
                top: `calc(50% - ${size / 2}px)`,
                left: `calc(${this.slideOffset * 100}% - ${size / 2}px)`
            };
        }

        get x(): number {
            return this.w * this.slideOffset;
        }

        get w(): number {
            if (this.imgOffset) { return this.imgOffset.width; }
        }

        get h(): number {
            if (this.imgOffset) { return this.imgOffset.height; }
        }

        private get imageAfterSrc():string {
            return this.responsiveImageSrc(this.after);
        }

        private get imageBeforeSrc():string {
            return this.responsiveImageSrc(this.before);
        }

        private responsiveImageSrc(src):string {
            const isSmallBreakpoint = breakpointService.isActiveBreakpoint('small');
            return isSmallBreakpoint ? `${src}?width=760` : src;
        }

        setDimensions() {
            const img = this.$el.querySelector('img');
            this.imgOffset = img.getBoundingClientRect();
            this.containerStyle = { width: `${this.w}px`, height: `${this.h}px`, left: '50%', marginLeft: `-${this.w / 2}px` };
            this.$nextTick(() => {
                this.imgOffset = this.$el.getBoundingClientRect();
            });
        }

        startSlide(event) {
            this.sliding = true;
            this.moveSlide(event);
            this.overlayStyle = { opacity: 0 };
        }

        moveSlide(event) {
            if (this.sliding) {
                let x = (event.touches ? event.touches[0].pageX : event.pageX) - this.imgOffset.left;
                x = (x < 0) ? 0 : ((x > this.w) ? this.w : x);

                this.slideOffset = (x / this.w);
            }
        }

        endSlide() {
            this.sliding = false;
            this.overlayStyle = {};
        }

        resize() {
            this.containerStyle = {};
            this.$nextTick(() => this.setDimensions());
        }

        mounted() {
            this.slideOffset = this.offset;
            document.addEventListener('touchmove', this.moveSlide);
            document.addEventListener('touchend', this.endSlide);
            document.addEventListener('mousemove', this.moveSlide);
            document.addEventListener('mouseup', this.endSlide);
            window.addEventListener('resize', this.resize);
        }

        beforeDestroy() {
            document.removeEventListener('touchmove', this.moveSlide);
            document.removeEventListener('touchend', this.endSlide);
            document.removeEventListener('mousemove', this.moveSlide);
            document.removeEventListener('mouseup', this.endSlide);
            window.removeEventListener('resize', this.resize);
        }
}
