export const enum Status {
    Neutral = 1,
    Stolen = 2,
    Sold = 3,
    Disabled = 4
}

export const enum Action {
    Stolen = 1,
    NotStolen = 2,
    Sold = 3,
    Deleted = 5,
    Updated = 6,
    Created = 7
}

export default interface IRegisteredProductAction {
    action: Action;
    name: string;
}