import { reactive } from 'vue';
import CheckoutService from '@/services/checkout.service';

const checkoutPOSState = reactive<ICheckoutStatePOS>({
    isProcessingOrder: false
});

export function useCheckoutPOS() {
    async function getCountryTaxTotal(countryCode) {
        return await CheckoutService.getPOSCountryTaxTotal(countryCode);
    }

    async function submitOrder(order) {
        checkoutPOSState.isProcessingOrder = true;
        try {
            return await CheckoutService.submitPOSOrder(order);
        } catch (error) {
            console.log(error);
            checkoutPOSState.isProcessingOrder = false;
        }
    };
    return {
        getCountryTaxTotal,
        submitOrder
    };
};
