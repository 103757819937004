
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { AppGetter } from '../../store';
import $ from 'cash-dom';
import VueProgrammaticInvisibleGoogleRecaptcha from 'vue-programmatic-invisible-google-recaptcha';
import userAgent from '@/services/userAgent.service';
import { useNewsletter } from '@/store/newsletter.composable';

@Component({
    name: 'newsletter-form',
    components: {
        VueProgrammaticInvisibleGoogleRecaptcha
    }
})
export default class NewsletterForm extends Vue {
    @AppGetter
    public termsUrl: string;

    @AppGetter
    public recaptchaKey: string;

    @Prop({ type: Boolean, default: true })
    public applyMaxWidth: boolean;

    @Prop({ type: Boolean, default: true })
    public showHeadline: boolean;

    @Prop({ type: String, default: '' })
    public lateRedirectPageLink: string;

    @Prop({ type: String, required: false })
    public contentId: string;

    public formDataPending = true;
    public submittingFormData = false;
    public formSubmitted = false;
    public filterBy: FilterByEnum = FilterByEnum.Private;
    public readonly filterByPrivateEnum = FilterByEnum.Private;
    public readonly filterByProfessionalEnum = FilterByEnum.Professional;
    public newsletterForm: SubscribeToNewsletterWebRequest = {
        interestedInOutdoor: false,
        interestedInIndoor: false,
        profilingFlagAccepted: false,
        newsletterFlagAccepted: false,
        userType: '',
        firstName: '',
        lastName: '',
        email: '',
        country: '',
        profession: '',
        company: '',
        recaptchaToken: '',
        contentId: '',
        channelOverride: ''
    };

    public newsletterFormData: NewsletterSignupFormViewModel = {
        userTypes: [],
        countries: [],
        professions: []
    };

    public getFormOptions = useNewsletter().getFormOptions;
    public submitFormOptions = useNewsletter().submitFormOptions;
    public getCountryData = useNewsletter().getCountryData;
    public privacyPolicyStatementHtml = useNewsletter().privacyPolicyStatementHtml;

    public existingCountryInformation: NewsletterExistingCountryViewObject = { url: null, visible: false };
    public selectedCountry: INewsletterCountryItem = {};
    public selectedProfession: INewsletterProfessionItem = {};
    public runAnimations = userAgent.isPrerender;
    public _uid: any; // Vue provided uniq ID
    public componentId = 'newsletterFormRecaptcha';
    public interestSelected = false;
    public showInterestSelectedErrorMessage = false;

    private labelNamespace = 'Pages.NewsletterSignup.NewsletterForm';
    private successMessageLabelNamespace = 'Pages.NewsletterSignup.SubmitSuccessMessage';

    get shouldValidateCheckboxes(): boolean {
        return this.filterBy === this.filterByProfessionalEnum;
    }

    public validateInterestCheckBoxes() {
        if (this.newsletterForm.interestedInIndoor || this.newsletterForm.interestedInOutdoor) {
            this.interestSelected = true;
            this.showInterestSelectedErrorMessage = false;
        } else {
            this.interestSelected = false;
            this.showInterestSelectedErrorMessage = true;
        }
    }

    @Watch('newsletterForm.interestedInIndoor')
    private onInterestIndoorCheckboxesChanged() {
        this.validateInterestCheckBoxes();
    }

    @Watch('newsletterForm.interestedInOutdoor')
    private onInterestOutdoorCheckboxesChanged() {
        this.validateInterestCheckBoxes();
    }

    mounted() {
        this.getFormOptions().then((data: NewsletterSignupFormViewModel) => {
            this.newsletterFormData = data;
            this.formDataPending = false;
            this.setCustomerTypeFilter();
            this.getDefaultSelectedCountry();
            this.getDefaultSelectedProfession();
        });
    }

    initAnimations(): void {
        this.runAnimations = true;
    }

    public getDefaultSelectedCountry() {
        this.selectedCountry = this.newsletterFormData.countries.find(x => x.isDefault);
        this.setCurrentSelectedCountry();
    }

    public getDefaultSelectedProfession() {
        this.selectedProfession = this.newsletterFormData.professions[0];
        this.setSelectedProfession();
    }

    public setCurrentSelectedCountry() {
        const request = { id: this.selectedCountry.id, displayName: this.selectedCountry.displayName };
        this.getCountryData(request)
            .then((response) => {
                this.existingCountryInformation = response;
            }, (err) => {
                console.warn(err);
            });
        this.newsletterForm.country = this.selectedCountry.displayName;
    }

    public setCurrentUserType() {
        this.newsletterForm.userType = this.filterBy;
    }

    public setSelectedProfession() {
        this.newsletterForm.profession = this.selectedProfession.id;
    }

    public validateAndSubmitRequest() {
        if (this.shouldValidateCheckboxes) {
            this.validateInterestCheckBoxes();
        }

        this.$validator
            .validateAll()
            .then(isValid => {
                if (isValid) {
                    if ((this.shouldValidateCheckboxes && !this.interestSelected)) {
                        return;
                    }
                    (this.$refs[this.componentId + this._uid] as any).execute();

                    if (this.lateRedirectPageLink != '') {
                        setTimeout(() => {
                            this.redirectToPage();
                        }, 15000);
                    }
                } else {
                    this.scrollInvalidElementIntoView();
                }
            })
            .catch(x => console.log('catch' + x));
    }

    public submitForm(recaptchaToken) {
        this.submittingFormData = true;
        this.newsletterForm.recaptchaToken = recaptchaToken;
        this.newsletterForm.contentId = this.contentId;
        this.submitFormOptions(this.newsletterForm)
            .then((response) => {
                this.handleSuccess();
            }, (err) => {
                this.submittingFormData = false;
            });
    }

    public redirectToPage() {
        document.location.href = this.lateRedirectPageLink;
    }

    private handleSuccess() {
        this.formSubmitted = true;
        this.submittingFormData = false;
        this.scrollToElement('newsletter-signup-success');
    }

    private setCustomerTypeFilter() {
        const defaultSelectedUserType = this.newsletterFormData.userTypes.find(x => x.isDefault) || this.newsletterFormData.userTypes[0];
        if (defaultSelectedUserType.id === FilterByEnum.Professional) {
            this.filterBy = FilterByEnum.Professional;
        } else {
            this.filterBy = FilterByEnum.Private;
        }
        this.setCurrentUserType();
    }

    private scrollInvalidElementIntoView() {
        const firstInvalidElement = $('.is-danger').first()[0];
        if (firstInvalidElement) {
            firstInvalidElement.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' });
        }
    }

    private scrollToElement(elementId: string): void {
        let element: HTMLElement;
        element = document.getElementById(elementId);

        if (!element) {
            setTimeout(() => {
                element = document.getElementById(elementId);
                element.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' });
            }, 1500);
        } else {
            element.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' });
        }
    }
}

enum FilterByEnum {
    Private = 'Private',
    Professional = 'Professional',
}

interface INewsletterCountryItem {
    displayName?: string;
    id?: string;
    isDefault?: boolean;
}

interface INewsletterProfessionItem {
    name?: string;
    id?: string;
}
