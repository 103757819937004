
import Component from 'vue-class-component';
import { Mixins } from 'vue-mixin-decorator';
import { BreakpointsMixin } from '@/core/responsive/breakpoints/breakpoint.mixin';
import {
    AppGetter,
    CatalogAction,
    CatalogGetter,
    RouteState
} from '@/store';
import DistributionDiagramImage from './DistributionDiagramImage.vue';
import { router } from '@/router';
import { QueryParams } from '@/core';
import { PurchaseCtaEnum } from '@/services/product.service';
import PriceInformationB2B from '@/ui/views/product-details/PriceInformationB2B.vue';
import { Watch } from 'vue-property-decorator';
import QueryParamsService from '@/core/query-params.service';

@Component({
    name: 'product-variant-overlay',
    components: {
        DistributionDiagramImage,
        PriceInformationB2B
    }
})
export default class ProductVariantOverlay extends Mixins<BreakpointsMixin>(BreakpointsMixin) {
    @AppGetter
    public isB2BAuthenticated: boolean;

    @AppGetter
    public theme: ColorTheme;

    @CatalogGetter
    public isShowingDataSpecs: boolean;

    @CatalogGetter
    public selectedConfigVariant: ProfessionalVariantViewObject;

    @CatalogGetter
    public product: ProductBaseViewModel<VariantBaseViewObject>;

    @CatalogAction
    public selectVariantConfig: ({ variant, pushToHistory }:{variant:VariantBaseViewObject, pushToHistory?:boolean}) => void;

    @CatalogAction
    public deselectVariantConfig: (clearRoutParam?:boolean) => void;

    @CatalogAction
    public toggleShowVariantDataSpecs: (flag: boolean) => void;

    @RouteState
    public query: any;

    private quoteToggledOn = false;

    get showPriceInformation(): boolean {
        const isAddToBasket = this.selectedConfigVariant.purchaseCta === (PurchaseCtaEnum.AddToBasketB2B as any);
        const isShowPricing = this.selectedConfigVariant.purchaseCta === (PurchaseCtaEnum.ShowPricingB2B as any);
        const isExpectingPriceInformationView = this.selectedConfigVariant && this.selectedConfigVariant.purchaseCta === (PurchaseCtaEnum.GetQuote as any) && this.isB2BAuthenticated;
        return isAddToBasket || isShowPricing || isExpectingPriceInformationView;
    }

    get showGetQuote(): boolean {
        return this.selectedConfigVariant.purchaseCta === (PurchaseCtaEnum.GetQuote as any);
    }

    mounted(): void {
        this.showConfiguredVariant();
    }

    @Watch('query')
    public onQueryChange(newQuery, oldQuery): void {
        this.quoteToggledOn = false;
        this.showConfiguredVariant();
    }

    private showConfiguredVariant(): void {
        const configuredVariantIdFromUrl = QueryParamsService.getParam(QueryParams.ConfiguredVariantId);
        const configuredVariant = this.product.variants.find(v => v.id === configuredVariantIdFromUrl);

        if (configuredVariant) {
            this.selectVariantConfig({ variant: configuredVariant });
            this.toggleShowVariantDataSpecs(true);
        } else {
            this.deselectVariantConfig(false);
            this.toggleShowVariantDataSpecs(false);
        }
    }

    handleClose(): void {
        const query = {
            ...router.currentRoute.query
        };
        if (query[QueryParams.ConfiguredVariantId]) {
            delete query[QueryParams.ConfiguredVariantId];
        }
        router.replace({ query });
    }
}
