import Http from '../core/http/http.service';
import { router } from '../router';
import Axios from 'axios';
class PageService {
    public async get(route: string): Promise<IPageResult> {
        try {
            const page = await Http.page(route);
            return page;
        } catch (error) {
            if (Axios.isCancel(error)) {
                throw error;
            }
            return this.handlePageError(error);
        }
    }

    public hardReload() {
        window.location.reload();
    }

    public softRedirect(url: string, replaceHistory?: boolean) {
        const action = replaceHistory ? 'replace' : 'push';
        router[action](url);
    }

    public hardRedirect(url: string) {
        window.location.href = url;
    }

    private handlePageError(error) {
        return this.getErrorPage(error.response.status);
    }

    private getErrorPage(status: number): IPageResult {
        return {
            statusCode: status,
            data: {
                analyticsTrackerScript: null,
                breadcrumb: [],
                content: '<div class=\'u-text-center u-p-12 u-text-h2\'>Server error</div>',
                isCheckoutView: false,
                isCheckoutViewWithTopBar: false,
                hideNavigationSpacer: false,
                hideBreadcrumb: true,
                previousUrlBreadcrumb: false,
                isNoIndexPage: true,
                metaData: {
                    description: 'Server error',
                    keywords: '',
                    title: 'Server error',
                    url: '',
                    image: '',
                    hrefLanguages: []
                },
                theme: {
                    pageBackgroundColor: '',
                    textColor: '',
                    footerBackgroundColor: '',
                    productTileBackgroundColor: '',
                    breadcrumbColor: ''
                },
                menuTheme: {
                    whiteMenuText: false,
                    removeTopPadding: false,
                    removeSpacingBeforeContent: false
                }
            }
        };
    }
}

export default new PageService();
