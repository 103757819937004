
import { AppGetter } from '@/store';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { StorageService } from '@/core/storage.service';
import LegacyBrowserService from '@/core/legacybrowser.service';
import serverContextService from '@/core/server-context.service';
import httpService from '@/core/http/http.service';
import { Mixins } from 'vue-mixin-decorator';
import { BreakpointsMixin } from '@/core/responsive/breakpoints/breakpoint.mixin';

type siteMode = 'Private' | 'Professional';

@Component({
    name: 'last-viewed-products'
})
export default class LastViewedProducts extends Mixins<BreakpointsMixin>(BreakpointsMixin) {
    @Prop({
        default: false
    }) public addTopMargin: boolean;

    @Prop({
        default: true
    }) public visible: boolean;

    @Prop({
        required: false,
        type: String || null
    }) public productId: string;

    @AppGetter
    public theme: ColorTheme;

    public productIds: string[] = [];
    public products: ProductTileViewModel[] = [];
    public showLeft: boolean = false;
    public showRight: boolean = false;
    public targetScrollIndex: number = 0;
    public isScrollingProducts: boolean = false;
    public browserGridSupported = false;

    get hasProducts() {
        return this.products && this.products.length > 0;
    }

    get isLargeBreakpoint() {
        return this.breakpointIsActive.large;
    }

    get isSmallBreakpoint() {
        return this.breakpointIsActive.small;
    }

    public created() {
        this.initialize();
        this.browserGridSupported = LegacyBrowserService.isBrowserGridSupported();
    }

    public prev() {
        this.scrollProducts(-1, -20);
    }

    public next() {
        this.scrollProducts(1, 20);
    }

    public scrollProducts(direction: number, scrollAmount: number) {
        if (!this.isScrollingProducts) {
            const tiles = this.$refs.lastviewedtiles as HTMLSpanElement;
            const tile = tiles.querySelector('.tiles__item');
            if (tiles && tile) {
                this.targetScrollIndex += direction;
                const offset = (tile.clientWidth + 50) * this.targetScrollIndex; // Get width of 1 tile plus spacing (50px is desktop spacing), and multiply with target index for product to scroll to

                const scrollToRight = () => {
                    let continueAnimation = false;
                    if (direction === -1) {
                        continueAnimation = tiles.scrollLeft > offset;
                    } else {
                        continueAnimation = tiles.scrollLeft < offset;
                    }

                    if (continueAnimation) {
                        this.isScrollingProducts = true;
                        window.requestAnimationFrame(scrollToRight);
                        tiles.scrollLeft += scrollAmount;
                    } else {
                        this.isScrollingProducts = false;
                    }
                    this.showRight = (tiles.scrollLeft + tiles.getBoundingClientRect().width) < tiles.scrollWidth;
                    this.showLeft = this.targetScrollIndex !== 0;
                };
                scrollToRight();
            }
        }
    }

    public fetchProducts() {
        if (this.productIds.length) {
            httpService.get('/producttile/get', { id: this.productIds }).then((response: ProductTileViewModel[]) => {
                this.products = response;
                if (this.products.length > 3) {
                    this.showRight = true;
                }
            });
        }
    }

    public tileStyles(product: ProductTileViewModel) {
        const backgroundColor = this.theme.productTileBackgroundColor ? this.theme.productTileBackgroundColor : product.backgroundColor;
        return backgroundColor ? { backgroundColor } : {};
    }

    private getLastViewedProductsIdsFromMarket(localStorageKey: string) {
        return new Set((StorageService.getItemAs(localStorageKey) as string[]) || ([] as string[]));
    }

    private generateLocalStorageKey(market: string, siteMode: string): string {
        const localStoragePrefix: string = 'LastViewedProducts';

        return `${localStoragePrefix}-${market}-${siteMode}`;
    }

    private filterAndReverseProductIds(lastViewedProductIds: Set<string>, productId: string): Array<string> {
        const collection: Array<string> = Array.from(lastViewedProductIds);

        const filteredCollection:Array<string> = collection.filter(id => {
            return id !== productId;
        });

        const reversedCollection: Array<string> = filteredCollection.reverse();

        return reversedCollection;
    }

    private initialize() {
        const language = serverContextService.languages.find(l => l.isSelected) as any;

        const market: string = language ? language.code : 'en';
        const siteMode: siteMode = serverContextService.isPrivateMode ? 'Private' : 'Professional';

        const localStorageKey: string = this.generateLocalStorageKey(market, siteMode);
        const lastViewedProductIds = this.getLastViewedProductsIdsFromMarket(localStorageKey);

        if (this.productId) {
            // if the productId is already in the set, deleting and readding moves it to the last position
            if (lastViewedProductIds.has(this.productId)) {
                lastViewedProductIds.delete(this.productId);
            }

            lastViewedProductIds.add(this.productId);
            // store the updated array
            StorageService.setItemAs(localStorageKey, Array.from(lastViewedProductIds));
        }

        this.productIds = this.filterAndReverseProductIds(lastViewedProductIds, this.productId);

        this.fetchProducts();
    }
}
