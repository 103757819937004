import { computed, watch } from 'vue';
import { Route } from 'vue-router';
import { useRoute } from 'vue-router/composables';

export function watchRelativeUrl(cb: (relativeUrl: RelativeUrl, oldRelativeUrl: RelativeUrl) => void) : Route {
    const route = useRoute();
    const ru = computed<RelativeUrl>(() => ({ path: route.path, query: route.query }));
    watch(() => ru.value, cb, { deep: true, immediate: true });
    return route;
}
