import Http from '@/core/http/http.service';

class NewsletterService {
    getFormOptions(): Promise<NewsletterSignupFormViewModel> {
        return Http.get<NewsletterSignupFormViewModel>('newsletter/getformoptions');
    }

    submitFormOptions(request: SubscribeToNewsletterWebRequest): Promise<string> {
        return Http.post<string>('newsletter/subscribe', request);
    }

    getCountryData(request: NewsletterExistingCountryWebRequest): Promise<NewsletterExistingCountryViewObject> {
        return Http.post<NewsletterExistingCountryViewObject>('newsletter/getcountrydata', request);
    }

    public acceptMarketingEmails(request: AcceptMarketingEmailsWebRequest): Promise<string> {
        return Http.post<string>('checkoutb2c/submitMarketingEmailPermission', request);
    }

    submitSignUpDiscountForm(request: SignUpDiscountEmailWebRequest): Promise<string> {
        return Http.post<string>('signupdiscount/subscribewithsignupdiscount', request);
    }

    updateEmailProfile(request: UpdateEmailProfileWebRequest): Promise<string> {
        return Http.post<string>('signupdiscount/updateemailprofile', request);
    }
}

export default new NewsletterService();
