import { SwiperManager } from '@/ui/shared/swiper';
import { useCustomLoadManager, DesicionContext, SlideRenderState } from '@/ui/shared/swiper/lazyload';
import { useSaveData } from '@/ui/composables';

export const useMainImageLoadManager = (swiperManager: SwiperManager) => {
    const saveData = useSaveData();

    const decisionLogic = (index: number, context: DesicionContext): SlideRenderState => {
        const { activeIndex, isAnimating, hasHovered, hasInteracted, timerStatuses } = context;

        if (index === activeIndex) {
            return SlideRenderState.Full;
        }

        const getIndexAtOffset = (offset: number): number | undefined => {
            if (swiperManager.swiperOptions.loop) {
                return (activeIndex + offset + swiperManager.slides.value.length) % swiperManager.slides.value.length;
            } else {
                const newIndex = activeIndex + offset;
                if (newIndex < 0 || newIndex >= swiperManager.slides.value.length) {
                    return undefined;
                }
                return newIndex;
            }
        };

        const [timerOneCompleted, timerTwoCompleted, timerThreeCompleted, timerFourCompleted] = timerStatuses;
        const hasInteractedSomehow = (hasHovered || hasInteracted);

        const nextSlide = getIndexAtOffset(1);
        const prevSlide = getIndexAtOffset(-1);
        if ((index === nextSlide) || (index === prevSlide)) {
            if (timerOneCompleted || hasInteractedSomehow) return SlideRenderState.VisuallyReady;
        }

        if (isAnimating) return SlideRenderState.None; // don't preload other than prev/next while sliding, it hurts performance
        if (saveData) return SlideRenderState.None; // don't preload if user has save data mode enabled

        const nextNextSlide = getIndexAtOffset(2);
        if (index === nextNextSlide && (hasInteractedSomehow || timerTwoCompleted)) {
            return SlideRenderState.VisuallyReady;
        }

        if (timerThreeCompleted && hasInteractedSomehow) {
            return SlideRenderState.Prefetch;
        }

        if (timerFourCompleted) {
            return SlideRenderState.Prefetch;
        }

        return SlideRenderState.None;
    };

    const timers = [
        { delay: 500, random: 200 }, // When to load prev and next, and prefetch next-next
        { delay: 2000, random: 200 }, // When to prefetch next-next
        { delay: 2500, random: 500 }, // When to prefetch all slides (provided the user has hovered or interacted)
        { delay: 3500, random: 500 } // When to prefetch all slides (even if the user hasn't interacted)
    ];

    return useCustomLoadManager(swiperManager, decisionLogic, timers);
};
