class DatePickerService {
    public validateDateStringLength(input: string): boolean {
        // Check if the length of the string (with special characters removed) is equal to 8 (ddMMyyyy), if not we wont convert the string to a Date
        return this.stripSpecialCharacters(input).length !== 8;
    }
    public validateDateYear(input: Date): boolean {
        /*
         * If the chosen date is lower than or equal to "1873" (LP was established in 1874 so anything before that is irrelevant)
         * We will therefore return false if the date is before 1873
         */
        return input.getFullYear() < 1874;
    }

    public stripSpecialCharacters(input: string): string {
        return input.replace(/[^[a-zA-Z0-9]/g, '');
    }
}

export default new DatePickerService();

