import { render, staticRenderFns } from "./OldLoginForm.vue?vue&type=template&id=7970d2ec&scoped=true&"
import script from "./OldLoginForm.vue?vue&type=script&setup=true&lang=ts&"
export * from "./OldLoginForm.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./OldLoginForm.vue?vue&type=style&index=0&id=7970d2ec&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7970d2ec",
  null
  
)

export default component.exports