/*
This manager class is used to manage the Swiper instance and the slides.

The reason for the complexity of this class is that Swiper.js does not play well with Vue dynamically changing the content of the swiper.
Swiperjs looses track and Vue looses track. So we need to fully control setup and content change, which is the primary purpose of this class.
This is also why the class needs to control the slides array (in order to detect changes and update accordingly)

Ideas for improvement:
- Performance option to only render the x first slides at load (and insert blank slides for the rest), but replace those blank slides
  with real content upon hover / first interaction complete
*/

import { Ref, ref, watch, onMounted, onUnmounted, nextTick } from 'vue';
import { Swiper } from 'swiper';
import { SwiperOptions } from 'swiper/types';
import { Zoom } from 'swiper/modules';

const isSwiperInLoopMode = (swiper: Swiper) => {
    return swiper.params && swiper.params.loop;
};

export class SwiperManager<T = unknown> {
    public slides: Ref<T[]> = ref([]);
    private contentVersion = 0;
    public slidesAndRenderKey: Ref<{ slides: T[]; key: number }> = ref({
        slides: [],
        key: 0
    });

    private swiperEl: HTMLElement | undefined;

    public swiperOptions: SwiperOptions;
    public activeIndex: Ref<number | undefined> = ref();
    public activeSlide: Ref<T | undefined> = ref();
    public swiper: Ref<Swiper | undefined> = ref();
    public hasHovered: Ref<boolean> = ref(false);
    public hasInteracted: Ref<boolean> = ref(false);
    public isAnimating: Ref<boolean> = ref(false);

    constructor(swiperOptions?: SwiperOptions) {
        this.swiper.value = undefined;

        const defaultSwiperOptions: SwiperOptions = {
            direction: 'horizontal',
            slidesPerView: 1,
            spaceBetween: 0,
            loop: true
        };

        this.swiperOptions = {
            ...defaultSwiperOptions,
            ...(swiperOptions.zoom ? { ...swiperOptions, modules: [Zoom] } : swiperOptions)
        };
    }

    setSwiperElement(swiperEl: HTMLElement) {
        this.swiperEl = swiperEl;
    }

    getSwiperElement() {
        return this.swiperEl;
    }

    // This will trigger an update in ManagedSwiper. The new key will ensure that Vue does not re-use slides, which
    // would mess up the order because swiper.js cannot handle that its html elements gets rearranged from the outside
    private updateSlidesAndRenderKey() {
        this.contentVersion++;
        this.slidesAndRenderKey.value = {
            slides: this.slides.value,
            key: this.contentVersion
        };
    };

    private resetInteractionState() {
        this.hasHovered.value = false;
        this.isAnimating.value = false;
        this.hasInteracted.value = false;
    }

    private onSlidesUpdate() {
        if (!this.swiper.value) {
            return;
        }

        this.resetInteractionState();
        this.updateSlidesAndRenderKey();

        nextTick(() => {
            if (!this.swiper.value) {
                return;
            }
            const swiper = this.swiper.value;

            if (isSwiperInLoopMode(swiper)) {
                // A simple swiper.update() is not enough when new slides are replaced / added in loop mode.
                // The reason is that the new content in loop mode needs "data-swiper-slide-index" attributes - and swiper.js does not
                // detect / fix / re-add these automatically

                // PS: I peeked into swiper "modulation" module and mimic how they handle updating the swiper when slides are appended.
                // some of these methods are actually only intended for internal use, but since they are used in the official module, they need to be exposed,
                // and it would be surprising if they should be removed in the future. If this should become a problem, there is the alternative to completely
                // destroy and recreate the swiper instance instead

                if (this.slides.value.length > 1) {
                    swiper.loopDestroy();
                    // @ts-ignore-next-line
                    swiper.recalcSlides();
                    swiper.loopCreate();
                    swiper.update();
                } else {
                    // prevent swiper from looping when there is only one slide
                    swiper.params.loop = false;
                    swiper.update();
                }
            } else {
                swiper.update();
            }

            swiper.slideTo(0, 0, false);
        });
    }

    setSlidesRef(slides: Ref<T[]>) {
        this.slides = slides;
        this.resetInteractionState();
        this.updateSlidesAndRenderKey();
        watch(this.slides, () => {
            this.onSlidesUpdate();
        });
    }

    private updateActiveIndexAndSlide(swiper: Swiper) {
        this.activeIndex.value = swiper.realIndex;
        this.activeSlide.value = this.slides.value[this.activeIndex.value];
    }

    initSwiper() {
        if (this.swiperEl) {
            if (this.swiperOptions.loop && this.slides.value.length <= 1) {
                // Swiper does not handle it gracefully when there is only one slide in loop mode, so we take measures to avoid it
                this.swiperOptions.loop = false;
            }

            this.swiper.value = new Swiper(this.swiperEl, this.swiperOptions);
            this.updateActiveIndexAndSlide(this.swiper.value);
            this.swiper.value.on('slideChange', (swiper) => {
                this.updateActiveIndexAndSlide(swiper);
            });
            this.swiper.value.on('transitionStart', () => {
                this.isAnimating.value = true;
                this.hasInteracted.value = true;
            });
            this.swiper.value.on('transitionEnd', () => {
                this.isAnimating.value = false;
            });
        }
    }

    slideNext() {
        this.swiper.value && this.swiper.value.slideNext();
    }

    slidePrev() {
        this.swiper.value && this.swiper.value.slidePrev();
    }

    slideTo(index: number) {
        this.swiper.value && this.swiper.value.slideTo(index);
    }

    slideToLoop(index: number) {
        this.swiper.value && this.swiper.value.slideToLoop(index);
    }

    getActiveSlideElement(): HTMLElement | null {
        if (!this.swiper.value) {
            return null;
        }
        const activeIndex = this.swiper.value.activeIndex;
        const slides = this.swiper.value.slides;
        return slides[activeIndex] as HTMLElement;
    }

    reportMouseOver() {
        this.hasHovered.value = true;
    }
}
interface UseSwiperParams<T> {
    slides: Ref<T[]>;
    swiperOptions?: SwiperOptions;
}

export function useSwiper<T>(params: UseSwiperParams<T>) {
    const swiperManager = new SwiperManager<T>(params.swiperOptions || {});
    swiperManager.setSlidesRef(params.slides);

    onMounted(() => {
        swiperManager.initSwiper();
    });

    onUnmounted(() => {
        if (swiperManager.swiper.value) {
            swiperManager.swiper.value.destroy();
        }
    });

    return {
        swiperManager
    };
}
