import { GetterTree, MutationTree, ActionTree } from 'vuex';
import { QueryParams } from '../core';
import QueryParamsService from '../core/query-params.service';
import { router } from '../router';
import { namespace } from 'vuex-class';
import ProductService from '../services/product.service';
import CountryService from '@/services/country.service';
import NotificationService from '../core/notification.service';
import DictionaryService from '../core/dictionary.service';
import _ from 'lodash';
import ImageWithLinkViewObject = Vertica.LouisPoulsen.Application.Content.Blocks.PropertyLists.ImageWithLink.ImageWithLinkViewObject;
import VariantConfiguratorOptionsViewObject = Vertica.LouisPoulsen.Application.Content.Pages.Product.VariantConfiguratorOptionsViewObject;
import { ProductTileView } from '@/enums';

export const ProductConfiguratorMultiColorProperty = 'multiColor';

enum MutationMethod {
    setSelectedVariant = 'setSelectedVariant',
    setFilteredConfigVariants = 'setFilteredConfigVariants',
    setSelectedVariantConfig = 'setSelectedVariantConfig',
    setIsShowingDataSpecs = 'setIsShowingDataSpecs',
    setIsShowingVariantList = 'setIsShowingVariantList',
    setProductTileView = 'setProductTileView',
    setProductTileColorFilters = 'setProductTileColorFilters'
}

const storeState: ICatalogState = {
    product: { variants: [] } as ProductBaseViewModel<VariantBaseViewObject>,
    selectedVariant: {} as PrivateVariantViewObject | ProfessionalVariantViewObject,
    filteredConfigVariants: [],
    selectedConfigVariant: {} as VariantBaseViewObject,
    productInspirationImagesProfessional: [] as ImageWithLinkViewObject[],
    isShowingDataSpecs: false,
    isShowingVariantList: false,
    gaTrackingGoal: '',
    productConfiguratorProperties: [] as VariantConfiguratorOptionsViewObject[],
    quoteConfiguratorOptions: [] as VariantConfiguratorOptionsViewObject[],
    productTileView: ProductTileView.Product,
    productTileColorFilters: []
};

const storeGetters: GetterTree<ICatalogState, any> = {
    product: s => s.product,
    productVariants: s => s.product.variants,
    productPropertySelectorVariants: s => s.product.variants.filter(v => (v as ProfessionalVariantViewObject).isPropertySelectorOption),
    productVariantLisHeaders: s => {
        const product = (s.product as ProfessionalProductViewModel);
        return product.variantList && product.variantList.headers ? product.variantList.headers : [];
    },
    selectedVariant: s => s.selectedVariant || {},
    filteredConfigVariants: s => s.filteredConfigVariants,
    selectedConfigVariant: s => s.selectedConfigVariant || {},
    productInspirationImagesProfessional: s => s.productInspirationImagesProfessional.filter((img:ImageWithLinkViewObject) => img.imageUrl !== null),
    productConfiguratorProperties: s => s.productConfiguratorProperties,
    quoteConfiguratorOptions: s => s.quoteConfiguratorOptions,
    isShowingDataSpecs: s => s.isShowingDataSpecs,
    isShowingVariantList: s => s.isShowingVariantList,
    gaTrackingGoal: s => s.product.productTracking.trackingGoal,
    productTileView: s => s.productTileView,
    productTileColorFilters: s => s.productTileColorFilters,
    validProductPropertySelectorTypes: () => ['colorCode', 'armText', 'sizeText', 'mountingText', 'additionalFeatures', 'shieldDiffusor', 'body']
};

const actions: ActionTree<ICatalogState, any> = {
    selectVariant({ commit }, variant) {
        const query = QueryParamsService.appendQueryParam(QueryParams.SelectedVariantId, variant.id);
        router.replace({ query });
        commit(MutationMethod.setSelectedVariant, variant);
    },
    selectVariantConfig({ commit }, { variant, pushToHistory }: {variant:VariantBaseViewObject, pushToHistory?:boolean}) {
        if (variant.id !== null && variant.id !== undefined) {
            const query = QueryParamsService.appendQueryParam(QueryParams.ConfiguredVariantId, variant.id);
            const action = pushToHistory ? 'push' : 'replace';
            router[action]({ query });
        }
        commit(MutationMethod.setSelectedVariantConfig, variant);
    },
    deselectVariantConfig({ commit }, clearRoutParam = true) {
        if (clearRoutParam) {
            const query = QueryParamsService.removeQueryParam(QueryParams.ConfiguredVariantId);
            if (query[QueryParams.ConfiguredVariantId] === undefined) {
                delete query[QueryParams.ConfiguredVariantId];
            }
            router.replace({ query });
        }
        commit(MutationMethod.setSelectedVariantConfig, {});
    },
    setFilteredConfigVariants({ commit }, variants) {
        commit(MutationMethod.setFilteredConfigVariants, variants);
    },
    toggleShowVariantDataSpecs({ commit }, flag) {
        commit(MutationMethod.setIsShowingDataSpecs, flag);
    },
    toggleShowVariantList({ commit }, flag) {
        commit(MutationMethod.setIsShowingVariantList, flag);
    },
    toggleProductTileView({ commit }, view: ProductTileView) {
        commit(MutationMethod.setProductTileView, view);
    },
    setProductTileColorFilters({ commit }, filters: string[]) {
        commit(MutationMethod.setProductTileColorFilters, filters);
    },
    async sendQuote({ commit }, request) {
        return ProductService.sendQuote(request).then(() => {
            NotificationService.success(
                DictionaryService.get('Pages.ProductDetail.GetQuote.SuccessNotificationTitle'),
                DictionaryService.get('Pages.ProductDetail.GetQuote.SuccessNotificationMessage')
            );
        });
    },

    async sendQuoteNewsletter({ commit }, request) {
        return ProductService.sendQuoteNewsletter(request).then(() => {
            NotificationService.success(
                DictionaryService.get('Pages.ProductDetail.GetQuote.SuccessNotificationTitle'),
                DictionaryService.get('Pages.ProductDetail.GetQuote.SuccessNotificationMessage')
            );
        });
    },
    async sendSpecificationQuote({ commit }, request) {
        return ProductService.sendSpecificationQuote(request).then(() => {
            NotificationService.success(
                DictionaryService.get('Pages.ProductDetail.GetQuote.SuccessNotificationTitle'),
                DictionaryService.get('Pages.ProductDetail.GetQuote.SuccessNotificationMessage')
            );
        });
    },
    async sendPrivateQuote({ commit }, request) {
        return ProductService.sendPrivateQuote(request).then(() => {
            NotificationService.success(
                DictionaryService.get('Pages.ProductDetail.GetQuoteB2C.SuccessNotificationTitle'),
                DictionaryService.get('Pages.ProductDetail.GetQuoteB2C.SuccessNotificationMessage')
            );
        });
    },
    async getQuoteCountries({ commit }, request) : Promise<CountryViewModel[]> {
        try {
            return await CountryService.getCountries();
        } catch (error) {
            return error;
        }
    },
    async getCountryStates({ commit }, countryCode): Promise<StateViewObject[]> {
        try {
            return await CountryService.getCountryStates(countryCode);
        } catch (error) {
            return error;
        }
    },
    async getProfessions({ commit }, request) {
        try {
            return await ProductService.getProfessions();
        } catch (error) {
            return error;
        }
    }
};

const mutations: MutationTree<ICatalogState> = {
    [MutationMethod.setSelectedVariant](state, variant: VariantBaseViewObject) {
        state.selectedVariant = variant;
    },
    [MutationMethod.setSelectedVariantConfig](state, variant) {
        state.selectedConfigVariant = variant;
    },
    [MutationMethod.setFilteredConfigVariants](state, variants) {
        state.filteredConfigVariants = variants;
    },
    [MutationMethod.setIsShowingDataSpecs](state, flag) {
        state.isShowingDataSpecs = flag;
    },
    [MutationMethod.setIsShowingVariantList](state, flag) {
        state.isShowingVariantList = flag;
    },
    [MutationMethod.setProductTileView](state, productTileView) {
        state.productTileView = productTileView;
    },
    [MutationMethod.setProductTileColorFilters](state, filters) {
        state.productTileColorFilters = filters;
    }
};

export default {
    namespaced: true,
    state: storeState,
    getters: storeGetters,
    actions,
    mutations
};

const moduleName = 'catalog';
const moduleObject = namespace(moduleName);

export const CatalogGetter = moduleObject.Getter;
export const CatalogMutation = moduleObject.Mutation;
export const CatalogAction = moduleObject.Action;
export const CatalogState = moduleObject.State;
