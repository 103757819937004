
import { Component, Prop, Watch } from 'vue-property-decorator';
import AccordionItem from '@/ui/shared/accordion/AccordionItem.vue';
import Http from '@/core/http/http.service';
import DownloadCenterFileTypeResult from '@/ui/views/download-center/DownloadCenterFileTypeResult.vue';
import { AppGetter, downloadCenterAction, downloadCenterGetter } from '@/store';
import { Mixins } from 'vue-mixin-decorator';
import { TranslateMixin } from '@/core/translate.mixin';

@Component({
    components: {
        DownloadCenterFileTypeResult
    }
})
export default class DownloadCenterFileTypeAccordion extends Mixins<TranslateMixin>(TranslateMixin) {
    @downloadCenterGetter
    public getSelectionForType: (typeId: string) => string[];

    @downloadCenterGetter
    public selectedProductIds: string[];

    @downloadCenterAction
    public setNumberOfResults: (payload: {typeId: string, amount: number}) => void;

    @AppGetter
    public borderColor: (opacity: number) => string;

    @Prop({
        type: Object,
        required: true
    }) type: DownloadCenterTypeViewObject;

    @Prop({
        type: Boolean,
        required: false,
        default: false
    }) defaultOpen: boolean;

    result: FileReferenceViewObject[] | null = null;
    isLoading = false;
    expanded = false;

    get selectedItemsCount(): number {
        return this.getSelectionForType(this.type.id).length;
    }

    $refs!: {
        accordionItem: Vue
    };

    mounted(): void {
        this.getResult();
    }

    beforeDestroy() {
        this.setNumberOfResults({
            typeId: this.type.id,
            amount: 0
        });
    }

    get filteredResult() {
        if (this.result === null) {
            return [];
        }
        return this.selectedProductIds.length === 0
            ? this.result
            : this.result.filter(resultFile => resultFile.productIds.some(productId => this.selectedProductIds.indexOf(productId) >= 0) || resultFile.productIds.length === 0);
    }

    @Watch('filteredResult')
    onFilteredResultChange() {
        this.setNumberOfResults({
            typeId: this.type.id,
            amount: this.filteredResult.length
        });
    }

    @Watch('defaultOpen')
    onStartOpenChange(): void {
        if (this.defaultOpen) {
            this.toggleAccordionExpanded();
        }
    }

    toggleAccordionExpanded(): void {
        this.$nextTick().then(() => {
            (this.$refs.accordionItem as any).toggleExpanded(true);
        });
    }

    onAccordionExpandedChange(isExpanded): void {
        this.expanded = isExpanded;
    }

    get panelClass() {
        return 'u-py-16p u-text-ellipsis u-cursor-pointer u-text-medium u-font-normal u-relative u-border-t ' + this.borderColor(25);
    }

    getResult(): void {
        if (this.result !== null) {
            return;
        }

        this.isLoading = true;
        const payload = { type: this.type.id };

        Http.get('downloadcenter/list', payload).then((result: FileReferenceViewObject[]) => {
            this.result = result;
            this.isLoading = false;
        });
    }
}
