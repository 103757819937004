import { GetterTree, MutationTree, ActionTree } from "vuex";
import { namespace } from "vuex-class";
import ServerContextService from "@/core/server-context.service";
import Http from "@/core/http/http.service";

enum MutationMethod {
    setState = "setState"
}
interface IHideB2BPriceState {
    hideB2BPrices: boolean;
}
const hideB2BPriceState: IHideB2BPriceState = {
    hideB2BPrices: ServerContextService.initialHideB2BPricesState
};

const getters: GetterTree<IHideB2BPriceState, any> = {
    hideB2BPrices: state => state.hideB2BPrices,
};

const actions: ActionTree<IHideB2BPriceState, any> = {
    async toggle({ commit }) {
        let result = await Http.post<boolean>("hideb2bprices/toggle");
        commit(MutationMethod.setState, result);
    }
};

const mutations: MutationTree<IHideB2BPriceState> = {
    [MutationMethod.setState](state, flag) {
        state.hideB2BPrices = flag;
    }
};

export default {
    namespaced: true,
    state: hideB2BPriceState,
    getters,
    actions,
    mutations
};

const moduleName = "hideb2bprices";
const moduleObject = namespace(moduleName);

export const HideB2BPricesGetter = moduleObject.Getter;
export const HideB2BPricesMutation = moduleObject.Mutation;
export const HideB2BPricesAction = moduleObject.Action;
export const HideB2BPricesState = moduleObject.State;
