import { StorageService } from '@/core/storage.service';
import NewFeatureInfo = Vertica.LouisPoulsen.Application.NewFeatureInfo.Api.Responses.NewFeatureInfo;

const newFeaturesQueueStorageKey = 'communicationpopup-queue';

export function pop():NewFeatureInfo | void {
    const queuedFeaturesJson:string = StorageService.getItem(newFeaturesQueueStorageKey);
    if (queuedFeaturesJson) {
        const queuedFeaturesArray = JSON.parse(queuedFeaturesJson) as NewFeatureInfo[];
        if (queuedFeaturesArray.length > 0) {
            push(queuedFeaturesArray.slice(1));
            return queuedFeaturesArray[0];
        }
    }
}

export function push(newFeatures: NewFeatureInfo[]) {
    StorageService.setItem(newFeaturesQueueStorageKey, JSON.stringify(newFeatures));
}
