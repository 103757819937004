import { ActionTree } from 'vuex';
import { namespace } from 'vuex-class';
import TraderFormService from '../services/traderform.service';
import YourStoriesFormBlockWebRequest = Vertica.LouisPoulsen.Application.Content.Requests.YourStoriesFormBlockWebRequest;

const actions: ActionTree<any, any> = {
    async submitSignupForm({ commit }, request: TraderSignupFormBlockWebRequest) {
        return await TraderFormService.submitFormOptions(request);
    },
    async submitYourStoriesForm({ commit }, request: YourStoriesFormBlockWebRequest) {
        return await TraderFormService.submitYourStories(request);
    }
};

export default {
    namespaced: true,
    actions
};

const moduleName = 'traderform';
const moduleObject = namespace(moduleName);

export const TraderFormAction = moduleObject.Action;
