import { render, staticRenderFns } from "./ProductViewInAugmentedReality.vue?vue&type=template&id=361dc13d&scoped=true&"
import script from "./ProductViewInAugmentedReality.vue?vue&type=script&lang=ts&setup=true&"
export * from "./ProductViewInAugmentedReality.vue?vue&type=script&lang=ts&setup=true&"
import style0 from "./ProductViewInAugmentedReality.vue?vue&type=style&index=0&id=361dc13d&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "361dc13d",
  null
  
)

export default component.exports