import Http from '@/core/http/http.service';
import FinalizeMfaSetupWebRequest = Vertica.LouisPoulsen.Application.Authentication.Requests.FinalizeMfaSetupWebRequest;
import FinalizeMfaSetupViewModel = Vertica.LouisPoulsen.Application.Authentication.ViewModels.FinalizeMfaSetupViewModel;
import VerifyMfaWebRequest = Vertica.LouisPoulsen.Application.Authentication.Requests.VerifyMfaWebRequest;
import VerifyMfaViewModel = Vertica.LouisPoulsen.Application.Authentication.ViewModels.VerifyMfaViewModel;
import SetupMfaViewModel = Vertica.LouisPoulsen.Application.Authentication.ViewModels.SetupMfaViewModel;
import SetupMfaWebRequest = Vertica.LouisPoulsen.Application.Authentication.Requests.SetupMfaWebRequest;

class MfaService {
    public async setup(token: string): Promise<SetupMfaViewModel> {
        const request: SetupMfaWebRequest = {
            token
        };

        return Http.post('mfa/SetupMfaAsync', request)
            .then((response: SetupMfaViewModel) => {
                return response;
            });
    }

    public async finalizeSetup(mfaOtpForm: IMfaOtpForm, token: string): Promise<FinalizeMfaSetupViewModel> {
        const { otpCode } = mfaOtpForm;

        const request: FinalizeMfaSetupWebRequest = {
            token,
            code: otpCode
        };

        return Http.post('mfa/FinalizeMfaSetupAsync', request)
            .then((response: FinalizeMfaSetupViewModel) => {
                return response;
            });
    }

    public async verify(mfaOtpForm: IMfaOtpForm, token: string): Promise<VerifyMfaViewModel> {
        const { otpCode } = mfaOtpForm;

        const request: VerifyMfaWebRequest = {
            token,
            code: otpCode
        };

        return Http.post('mfa/VerifyMfaAsync', request)
            .then((response: VerifyMfaViewModel) => {
                return response;
            });
    }
}

export default new MfaService();
