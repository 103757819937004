
    import Vue from "vue";
    import Component from "vue-class-component";
    import { Prop } from "vue-property-decorator";

    @Component({
        name: "tab"
    })
    export default class Tab extends Vue {
        @Prop() public title: string;
        @Prop() public isDisabled: boolean;
        @Prop() public id: string;

        public isActive: boolean = false;
    }
