
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { AppGetter } from "../../store/app.store";
import ScrollService from "../../core/scroll.service";
import QueryParamService, { QueryParams } from "../../core/query-params.service";
import bus from '../../core/bus';
import { SpaPageReadyEventKey } from '../../router';

@Component({
    name: "ServerView"
})
export default class ServerView extends Vue {

    @Prop() private datakey: string;
    @AppGetter private pageData: (key: string) => string;

    private get page() {
        const content = this.pageData(this.datakey);
        if (!content) {
            return null;
        }

        return {
            template: `<div>${decodeAttr(content)}</div>`
        };

        // We manually decode html entities in attributes to prevent issue
        // where Vue doesn't decode html entities correctly
        // The issue would result in "&#230;" showing up as text in the browser
        // https://github.com/vuejs/vue/issues/7970
        // https://github.com/vuejs/vue/issues/7969

        function decodeAttr(html: string) {
           const textArea = document.createElement('textarea');
           textArea.innerHTML = escapeQuote(content);
           return deescapeQuote(textArea.innerText);

           function escapeQuote(html: string) {
               html = html.replace(/&quot;/gi, '__$__');
               html = html.replace(/&#39;/gi, '__%__');
               html = html.replace(/&nbsp;/gi, '__@__');
               return html;
           }

           function deescapeQuote(html: string) {
               html = html.replace(/__\$__/g, '&quot;');
               html = html.replace(/__\%__/g, '&#39;');
               html = html.replace(/__\@__/g, '&nbsp;');
               return html;
           }
       }
    }

    // for timing scroll in new view after transition
    private animationEnterDone() {
        bus.emit(SpaPageReadyEventKey);
    }
}
