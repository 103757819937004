import Http from '../core/http/http.service';
import SapVariantViewModel = Vertica.LouisPoulsen.Application.Commerce.ViewModels.SapVariantViewModel;
import GetSpecificationQuoteWebRequest = Vertica.LouisPoulsen.Application.Commerce.Requests.GetSpecificationQuoteWebRequest;
import GetPrivateQuoteWebRequest = Vertica.LouisPoulsen.Application.Commerce.Requests.GetPrivateQuoteWebRequest;
import CalculateDiscountedPriceWebRequest = Vertica.LouisPoulsen.Application.Commerce.Requests.Business.CalculateDiscountedPriceWebRequest;
import CalculateDiscountedPriceViewModel = Vertica.LouisPoulsen.Application.Commerce.ViewModels.CalculateDiscountedPriceViewModel;

class ProductService {
    search(term: string): Promise<SearchResultsViewModel> {
        const request: SearchWebRequest = { term } as SearchWebRequest;
        return Http.get<SearchResultsViewModel>('search/search', request);
    }

    sendQuote(request: GetQuoteWebRequest) {
        return Http.post<any>('quote/getquote', request);
    }

    sendQuoteNewsletter(request: GetQuoteWebRequest) {
        return Http.post<any>('quote/getquotenewsletter', request);
    }

    sendSpecificationQuote(request: GetSpecificationQuoteWebRequest) {
        return Http.post<any>('quote/getspecificationquote', request);
    }

    sendPrivateQuote(request: GetPrivateQuoteWebRequest) {
        return Http.post<any>('quote/getprivatequote', request);
    }

    getProfessions() {
        return Http.get<any>('professions/getprofessions');
    }

    getSapVariant(variantId: string) {
        return Http.get<SapVariantViewModel>('productb2b/getvariant?variantId=' + variantId);
    }

    calculateDiscountedPrice(request: CalculateDiscountedPriceWebRequest) {
        return Http.post<CalculateDiscountedPriceViewModel>('productb2b/calculatediscountedprice', request);
    }
}

export default new ProductService();

export enum PurchaseCtaEnum {
    AddToBasket = 0,
    GetQuote = 1,
    FindDealer = 2,
    None = 3,
    GetCutsheet = 4,
    Discontinued = 5,
    AddToBasketB2B = 6,
    ShowPricingB2B = 7,
    GetQuoteB2C = 8,
    SoldOut = 9,
    SignUpForTrade = 10
}
