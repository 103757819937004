import Http from '@/core/http/http.service';
import IUpdatePasswordForm from '@/ui/views/updatepassword/updatepasswordform.interface';
import RedirectViewModel = Vertica.LouisPoulsen.Application.Infrastructure.WebApi.Response.RedirectViewModel;
import ChangePasswordRequest = Vertica.LouisPoulsen.Application.Account.Api.Requests.ChangePasswordRequest;

class UpdatePasswordService {
    public async updatePassword(formData: IUpdatePasswordForm): Promise<RedirectViewModel> {
        const { currentPassword, newPassword, confirmPassword } = formData;

        const request: ChangePasswordRequest = {
            oldPassword: currentPassword,
            newPassword,
            newPasswordAgain: confirmPassword
        };

        return Http.post('account/password', request)
            .then((response: RedirectViewModel) => {
                return response;
            });
    }
}

export default new UpdatePasswordService();
