import {ActionTree, GetterTree, MutationTree} from "vuex";
import BasketService from "../services/basket.service";
import CheckoutService from "../services/checkout.service";
import {namespace} from "vuex-class";
import UpdateLineItemWebRequest = Vertica.LouisPoulsen.Application.Commerce.Requests.UpdateLineItemWebRequest;
import RemoveFromBasketWebRequest = Vertica.LouisPoulsen.Application.Commerce.Requests.RemoveFromBasketWebRequest;
import UpdateOrderReferenceWebRequest = Vertica.LouisPoulsen.Application.Commerce.Requests.UpdateOrderReferenceWebRequest;
import UpdateLineReferenceWebRequest = Vertica.LouisPoulsen.Application.Commerce.Requests.UpdateLineReferenceWebRequest;
import IBasketViewModel = Vertica.LouisPoulsen.Application.Commerce.ViewModels.IBasketViewModel;
import {BasketType} from "@/enums";

enum MutationMethod {
    setBasket = "setQuoteBasket",
    toggleIsBasketLocked = "toggleIsQuoteBasketLocked",
    variantBeingAdded = "quoteVariantBeingAdded"
}

const storeState: IQuoteBasketState = {
    basket: {
        lineItems: []
    } as IBasketViewModel,
    isBasketLocked: false,
    variantBeingAdded: null,
};

const getters: GetterTree<IQuoteBasketState, any> = {
    quoteBasket: state => state.basket as IBasketViewModel,
    quoteLineItems: state => state.basket.lineItems,
    quoteLineItemsCount: state => state.basket.lineItems.reduce((prev, lineitem) => prev + lineitem.quantity, 0),
    isQuoteBasketLocked: state => state.isBasketLocked,
    quoteVariantBeingAdded: state => state.variantBeingAdded,
    quoteLineItemById: state => id => state.basket.lineItems.find(l => l.variantId === id)
};

const actions: ActionTree<IQuoteBasketState, any> = {
    async addToQuoteBasket({ commit }, payload: AddToBasketWebRequest) {
        try {
            commit(MutationMethod.toggleIsBasketLocked);
            commit(MutationMethod.variantBeingAdded, payload.id);
            const basket = await BasketService.add(payload);
            commit(MutationMethod.setBasket, basket);
            commit(MutationMethod.toggleIsBasketLocked);
            commit(MutationMethod.variantBeingAdded, null);
        } catch (error) {
            console.log(error);
            commit(MutationMethod.variantBeingAdded, null);
            commit(MutationMethod.toggleIsBasketLocked);
        }
    },

    async updateLineItem({ commit }, payload: UpdateLineItemWebRequest) {
        try {
            commit(MutationMethod.toggleIsBasketLocked);
            commit(MutationMethod.variantBeingAdded, payload.id);
            const basket = await BasketService.update(payload);
            commit(MutationMethod.setBasket, basket);
            commit(MutationMethod.toggleIsBasketLocked);
            commit(MutationMethod.variantBeingAdded, null);
        } catch (error) {
            console.log(error);
            commit(MutationMethod.variantBeingAdded, null);
            commit(MutationMethod.toggleIsBasketLocked);
        }
    },

    async removeFromBasket({ commit }, payload: RemoveFromBasketWebRequest) {
        try {
            commit(MutationMethod.toggleIsBasketLocked);
            commit(MutationMethod.variantBeingAdded, payload.id);
            const basket = await BasketService.remove(payload);
            commit(MutationMethod.setBasket, basket);
            commit(MutationMethod.toggleIsBasketLocked);
            commit(MutationMethod.variantBeingAdded, null);
        } catch (error) {
            console.log(error);
            commit(MutationMethod.toggleIsBasketLocked);
        }
    },

    async fetchQuoteBasket({ commit }, params) {
        const basket = await BasketService.get(BasketType.Quotation);
        commit(MutationMethod.setBasket, basket);
        return basket;
    },

    async updateLineReference({ commit }, request: UpdateLineReferenceWebRequest) {
        commit(MutationMethod.toggleIsBasketLocked);

        return BasketService.updateLineReference(request).then((response) => {
            commit(MutationMethod.setBasket, response);
            commit(MutationMethod.toggleIsBasketLocked);
        }).catch(() => {
            commit(MutationMethod.toggleIsBasketLocked);
        });
    },

    async updateOrderReference({ commit }, request: UpdateOrderReferenceWebRequest) {
        commit(MutationMethod.toggleIsBasketLocked);

        return CheckoutService.updateOrderReference(request).then((response) => {
            commit(MutationMethod.setBasket, response);
            commit(MutationMethod.toggleIsBasketLocked);
        }).catch(() => {
            commit(MutationMethod.toggleIsBasketLocked);
        });
    },

    async ensureValidForCheckout({ commit }): Promise<ValidationResultViewModel> {
        commit(MutationMethod.toggleIsBasketLocked);

        const promise = BasketService.ensureValidForCheckout({ basketType: BasketType.Quotation as any });
        promise.finally(() => {
            commit(MutationMethod.toggleIsBasketLocked);
        })
        return promise;
    },
};

const mutations: MutationTree<IQuoteBasketState> = {
    [MutationMethod.setBasket](state, basket) {
        state.basket = basket;
    },
    [MutationMethod.toggleIsBasketLocked](state) {
        state.isBasketLocked = !state.isBasketLocked;
    },
    [MutationMethod.variantBeingAdded](state, variantId) {
        state.variantBeingAdded = variantId;
    }
};

export default {
    namespaced: true,
    state: storeState,
    getters,
    actions,
    mutations
};

const moduleName = "quotebasket";
const moduleObject = namespace(moduleName);

export const QuoteBasketGetter = moduleObject.Getter;
export const QuoteBasketMutation = moduleObject.Mutation;
export const QuoteBasketAction = moduleObject.Action;
export const QuoteBasketState = moduleObject.State;
