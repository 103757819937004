import { ref } from 'vue';
import { useEventListener } from './event';

export function useEsc(callback: () => void) {
    const key = ref<string>('');

    useEventListener(window, 'keydown', (event: KeyboardEvent) => {
        if (event.key === 'Escape' || event.key === 'Esc') {
            callback();
        }
    });

    return { key };
}
