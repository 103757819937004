import { router } from '@/router';
import { Dictionary } from 'vue-router/types/router';
import { isEqual } from 'lodash';
import { FilterAndSort } from '@/enums';

export enum UrlQueryKey {
    pageKey = 'page',
    categoryCodeKey = 'categoryCode',
    lastFacetKey = 'lastFacet',
    sortKey = 'sort',
    productCodeKey = 'productCode',
}

export default {
    getRequestModel,
    setFacetValues,
    setCategoryCodeValue,
    getQueryParam,
    getFacets,
    removeFacetValues,
    updateLastFacetKey,
    updateSortValue,
    removeLastFacetKey
};

function getRequestModel(selectedCategoryCode: string, lastFacet: string, productCodes: string[], sortMode?: string, useItemsAsFilter?: boolean) {
    return {
        CategoryCode: selectedCategoryCode,
        PageSize: 10000,
        LastFacet: lastFacet,
        productCode: useItemsAsFilter || sortMode === FilterAndSort.Bestsellers || !sortMode ? productCodes : null,
        sort: sortMode || FilterAndSort.Bestsellers
    };
}

function setFacetValues(facetId: string, facetValues: string[], updateLastFacet: boolean) {
    // Cleans all facets for this group and set the new ones
    const query = { ...router.currentRoute.query };
    delete query[facetId];

    if (facetValues.length > 0) {
        // @ts-ignore
        query[facetId] = facetValues;
    }

    if (updateLastFacet) {
        query[UrlQueryKey.lastFacetKey] = facetId;
    }

    updateQuery(query, [UrlQueryKey.pageKey], true);
}

function getFacets() {
    const result: { [key: string]: string[] } = {};
    Object
        .keys(router.currentRoute.query)
        .filter(key => key !== UrlQueryKey.sortKey)
        .filter(key => key !== UrlQueryKey.lastFacetKey)
        .filter(key => key !== UrlQueryKey.categoryCodeKey)
        .filter(key => key !== UrlQueryKey.productCodeKey)
        .filter(key => !key.toLowerCase().startsWith("utm_"))
        .forEach(key => {
            result[key] = (router.currentRoute.query[key] instanceof Array
                ? router.currentRoute.query[key]
                : [router.currentRoute.query[key]]) as string[];
        });
    return result;
}

function removeFacetValues(facetId: string) {
    const query = { ...router.currentRoute.query };
    delete query[facetId];
    updateQuery(query, [UrlQueryKey.pageKey], true);
}

function updateLastFacetKey(lastFacetId: string) {
    const query = { ...router.currentRoute.query };
    query[UrlQueryKey.lastFacetKey] = lastFacetId;
    updateQuery(query, [UrlQueryKey.pageKey], true);
}

function removeLastFacetKey() {
    const query = { ...router.currentRoute.query };
    delete query[UrlQueryKey.lastFacetKey];
    updateQuery(query, [UrlQueryKey.pageKey], true);
}

function updateSortValue(sortId: string) {
    const query = { ...router.currentRoute.query };
    query[UrlQueryKey.sortKey] = sortId;
    updateQuery(query, [UrlQueryKey.pageKey], true);
}

function setCategoryCodeValue(categoryCode: string) {
    // Cleans all facets for this group and set the new ones
    const query = { ...router.currentRoute.query };
    const key = UrlQueryKey.categoryCodeKey;
    delete query[key];

    if (categoryCode) {
        // @ts-ignore
        query[key] = categoryCode;
    }

    updateQuery(query, [UrlQueryKey.pageKey], true);
}

function getQueryParam(param: UrlQueryKey): string | undefined {
    return router.currentRoute.query[param] as string;
}

function updateQuery(query: Dictionary<string | (string | null)[] | null | undefined>, clearOthers: string[] = [], push = false) {
    if (clearOthers.length) {
        clearOthers.forEach(key => delete query[key]);
    }

    // Only push a change to vue router if a change has actually happenened, fixes "duplicate navigation" router errors
    if (!isEqual(query, router.currentRoute.query)) {
        if (push) {
            // @ts-ignore
            router.push({ query });
        } else {
            // @ts-ignore
            router.replace({ query });
        }
    }
}
