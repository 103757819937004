
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
const supportsVideo = !!document.createElement('video').canPlayType;

@Component({
    name: 'video-player'
})
export default class VideoPlayer extends Vue {
    @Prop()
    public poster: string;

    @Prop()
    public videoUrl: string;

    @Prop({
        required: false,
        type: Boolean,
        default: false
    }) loop: string;

    @Prop({
        required: false,
        type: Boolean,
        default: true
    }) controls: boolean;

    @Prop({ default: false })
    public videoAutoplay: boolean;

    public showVideo() {
        if (!this.videoAutoplay) {
            return;
        }
        const videoElement = this.$refs.video as HTMLVideoElement;
        const promise = videoElement.play();
        if (promise !== undefined) {
            promise.then().catch(error => {
                console.log('Play button not supported');
                // this.showPlayButton = true;
            });
        }
    }
}
