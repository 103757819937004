import { render, staticRenderFns } from "./ChangeOneTimePassword.vue?vue&type=template&id=6b5e9dd9&scoped=true&"
import script from "./ChangeOneTimePassword.vue?vue&type=script&lang=ts&setup=true&"
export * from "./ChangeOneTimePassword.vue?vue&type=script&lang=ts&setup=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b5e9dd9",
  null
  
)

export default component.exports