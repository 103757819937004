import { getCurrentInstance } from 'vue';

export function useValidator() {
    const { proxy } = getCurrentInstance();
    const validator = proxy.$validator;
    return validator;
}

export function injectParentValidator() {
    const proxy = getCurrentInstance().proxy;
    const $validator = proxy.$parent.$validator;
    proxy.$validator = $validator;
    return $validator;
}

export function defineValidation(name: string, getter: () => any) {
    return () => {
        const $validator = useValidator();
        const options = { name, getter };
        $validator.attach(options);
    };
}
