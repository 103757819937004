
import Vue from 'vue';
import Component from 'vue-class-component';
import { StorageService } from '../../core/storage.service';

    @Component({
        name: 'newsletter-popup'
    })
export default class NewsletterPopup extends Vue {
    public showPopup: boolean = false;
    public clickCount: number = 0;
    private storageKey: string = 'newsletterpopup';

    mounted() {
        if (this.shouldEnablePopup()) {
            this.addClickListener();
        }
    }

    public closePopup() {
        this.showPopup = false;
        this.removeClickListener();
        this.clearLocalStorage();
        this.setLocalStorage();
    }

    private shouldEnablePopup(): boolean {
        const storagePopupItem = StorageService.getItem(this.storageKey);
        return (!storagePopupItem || (storagePopupItem && this.daysBetween(new Date(), new Date(storagePopupItem)) > 30));
    }

    private clickCounter() {
        this.clickCount++;
        if (this.clickCount === 7) {
            this.showPopup = true;
            this.removeClickListener();
        }
    }

    private addClickListener() {
        document.addEventListener('click', this.clickCounter);
    }

    private removeClickListener() {
        document.removeEventListener('click', this.clickCounter);
    }

    private setLocalStorage() {
        const today: Date = new Date();
        StorageService.setItem(this.storageKey, today);
    }

    private clearLocalStorage() {
        StorageService.removeItem(this.storageKey);
    }

    private daysBetween(date1: Date, date2: Date) {
        // Get 1 day in milliseconds
        const one_day = 1000 * 60 * 60 * 24;

        // Convert both dates to milliseconds
        const date1_ms = date1.getTime();
        const date2_ms = date2.getTime();

        // Calculate the difference in milliseconds
        const difference_ms = date2_ms - date1_ms;

        // Convert back to days and return
        return Math.round(difference_ms / one_day);
    }
}

