import Http from '@/core/http/http.service';
import IResetPasswordForm from '@/ui/views/resetpassword/resetpasswordform.interface';
import RedirectViewModel = Vertica.LouisPoulsen.Application.Infrastructure.WebApi.Response.RedirectViewModel;
import ChooseNewPasswordRequest = Vertica.LouisPoulsen.Application.Authentication.Api.Requests.ChooseNewPasswordRequest;

class ResetPasswordService {
    public async resetPassword(formData: IResetPasswordForm, token: string): Promise<RedirectViewModel> {
        const { newPassword, confirmPassword } = formData;

        const request: ChooseNewPasswordRequest = {
            newPassword,
            newPasswordAgain: confirmPassword
        };

        return Http.put(`authentication/password/forgot/${token}`, request)
            .then((response: RedirectViewModel) => {
                return response;
            });
    }
}

export default new ResetPasswordService();
