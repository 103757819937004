import PageService from "../page.service";
import ServerContextService from "../server-context.service";

export default (response) => {
    const redirectHeader = response.headers["clientredirect"];
    const shouldRedirect = redirectHeader === "True";
    if (shouldRedirect && response.data) {
        const redirectUrl = response.data.clientRedirectUrl;
        if (redirectUrl) {
            response.data.hardRedirect
                ? PageService.hardRedirect(redirectUrl)
                : PageService.softRedirect(redirectUrl, response.data.preventBrowserHistoryStep);
        } else if (response.data.reloadCurrentPage) {
            PageService.hardReload();
        }
    }
    return response;
};
