
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import Modal from '@/ui/shared/Modal.vue';
import TrackingService from '@/core/tracking.service';
import { AppGetter, CatalogAction } from '@/store';
import {
    calculatorFormViewModel,
    savingsDataModel,
    selectedFixturesModel,
    fixtureDetailsDataModel
} from '@/ui/shared/blocks/LedCalculator/LedCalculatorTypes';
import VueProgrammaticInvisibleGoogleRecaptcha from 'vue-programmatic-invisible-google-recaptcha';
import { ledCalculatorController } from '@/services';
import LedCalculatorFormWebRequest = Vertica.LouisPoulsen.Application.LedCalculator.Requests.LedCalculatorFormWebRequest;
import NotificationService from '@/core/notification.service';
import DictionaryService from '@/core/dictionary.service';

@Component({
    name: 'block-led-calculator-contact-form',
    components: {
        VueProgrammaticInvisibleGoogleRecaptcha
    }
})
export default class BlockLedCalculatorContactForm extends Vue {
    @Prop({
        type: [Object, null],
        default: null,
        required: true
    })
    public model: calculatorFormViewModel;

    @Prop({
        type: [Object, null],
        default: null,
        required: false
    })
    public selectedFixtures: selectedFixturesModel | null;

    @Prop({
        required: true,
        type: Boolean
    })
    public showModal: boolean;

    @Prop({
        type: [Object, null],
        default: null,
        required: false
    })
    public upgradeFixtureInfo: fixtureDetailsDataModel | null;

    @AppGetter
    public recaptchaKey: string;

    @CatalogAction
    public getQuoteCountries: () => Promise<any>;

    @CatalogAction
    public getCountryStates: (countryCode: string) => Promise<any>;

    @CatalogAction
    public getProfessions: () => Promise<any>;

    public form: any = {
        name: '',
        profession: {
            name: '',
            id: ''
        },
        company: '',
        phone: '',
        email: '',
        postalCode: '',
        city: '',
        country: '',
        state: ''
    };

    public isSending = false;
    public countries = [];
    public states = [];
    public professions = [];
    public _uid: any; // Vue provided uniq ID
    public componentId = 'ledCalculatorFormRecaptcha';

    @Watch('showModal')
    requestDropdownsData() {
        if (this.countries.length === 0) {
            this.getQuoteCountries().then(data => {
                this.countries = data;
            });
        }
        if (this.professions.length === 0) {
            this.getProfessions().then(data => {
                this.professions = data;
            });
        }
    }

    @Watch('form.country')
    getStates() {
        if (this.form.country.hasState) {
            this.getCountryStates(this.form.country.countryCode).then(data => {
                this.states = data;
            });
        } else {
            this.states = [];
        }
    }

    public submit() {
        TrackingService.event('LedCalculator', 'Submit');
        if (!this.isSending) {
            this.$validator
                .validateAll()
                .then(isValid => {
                    if (isValid) {
                        (this.$refs[this.componentId + this._uid] as any).execute();
                    } else {
                        TrackingService.event('LedCalculator', 'Error');
                        this.scrollInvalidElementIntoView();
                    }
                })
                .catch(x => {
                    this.isSending = false;
                    console.log('catch' + x);
                });
        }
    }

    public submitForm(recaptchaToken) {
        this.isSending = true;
        const request: LedCalculatorFormWebRequest = this.createRequest(recaptchaToken);

        ledCalculatorController.sendForm(request).then(() => {
            this.isSending = false;
            this.showModal = false;
            this.$emit('on-submit');
            TrackingService.event('LedCalculator', 'Success');
            NotificationService.success(
                DictionaryService.get('Pages.ProductDetail.GetQuote.SuccessNotificationTitle'),
                DictionaryService.get('Pages.ProductDetail.GetQuote.SuccessNotificationMessage')
            );
        }).catch(() => {
            this.isSending = false;
        });
    }

    private createRequest(recaptchaToken: string): LedCalculatorFormWebRequest {
        return {
            city: this.form.city,
            company: this.form.company,
            countryCode: this.form.country.countryCode,
            email: this.form.email,
            name: this.form.name,
            phone: this.form.phone,
            postalCode: this.form.postalCode,
            profession: this.form.profession.name,
            recaptchaToken,
            state: this.form.state,

            existingFixtureId: this.selectedFixtures.existingFixture.id,
            ledUpgradeKitId: this.selectedFixtures.ledFixture.id
        };
    }

    private scrollInvalidElementIntoView() {
        const firstInvalidElement = document.querySelectorAll('.is-danger')[0];
        if (firstInvalidElement) {
            firstInvalidElement.scrollIntoView({ behavior: 'smooth' });
        }
    }
}
