import Http from './http/http.service';
import PageService from './page.service';

class LoginService {
    public async login(loginForm: ILoginForm, redirectUrl: string): Promise<LoginViewModel> {
        const { username, password } = loginForm;

        const userCredentials: LoginWebRequest = {
            userName: username,
            password,
            redirectUrl
        };

        return Http.post('authentication/login', userCredentials)
            .then((response: LoginViewModel) => {
                return response;
            });
    }

    public loginRedirect(redirectUrl: string): void {
        PageService.hardRedirect(redirectUrl);
    }
}

export default new LoginService();
