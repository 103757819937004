
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import TrackingService from '@/core/tracking.service';
import {
    AppGetter,
    downloadCenterAction,
    downloadCenterGetter
} from '@/store';

@Component
export default class DownloadCenterFile extends Vue {
    @downloadCenterGetter
    public isFileSelected: (typeId: string, file: string) => boolean;

    @downloadCenterAction
    public selectFile: (payload: {typeId: string, file: string}) => void;

    @AppGetter
    public textColor: (opacity: number) => string;

    @Prop({
        required: true,
        type: Object
    }) file: FileReferenceViewObject;

    @Prop({
        type: String,
        required: true
    }) typeId: string;

    get selected(): boolean {
        return this.isFileSelected(this.typeId, this.file.id);
    }

    set selected(value) {
        this.selectFile({ typeId: this.typeId, file: this.file.id });
    }

    downloadLinkClicked() {
        TrackingService.event('Ecommerce', 'Download center', 'Download: ' + this.typeId);
    }
}
