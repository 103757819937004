import { GetterTree, MutationTree, ActionTree } from 'vuex';
import { namespace } from 'vuex-class';
import Vue from 'vue';

interface selectedFilesViewModel {
    [key: string]: string[]
}

enum MutationMethod {
    initializeFileTypeSelection = 'initializeFileTypeSelection',
    addFileToSelection = 'addFileToSelection',
    removeFileFromSelection = 'removeFileFromSelection',
    setSelectionForType = 'setSelectionForType',
    setSelectedProductIds = 'setSelectedProductIds',
    setNumberOfResults = 'setNumberOfResults'
}
interface downloadCenterState {
    selectedFiles: selectedFilesViewModel;
    selectedProductIds: string[];
    resultCount: { [key: string]: number };
}
const state: downloadCenterState = {
    selectedFiles: {},
    selectedProductIds: [],
    resultCount: {}
};

const getters: GetterTree<downloadCenterState, any> = {
    selectedFiles: state => state.selectedFiles,
    amountOfResults: state => Object.values(state.resultCount).reduce((x, y) => (x + y), 0),
    getSelectionForType: state => (typeId: string): string[] => state.selectedFiles[typeId],
    isFileSelected: (state, getters) => (typeId: string, file: string): boolean => {
        const selectionForType = getters.getSelectionForType(typeId);
        if (selectionForType.length === 0) {
            return false;
        } else {
            return selectionForType.indexOf(file) >= 0;
        }
    },
    selectedProductIds: state => state.selectedProductIds
};

const actions: ActionTree<downloadCenterState, any> = {
    initializeFileTypes({ commit }, typeIds: string[]) {
        typeIds.forEach(typeId => {
            commit(MutationMethod.initializeFileTypeSelection, typeId);
        });
    },
    selectFile({ commit, state, getters }, payload: { typeId: string, file: string }) {
        const indexInSelection = getters.getSelectionForType(payload.typeId).indexOf(payload.file);
        if (indexInSelection >= 0) {
            commit(MutationMethod.removeFileFromSelection, { typeId: payload.typeId, fileIndex: indexInSelection });
        } else {
            commit(MutationMethod.addFileToSelection, { typeId: payload.typeId, file: payload.file });
        }
    },
    setSelection({ commit, state }, payload: { typeId: string, files: string[] }) {
        commit(MutationMethod.setSelectionForType, { typeId: payload.typeId, files: payload.files });
    },
    clearSelection({ commit }, typeId: string) {
        commit(MutationMethod.setSelectionForType, { typeId, files: [] });
    },
    setSelectedProductIds({ commit }, productIds: string[]) {
        commit(MutationMethod.setSelectedProductIds, productIds);
    },
    setNumberOfResults({ commit }, payload: { typeId: string, amount: string[] }) {
        commit(MutationMethod.setNumberOfResults, { typeId: payload.typeId, amount: payload.amount });
    }
};

const mutations: MutationTree<downloadCenterState> = {
    [MutationMethod.initializeFileTypeSelection](state, typeId: string) {
        Vue.set(state.selectedFiles, typeId, []);
    },
    [MutationMethod.addFileToSelection](state, payload: {typeId: string, file: string}) {
        state.selectedFiles[payload.typeId].push(payload.file);
    },
    [MutationMethod.removeFileFromSelection](state, payload: {typeId: string, fileIndex: number}) {
        state.selectedFiles[payload.typeId].splice(payload.fileIndex, 1);
    },
    [MutationMethod.setSelectionForType](state, payload: {typeId: string, files: string[]}) {
        state.selectedFiles[payload.typeId] = payload.files.concat();
    },
    [MutationMethod.setSelectedProductIds](state, productIds: string[]) {
        state.selectedProductIds = productIds;
    },
    [MutationMethod.setNumberOfResults](state, payload: {typeId: string, amount: number}) {
        Vue.set(state.resultCount, payload.typeId, payload.amount);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};

const moduleName = 'downloadCenter';
const moduleObject = namespace(moduleName);

export const downloadCenterGetter = moduleObject.Getter;
export const downloadCenterMutation = moduleObject.Mutation;
export const downloadCenterAction = moduleObject.Action;
export const downloadCenterState = moduleObject.State;
