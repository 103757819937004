
const propertyNameSizeText = 'sizeText';
const propertyNameColorCode = 'colorCode';
const propertyNameArmText = 'armText';
const propertyNameMountingText = 'mountingText';
const propertyNameCableType = 'cableType';
const propertyNameSwitchPlug = 'switchPlug';
const propertyNameLightsourceText = 'lightsourceText';
const propertyNameShieldDiffusor = 'shieldDiffusor';
const propertyNameAdditionalFeatures = 'additionalFeatures';
const propertyNameBody = 'body';

export enum ProductProperty {
    propertyNameSizeText = 'sizeText',
    propertyNameColorCode = 'colorCode',
    propertyNameArmText = 'armText',
    propertyNameMountingText = 'mountingText',
    propertyNameCableType = 'cableType',
    propertyNameSwitchPlug = 'switchPlug',
    propertyNameLightsourceText = 'lightsourceText',
    propertyNameShieldDiffusor = 'shieldDiffusor',
    propertyNameAdditionalFeatures = 'additionalFeatures',
    propertyNameAdditionalFeaturesDescription = 'additionalFeaturesDescription',
    propertyNameBody = 'body',
}

export interface ProductPropertyOption
{
    value: string;
    description: string;
}

export interface ProductPropertyData
{
    property: ProductProperty;
    options: ProductPropertyOption[];
}

export type PrivatePrioritizedVariantFilterObject = {
    [propertyNameSizeText]: { selectedValue: string },
    [propertyNameColorCode]: { selectedValue: string },
    [propertyNameSwitchPlug]: { selectedValue: string },
    [propertyNameLightsourceText]: { selectedValue: string },
    [propertyNameArmText]: { selectedValue: string },
    [propertyNameMountingText]: { selectedValue: string },
    [propertyNameCableType]: { selectedValue: string },
    [propertyNameShieldDiffusor]: { selectedValue: string },
    [propertyNameAdditionalFeatures]: { selectedValue: string }
};

// important - the sequence matters as the filters are applied by priority
export function initializePrivateFilter() : PrivatePrioritizedVariantFilterObject {
    return {
        [propertyNameColorCode]: { selectedValue: '' },
        [propertyNameSizeText]: { selectedValue: '' },
        [propertyNameSwitchPlug]: { selectedValue: '' },
        [propertyNameLightsourceText]: { selectedValue: '' },
        [propertyNameArmText]: { selectedValue: '' },
        [propertyNameMountingText]: { selectedValue: '' },
        [propertyNameCableType]: { selectedValue: '' },
        [propertyNameShieldDiffusor]: { selectedValue: '' },
        [propertyNameAdditionalFeatures]: { selectedValue: '' }
    };
}

export type ProfessionalPrioritizedVariantFilterObject = {
    [propertyNameSizeText]: { selectedValue: string },
    [propertyNameColorCode]: { selectedValue: string },
    [propertyNameArmText]: { selectedValue: string },
    [propertyNameMountingText]: { selectedValue: string },
    [propertyNameAdditionalFeatures]: { selectedValue: string }
    [propertyNameShieldDiffusor]: { selectedValue: string },
    [propertyNameBody]: { selectedValue: string }
};

export function initializeProfessionalFilter() : ProfessionalPrioritizedVariantFilterObject {
    return {
        [propertyNameSizeText]: { selectedValue: '' },
        [propertyNameColorCode]: { selectedValue: '' },
        [propertyNameArmText]: { selectedValue: '' },
        [propertyNameMountingText]: { selectedValue: '' },
        [propertyNameAdditionalFeatures]: { selectedValue: '' },
        [propertyNameShieldDiffusor]: { selectedValue: '' },
        [propertyNameBody]: { selectedValue: '' }
    };
}
