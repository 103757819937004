
import { AppGetter } from '@/store';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import DictionaryService from '../../core/dictionary.service';
import _ from 'lodash';
import Vue from 'vue';

class NestedOption {
    value: any;
    parent: any | null;
}

@Component({
    name: 'dropdown',
    $_veeValidate: {
        name() {
            return this.name;
        },
        value() {
            return this.selectedOptionValue === Dropdown.EMPTY_VALUE ? undefined : this.selectedOptionValue;
        }
    }
})
export default class Dropdown extends Vue {
    @Prop() public disabled: boolean;
    @Prop() public name: string;
    @Prop({ type: Array, default: () => [] }) public options: any[];
    @Prop({ type: String }) public displayProperty: string;
    @Prop({ type: String }) public label: string;
    @Prop({ default: '--' }) public value: any;
    @Prop({ default: '--' }) public emptyValue: any;
    @Prop({ type: String }) public error: string;
    @Prop({ type: Boolean, default: false }) public displayCheckboxes: boolean;
    @Prop({ type: String, required: false }) public selectProperty: string;
    @Prop({ type: String, required: false }) public showAllText: string;
    @Prop({ type: Boolean, default: true }) public showLabel: boolean;
    @Prop({ type: Boolean, required: false }) public hasSelectPropertyAsValue: boolean;
    @Prop({ type: String, required: false }) public selectAllOptionLabel: string;
    @Prop({ type: String, required: false }) public nestedItemsProperty: string;
    @Prop({ type: Boolean, default: false }) public enableMultiSelect: boolean;
    @Prop({ type: Boolean, default: false }) public canClearSingleItem: boolean;
    @Prop({ type: Boolean, default: false }) public showSelectAllOption: boolean;
    @Prop({ type: Boolean, default: false }) public showListWithOneItem: boolean;
    @Prop({ type: Boolean, default: false }) public ignoreTheme: boolean;
    public focusedOption: any = '';
    public focusedOptionIndex = -1;
    public focusSelectAll = false;
    public showList = false;
    public multipleSelectList: {value: any, return: boolean}[] = [];
    public searchTerm = '';
    protected delayTimer: any;
    protected arrowNavigationSelector = '.c-select__list-item--focused';

    @AppGetter
    public textColor: (opacity: number) => string;

    @AppGetter
    public borderColor: (opacity: number) => string;

    @AppGetter
    public theme: ColorTheme;

    public _uid: any; // Vue provided uniq ID
    public static readonly EMPTY_VALUE : string = '--';
    propertyForSelect: string;
    nestedOptions: NestedOption[] = [];

    get selectedOptionValue() {
        if (this.enableMultiSelect) {
            // Get last element pushed to array, or default value
            return this.multipleSelectList[this.multipleSelectList.length - 1] || this.value;
        }
        return this.displayProperty && this.value ? this.value[this.displayProperty] : this.value;
    }

    get hasDisplayTextSlot() {
        return !!this.$slots.displayText;
    }

    get showTextForEmptyValue() {
        return (!this.enableMultiSelect && this.showAllText) || !this.isEmpty;
    }

    get selectAllLabel() {
        return this.selectAllOptionLabel || DictionaryService.get('Shared.Dropdowns.SelectAll');
    }

    get canShowList(): boolean {
        return !this.disabled && this.notEmpty;
    }

    get length() : number {
        return this.nestedOptions.length ? this.nestedOptions.length : this.options.length;
    }

    get isEmpty() {
        return !this.selectedOptionValue || this.selectedOptionValue === Dropdown.EMPTY_VALUE;
    }

    // Getters
    get allSelected(): boolean {
        return this.multipleSelectList.length === this.options.length;
    }

    get notEmpty(): boolean {
        return (this.showListWithOneItem && this.options.length === 1) || this.options.length > 1;
    }

    get actualValues() {
        return this.multipleSelectList.filter(x => x.return).map(x => x.value);
    }

    get hasCustomBackgroundColor() {
        return !this.ignoreTheme && this.theme.pageBackgroundColor;
    }

    get canClear() {
        return this.enableMultiSelect ? this.actualValues.length > 0 : this.canClearSingleItem && this.value !== this.emptyValue;
    }

    // Watchers
    @Watch('showList')
    resetOnClose() {
        if (!this.canShowList) return;
        if (!this.showList) {
            this.resetFocusedOptions();
            this.removeEventListeners();
        } else {
            window.addEventListener('keydown', this.preventScrollOnArrowKeys, false);
            this.scrollToTargetOption('.c-select__list-item--selected');
        }
    }

    public isInFocus(option: any) {
        return option === this.focusedOption;
    }

    public unselectAllOptions() {
        if (this.enableMultiSelect) {
            this.multipleSelectList = [];
            this.emitSelectUpdate(this.actualValues);
        } else {
            this.emitSelectUpdate(this.emptyValue);
        }
    }

    public closePopup(): void {
        if (!this.showList) {
            return;
        }
        this.showList = false;
    }

    public toggleAllOptions() {
        if (this.enableMultiSelect && !this.nestedItemsProperty) {
            if (!this.allSelected) {
                this.multipleSelectList = [];
                this.options.forEach((option) => {
                    this.multipleSelectList.push({ value: option, return: true });
                });
            } else {
                this.multipleSelectList = [];
            }
            this.emitSelectUpdate(this.actualValues);
        }
    }

    public arrowNavigateOptions(direction) {
        this.focusedOptionIndex += direction; // Update current index with new value
        this.focusSelectAll = false; // Reset focus set on select all option, back to false

        // If multi-select is enabled, calculate if user is trying to navigate to "select all" from key up/down
        if (this.enableMultiSelect && this.showSelectAllOption && (this.focusedOptionIndex === -1 || this.focusedOptionIndex === this.options.length)) {
            this.focusSelectAll = true; // Set focus on select all option in template
            this.focusedOption = ''; // Reset previous value, so only selectAll gets focused css class
            this.scrollToTargetOption(); // Scroll to option inside dropdown
            return; // Stop next calculation of focused index
        }

        // Calculate if focusedOptionIndex is bigger or lower than number of options
        this.ensureFocusedOptionIndex();

        // Set focus based on index
        this.setTargetFocusByIndex();
        this.scrollToTargetOption();
    }

    protected setTargetFocus() {
        for (let i = 0; i < this.options.length; i++) {
            let value = this.options[i];
            if (this.displayProperty) {
                value = this.options[i][this.displayProperty];
            }
            if (value.toLowerCase().indexOf(this.searchTerm.toLowerCase()) === 0) {
                this.focusedOptionIndex = i;
                this.setTargetFocusByIndex();
                this.scrollToTargetOption();
                break;
            }
            this.resetFocusedOptions();
        }
    }

    protected scrollToTargetOption(elementSelector: string = this.arrowNavigationSelector) {
        // Wait for DOM to be ready toggling classes
        this.$nextTick(() => {
            const element: HTMLElement = document.querySelector(elementSelector);
            const container: HTMLElement = document.querySelector('.c-select.-active .c-select__list');

            if (element) {
                const offset = element.offsetTop;
                if (offset > container.getBoundingClientRect().height - element.getBoundingClientRect().height) {
                    container.scrollTop = offset - container.getBoundingClientRect().height + element.getBoundingClientRect().height;
                } else {
                    container.scrollTop = 0;
                }
            }
        });
    }

    // Private methods
    protected ensureFocusedOptionIndex() {
        if (this.focusedOptionIndex < 0) {
            this.focusedOptionIndex = this.options.length - 1;
        }
        if (this.focusedOptionIndex > this.options.length - 1) {
            this.focusedOptionIndex = 0;
        }
    }

    protected setTargetFocusByIndex() {
        this.focusedOption = this.options[this.focusedOptionIndex];
    }

    protected resetFocusedOptions() {
        this.focusedOption = '';
        if (this.enableMultiSelect) {
            this.focusedOptionIndex = -2;
        } else {
            this.focusedOptionIndex = -1;
            if (this.value === Dropdown.EMPTY_VALUE) {
                this.focusedOptionIndex = 0;
            }
        }
    }

    protected resetSearchTerm() {
        this.searchTerm = '';
    }

    protected tryClearDelayTimer() {
        if (this.delayTimer) {
            clearTimeout(this.delayTimer);
        }
    }

    protected emitSelectUpdate(options) {
        // doc https://alligator.io/vuejs/add-v-model-support/#introduction
        // custom v-model = value comes in @prop value and is updated by $emit("input", "the new value!")
        this.$emit('input', options);
        this.$emit('onSelect', options);
    }

    protected preventScrollOnArrowKeys(e: any) {
        if (['ArrowUp', 'ArrowDown'].indexOf(e.key) > -1) {
            e.preventDefault();
        }
    }

    protected removeEventListeners() {
        window.removeEventListener('keydown', this.preventScrollOnArrowKeys, false);
    }

    // Hooks
    mounted() {
        this.propertyForSelect = this.selectProperty || this.displayProperty;
        if (this.nestedItemsProperty) {
            for (const option of this.options) {
                const children = (option[this.nestedItemsProperty] as any[]).map(c => ({ value: c, parent: option }) as NestedOption);
                this.nestedOptions = this.nestedOptions.concat([{ value: option, parent: null } as NestedOption, ...children]);
            }
        }

        this.resetFocusedOptions();
        // If dropdown is multi-select and values come pre-selected, load into multipleSelect
        if (this.enableMultiSelect && !this.nestedItemsProperty && this.value !== Dropdown.EMPTY_VALUE && this.value.length > 0) {
            this.multipleSelectList = this.value.map(x => ({ value: x, return: true }));
        }
    }

    focus() {
        this.showList = !this.enableMultiSelect || this.multipleSelectList.length === 0;
    }

    triggerShowList() {
        this.showList = !this.disabled && !this.showList;
    }

    destroyed() {
        this.removeEventListeners();
    }

    public handleKeyPress(event: KeyboardEvent) {
        if (this.showList) {
            // Key Enter
            if (event.key === 'Enter') {
                if (this.enableMultiSelect && this.focusSelectAll) {
                    this.toggleAllOptions();
                } else {
                    this.onSelect(this.options[this.focusedOptionIndex]);
                }
                return;
            }
            // Key up
            if (event.key === 'ArrowUp') {
                this.arrowNavigateOptions(-1);
                return;
            }
            // Key down
            if (event.key === 'ArrowDown') {
                this.arrowNavigateOptions(1);
                return;
            }
            this.searchTerm += event.key;
            this.setTargetFocus();
            this.tryClearDelayTimer();
            this.delayTimer = _.delay(this.resetSearchTerm, 300);
        }
    }

    public onSelect(option: any) {
        if (!this.enableMultiSelect) {
            this.showList = false;
        }

        let emitObject = option;
        if (this.enableMultiSelect) {
            this.toggleSelectedItem(option);
            emitObject = this.actualValues;
        } else {
            if ((option[this.propertyForSelect] || option) === this.selectedOptionValue) {
                return;
            }
        }
        this.emitSelectUpdate(emitObject);
    }

    public onSelectNestedOption(option: NestedOption) {
        if (!this.enableMultiSelect) {
            this.showList = false;
        }

        let emitObject : any = option.value;
        if (this.enableMultiSelect) {
            this.toggleNestedOptions(option);
            emitObject = this.actualValues;
        } else {
            if ((option[this.propertyForSelect] || option) === this.selectedOptionValue) {
                return;
            }
        }
        this.emitSelectUpdate(emitObject);
    }

    toggleNestedOptions(option: NestedOption) : void {
        if (option.parent === null) {
            this.toggleSelectedItem(option.value, false);
            const childrenToToggle = (option.value[this.nestedItemsProperty] as any[])
                .filter(x => this.isSelected(option.value) ? !this.isSelected(x) : this.isSelected(x));
            for (const child of childrenToToggle) {
                this.toggleSelectedItem(child, true);
            }
        } else {
            this.toggleSelectedItem(option.value, true);
            // If all children of a parent are now toggled and the parent is not,
            // or the parent is toggled and at least one child is not,
            // toggle the parent
            if ((!this.isSelected(option.parent) && option.parent[this.nestedItemsProperty].every((o: any) => this.isSelected(o))) ||
                (this.isSelected(option.parent) && option.parent[this.nestedItemsProperty].some((o: any) => !this.isSelected(o)))) {
                this.toggleSelectedItem(option.parent, false);
            }
        }
    }

    toggleSelectedItem(option: any, emit = true) {
        const index = this.propertyForSelect
            ? this.multipleSelectList.findIndex(x => (this.hasSelectPropertyAsValue ? x.value : x.value[this.propertyForSelect]) === option[this.propertyForSelect])
            : this.multipleSelectList.findIndex(x => x.value === option);
        if (index === -1) {
            this.multipleSelectList.push({ value: this.hasSelectPropertyAsValue ? option[this.propertyForSelect] : option, return: emit });
        } else {
            this.multipleSelectList.splice(index, 1);
        }
    }

    // Public methods
    public isSelected(option: any) {
        if (this.enableMultiSelect) {
            return this.propertyForSelect
                ? this.multipleSelectList.some(x => (this.hasSelectPropertyAsValue ? x.value : x.value[this.propertyForSelect]) === option[this.propertyForSelect])
                : this.multipleSelectList.some(x => x.value === option);
        }
        return option[this.propertyForSelect] === this.selectedOptionValue || option === this.selectedOptionValue;
    }
}
