var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (!_setup.isCheckoutView)?_c('div',{staticClass:"u-absolute u-z-100"},[_c('div',{ref:"topBarNavigationWrapper",staticClass:"c-top-bar-navigation-wrapper u-fixed u-top-0 u-right-0 u-left-0 u-z-49 u-flex u-flex-col",class:[
             { '--scroll-up': _setup.scrollSign < 0 && _setup.lastScrollTop > 0 },
             { '--scroll-down': _setup.scrollSign > 0 },
             { '-usp': _setup.usp && !_setup.showAuthTopBar },
             { '-auth-top-bar': _setup.showAuthTopBar },
             { '-search': _setup.isSearchOpen },
             { '-open': _setup.menuIsWhite },
             { 'u-h-full': _setup.popoutOpen }
         ]},[(_setup.showAuthTopBar)?_c(_setup.PortalTopBar,{attrs:{"white-background":!_setup.menuTheme.hasTopImage && !_setup.menuIsWhite}}):(_setup.usp)?_c(_setup.UspItem,{staticClass:"o-content-auto-center u-h-30p u-pt-10p u-cursor-pointer",class:[!_setup.whiteText ? 'u-text-black' : 'u-text-white',
                          { '-open': _setup.menuIsWhite }],attrs:{"white-text":_setup.whiteText}}):_vm._e(),_c('div',{staticClass:"c-top-bar-navigation u-pt-15p",class:[
                 !_setup.whiteText ? 'u-text-black' : 'u-text-white',
                 { '-open': _setup.menuIsWhite },
                 { 'u-flex-1': _setup.isSearchOpen }
             ]},[_c('div',{staticClass:"o-content-auto-center u-w-full u-flex u-h-45p"},[_c('Logo',{staticClass:"u-cursor-pointer u-max-w-130p"}),_c('div',{staticClass:"u-flex u-ml-auto u-text-center"},[_c('div',{staticClass:"u-flex u-justify-center u-self-start u-px-3 u-cursor-pointer",on:{"click":_setup.searchIconClicked}},[_c('i',[_c('IconSearch')],1)]),(_setup.isBasketVisible)?_c('div',{staticClass:"u-relative u-flex u-justify-center u-self-start u-px-3 u-cursor-pointer",on:{"click":_setup.toggleBasket}},[(_setup.addToBasketSuccess)?_c('span',{staticClass:"basket-indicator"}):_vm._e(),_c('i',{staticClass:"u-relative u-z-50"},[_c('IconBasket')],1),(_setup.lineItemsCount > 0)?_c('span',{staticClass:"c-mini-basket-splash u-flex u-justify-center u-items-center u-absolute u-rounded-full u-text-9p u-z-50",class:_setup.splashColors},[_vm._v("\n                            "+_vm._s(_setup.lineItemsCount)+"\n                        ")]):_vm._e()]):_vm._e(),_c('div',{staticClass:"u-flex u-justify-center u-pl-3 u-cursor-pointer",class:[
                             { '-is-closed': _setup.isMiniBasketOpen }],on:{"click":_setup.navigationIconClicked}},[_c('IconPhonemenu')],1)])],1),(_setup.canOpenSearch)?_c(_setup.NavigationSearch,{on:{"togglePopout":_setup.togglePopout}}):_vm._e()],1)],1),_c('Flyover',{attrs:{"open-state":_setup.isMiniBasketOpen,"content-classes":"mini-basket-flyover"}},[(_setup.isB2BAuthenticated)?_c(_setup.MiniBasketB2b,{on:{"close":_setup.closeNavigationOverlays}}):_c(_setup.MiniBasketB2c,{on:{"close":_setup.closeNavigationOverlays}})],1),_c(_setup.OffcanvasNavigation,{on:{"closeMenu":_setup.toggleNavigation}})],1):(_setup.isCheckoutViewWithTopBar)?_c('div',{staticClass:"u-absolute u-z-100"},[_c('div',{ref:"topBarNavigationWrapper",staticClass:"c-top-bar-navigation-wrapper -checkout-view u-fixed u-top-0 u-right-0 u-left-0 u-z-49 u-flex u-flex-col",class:[
             { '--scroll-up': _setup.scrollSign < 0 && _setup.lastScrollTop > 0 },
             { '--scroll-down': _setup.scrollSign > 0 }
         ]},[_c(_setup.PortalTopBar,{attrs:{"white-background":true}})],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }