class SearchStringService {

    public createSearchString(data: any, props: string[]): string {
        return props.map((prop) => this.generateSearchString(data, prop).toLowerCase()).join("|");
    }

    private generateSearchString(object: object, prop: string) {
        let nestedFields = prop.split(".");
        let currentField = nestedFields.shift();

        // Are there any more nested field
        // if not we extract the value
        if (nestedFields.length == 0) {
            if (Array.isArray(object)) {
                return object.map((obj) => obj[currentField]).join("|");
            } else {
                return object[currentField];
            }
        }

        // If there are more nested fields
        // we call this function recursively

        // If we meet an array on the way we loop over the elements
        // and call this function recursively on it.
        if (Array.isArray(object)) {
            return object.map((obj) => this.generateSearchString(obj[currentField], nestedFields.join("."))).join("|");
        } else {
            return this.generateSearchString(object[currentField], nestedFields.join("."));
        }
    }
}

export const searchStringService = new SearchStringService();
