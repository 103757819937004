
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { AppGetter } from '../../../store';
import ImageInfoPoint from '../ImageInfoPoint.vue';
import ImageInfoPointPopup from '../ImageInfoPointPopup.vue';
import userAgent from '@/services/userAgent.service';

    @Component({
        name: 'block-image-with-info-points',
        components: {
            ImageInfoPoint,
            ImageInfoPointPopup
        }
    })
export default class BlockImageWithInfoPoints extends Vue {
        @AppGetter public isInEditMode: boolean;

        @Prop({
            default: false
        })
        public addTopMargin: boolean;

        public openInfoPointIndex: number = -1;

        public runAnimations = userAgent.isPrerender;
        initAnimations(): void {
            this.runAnimations = true;
        }

        public toggleOpenInfoPoint(index: number): void {
            if (this.openInfoPointIndex === index) {
                this.openInfoPointIndex = -1;
            } else {
                this.openInfoPointIndex = index;
            }
        }

        @Prop({ required: true })
        public backgroundImageUrl: string;

        @Prop({ default: 'bottom' })
        public infoBoxPosition: string;

        @Prop({ required: true })
        public infoPoints: IImageInfoPoint[];

        get backgroundImageGenerated(): string {
            return this.backgroundImageUrl + '?maxwidth=1900&quality=90';
        }
}
