import { computed, ref } from 'vue';
import { Action } from './registeredproductaction.interface';
import { useTranslations } from '@/core/translate.mixin';

export function useRegisteredProductActions() {
    const { t } = useTranslations();
    const actionProductName = ref<string>('');
    const actionType = ref<Action>(null);

    const actionTitle = computed<string>(() => {
        switch (actionType.value) {
        case Action.Sold:
            return t('Pages.ProductRegistration.Dashboard.ActionModal.SoldTitle');
        case Action.Stolen:
            return t('Pages.ProductRegistration.Dashboard.ActionModal.StolenTitle');
        case Action.NotStolen:
            return t('Pages.ProductRegistration.Dashboard.ActionModal.NotStolenTitle');
        case Action.Deleted:
            return t('Pages.ProductRegistration.Dashboard.ActionModal.DeletedTitle');
        case Action.Updated:
            return t('Pages.ProductRegistration.Dashboard.ActionModal.UpdatedTitle');
        case Action.Created:
            return t('Pages.ProductRegistration.Dashboard.ActionModal.CreatedTitle');
        default:
            return '';
        }
    });

    const actionText = computed<string>(() => {
        switch (actionType.value) {
        case Action.Sold:
            return t('Pages.ProductRegistration.Dashboard.ActionModal.SoldText');
        case Action.Stolen:
            return t('Pages.ProductRegistration.Dashboard.ActionModal.StolenText');
        case Action.NotStolen:
            return t('Pages.ProductRegistration.Dashboard.ActionModal.NotStolenText');
        case Action.Deleted:
            return t('Pages.ProductRegistration.Dashboard.ActionModal.DeletedText');
        case Action.Updated:
            return t('Pages.ProductRegistration.Dashboard.ActionModal.UpdatedText');
        case Action.Created:
            return t('Pages.ProductRegistration.Dashboard.ActionModal.CreatedText');
        default:
            return '';
        }
    });

    return { actionType, actionProductName, actionTitle, actionText };
}
