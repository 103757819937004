import { render, staticRenderFns } from "./BasketGiftLineItemB2C.vue?vue&type=template&id=462f7bf9&"
import script from "./BasketGiftLineItemB2C.vue?vue&type=script&lang=ts&setup=true&"
export * from "./BasketGiftLineItemB2C.vue?vue&type=script&lang=ts&setup=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports