import { AxiosResponse } from 'axios';

export default (response) => {
    const renderTracking = (response: AxiosResponse) => {
        if ((window as unknown as IWindow).google_tag_manager) {
            try {
                console.log('try eval', response.data.analyticsTrackerScript);
                eval(response.data.analyticsTrackerScript);
            } catch (exception) {
                // this.$log.error(exception);
            }
            return true;
        }
        return false;
    };

    if (!response.data.analyticsTrackerScript) {
        console.log('nothing to track', response.data.analyticsTrackerScript);
        return response;
    }
    renderTracking(response);
    return response;
};
