import { render, staticRenderFns } from "./BlockPortrait.vue?vue&type=template&id=5ffd0c6a&"
import script from "./BlockPortrait.vue?vue&type=script&lang=ts&setup=true&"
export * from "./BlockPortrait.vue?vue&type=script&lang=ts&setup=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports