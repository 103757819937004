
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({
    name: 'image-info-point-popup',
})
export default class ImageInfoPointPopup extends Vue {
    @Prop()
    public headline: string;
    @Prop()
    public body: string;
    @Prop()
    public buttonText: string;
    @Prop()
    public buttonLink: string;
    @Prop()
    public products: IImageInfoPointProduct[];
}
