import Http from '@/core/http/http.service';
import SignUpRequest = Vertica.LouisPoulsen.Application.Account.Api.Requests.SignUpRequest;
import UpdateUserRequest = Vertica.LouisPoulsen.Application.Account.Api.Requests.UpdateUserRequest;
import CreateAccountResponse = Vertica.LouisPoulsen.Application.Account.Api.Responses.MyAccount.CreateAccountResponse;
import AccountInformationViewObject = Vertica.LouisPoulsen.Application.Account.Api.Responses.MyAccount.AccountInformationViewObject;
import MultiFactorAuthenticationViewObject = Vertica.LouisPoulsen.Application.Account.Api.Responses.MultiFactorAuthenticationViewObject;
import UpdateUserResponse = Vertica.LouisPoulsen.Application.Account.Api.UpdateUserResponse;
import ChangeMultiFactorAuthenticationRequest = Vertica.LouisPoulsen.Application.Account.Api.Requests.ChangeMultiFactorAuthenticationRequest;
import MfaType = Vertica.LouisPoulsen.Login.Database.Models.Enums.MfaType;

class AccountService {
    public async getProfileDetails(): Promise<AccountInformationViewObject> {
        return Http.get('account/profile');
    }

    public async getMfaSettings(): Promise<MultiFactorAuthenticationViewObject> {
        return Http.get('account/multi-factor-authentication');
    }

    public async createAccount(request: SignUpRequest): Promise<CreateAccountResponse> {
        return Http.post('account', request);
    }

    public async updateAccount(request: UpdateUserRequest): Promise<UpdateUserResponse> {
        return Http.put('account', request);
    }

    public async deleteAccount(): Promise<boolean> {
        return Http.remove('account');
    }

    public async setMfaState(mfaType: MfaType): Promise<boolean> {
        const request: ChangeMultiFactorAuthenticationRequest = {
            multiFactorAuthentication: mfaType
        };

        return Http.post('account/multi-factor-authentication', request);
    }
}

export default new AccountService();
