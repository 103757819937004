import { GetterTree, MutationTree, ActionTree } from 'vuex';
import { namespace } from 'vuex-class';
import DomService from '@/core/dom.service';

function disableBodyScroll(): void {
    DomService.addElementClass('html', 'modal-open');
}

function enableBodyScroll(): void {
    DomService.removeElementClass('html', 'modal-open');
}

interface navigationState {
    isMenuOpen: boolean,
    isMegaMenuOpen: boolean,
    isSearchOpen: boolean,
    isMiniBasketOpen: boolean,
    isMiniQuoteBasketOpen: boolean,
    isLanguageSelectorOpen: boolean,
    isNewsletterModalOpen: boolean
};

enum MutationMethod {
    closeAllNavigationOverlays = 'closeAllNavigationOverlays',
    setIsMenuOpen = 'setIsMenuOpen',
    setIsMegaMenuOpen = 'setIsMegaMenuOpen',
    setIsLanguageSelectorOpen = 'setIsLanguageSelectorOpen',
    setIsSearchOpen = 'setIsSearchOpen',
    setIsMiniBasketOpen = 'setIsMiniBasketOpen',
    setIsMiniQuoteBasketOpen = 'setIsMiniQuoteBasketOpen',
    setIsNewsletterModalOpen = 'setIsNewsletterModalOpen'
}

const storeState: navigationState = {
    isMenuOpen: false,
    isSearchOpen: false,
    isMiniBasketOpen: false,
    isMiniQuoteBasketOpen: false,
    isMegaMenuOpen: false,
    isLanguageSelectorOpen: false,
    isNewsletterModalOpen: false
};

const storeGetters: GetterTree<navigationState, any> = {
    isMenuOpen: state => state.isMenuOpen,
    isMegaMenuOpen: state => state.isMegaMenuOpen,
    isSearchOpen: state => state.isSearchOpen,
    isMiniBasketOpen: state => state.isMiniBasketOpen,
    isMiniQuoteBasketOpen: state => state.isMiniQuoteBasketOpen,
    isLanguageSelectorOpen: state => state.isLanguageSelectorOpen,
    isNewsletterModalOpen: state => state.isNewsletterModalOpen
};

const actions: ActionTree<navigationState, any> = {
    toggleNavigation({ dispatch, getters }): void {
        if (getters.isMenuOpen) {
            dispatch('closeNavigationOverlays');
        } else {
            dispatch('openNavigation');
        }
    },
    openNavigation({ commit }): void {
        commit(MutationMethod.closeAllNavigationOverlays);
        commit(MutationMethod.setIsMenuOpen, true);
        disableBodyScroll();
    },
    openMegaMenu({ commit }): void {
        commit(MutationMethod.closeAllNavigationOverlays);
        commit(MutationMethod.setIsMegaMenuOpen, true);
    },
    toggleMegaMenu({ commit }, value): void {
        commit(MutationMethod.closeAllNavigationOverlays);
        commit(MutationMethod.setIsMegaMenuOpen, value);
    },
    toggleSearch({ dispatch, getters }, canOpenSearch = true): void {
        if (canOpenSearch) {
            if (getters.isSearchOpen) {
                dispatch('closeNavigationOverlays');
            } else {
                dispatch('openSearch');
            }
        } else {
            const scrollToTop = () => {
                const c = document.documentElement.scrollTop || document.body.scrollTop;
                if (c > 0) {
                    window.requestAnimationFrame(scrollToTop);
                    window.scrollTo(0, c - c / 8);
                } else {
                    const searchInput: HTMLElement = document.querySelector('.search-field__input');
                    if (searchInput) {
                        searchInput.focus();
                    }
                }
            };
            scrollToTop();
        }
    },
    openSearch({ commit }): void {
        commit(MutationMethod.closeAllNavigationOverlays);
        commit(MutationMethod.setIsSearchOpen, true);
    },
    toggleBasket({ dispatch, getters }): void {
        if (getters.isMiniBasketOpen) {
            dispatch('closeNavigationOverlays');
        } else {
            dispatch('openBasket');
        }
    },
    openBasket({ commit }): void {
        commit(MutationMethod.closeAllNavigationOverlays);
        commit(MutationMethod.setIsMiniBasketOpen, true);
        disableBodyScroll();
    },
    toggleQuoteBasket({ dispatch, getters }): void {
        if (getters.isMiniQuoteBasketOpen) {
            dispatch('closeNavigationOverlays');
        } else {
            dispatch('openQuoteBasket');
        }
    },
    openQuoteBasket({ commit }): void {
        commit(MutationMethod.closeAllNavigationOverlays);
        commit(MutationMethod.setIsMiniQuoteBasketOpen, true);
        disableBodyScroll();
    },
    closeNavigationOverlays({ commit }): void {
        commit(MutationMethod.closeAllNavigationOverlays);
        enableBodyScroll();
    },
    toggleShowLanguageSelect({ commit, dispatch, getters }): void {
        commit(MutationMethod.setIsLanguageSelectorOpen, !getters.isLanguageSelectorOpen);
        dispatch('setScrollSettings');
    },
    toggleNewsletterModal({ commit, dispatch, getters }): void {
        commit(MutationMethod.setIsNewsletterModalOpen, !getters.isNewsletterModalOpen);
        dispatch('setScrollSettings');
    },
    setScrollSettings({ getters }): void {
        if (getters.isLanguageSelectorOpen ||
            getters.isMenuOpen ||
            getters.isSearchOpen ||
            getters.isMiniBasketOpen ||
            getters.isMiniQuoteBasketOpen ||
            getters.isLanguageSelectorOpen ||
            getters.isNewsletterModalOpen) {
            disableBodyScroll();
        } else {
            enableBodyScroll();
        }
    }
};

const mutations: MutationTree<navigationState> = {
    [MutationMethod.closeAllNavigationOverlays](state) {
        state.isMenuOpen = false;
        state.isSearchOpen = false;
        state.isMiniBasketOpen = false;
        state.isMiniQuoteBasketOpen = false;
        state.isMegaMenuOpen = false;
        state.isLanguageSelectorOpen = false;
        state.isNewsletterModalOpen = false;
    },
    [MutationMethod.setIsMenuOpen](state, openState) {
        state.isMenuOpen = openState;
    },
    [MutationMethod.setIsSearchOpen](state, openState) {
        state.isSearchOpen = openState;
    },
    [MutationMethod.setIsMiniBasketOpen](state, openState) {
        state.isMiniBasketOpen = openState;
    },
    [MutationMethod.setIsMiniQuoteBasketOpen](state, openState) {
        state.isMiniQuoteBasketOpen = openState;
    },
    [MutationMethod.setIsMegaMenuOpen](state, openState) {
        state.isMegaMenuOpen = openState;
    },
    [MutationMethod.setIsLanguageSelectorOpen](state, openState) {
        state.isLanguageSelectorOpen = openState;
    },
    [MutationMethod.setIsNewsletterModalOpen](state, openState) {
        state.isNewsletterModalOpen = openState;
    }
};

export default {
    namespaced: true,
    state: storeState,
    getters: storeGetters,
    actions,
    mutations
};

const moduleName = 'navigation';
const moduleObject = namespace(moduleName);

export const NavigationGetter = moduleObject.Getter;
export const NavigationMutation = moduleObject.Mutation;
export const NavigationAction = moduleObject.Action;
export const NavigationState = moduleObject.State;
