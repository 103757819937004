declare let window: IWindow;

export enum SiteMode {
    Professional = 'professional',
    Private = 'private'
}

class ServerContextService {
    get version() {
        return window._app.serverContext.version;
    }

    get statusCode() {
        return window._app.serverContext.statusCode;
    }

    get spaMode() {
        return window._app.serverContext.spaMode || false;
    }

    get initialBreadcrumb() {
        return window._app.serverContext.initialBreadcrumb || [];
    }

    get usp() {
        return window._app.serverContext.usp || null;
    }

    get footer() {
        return window._app.serverContext.footer || null;
    }

    get recaptchaKey() {
        return window._app.serverContext.recaptchaKey || '';
    }

    get menu() {
        return window._app.serverContext.menu || [];
    }

    get myAccountRoot() {
        return window._app.serverContext.myAccountRoot || null;
    }

    get portalRoot() {
        return window._app.serverContext.portalRoot || null;
    }

    get languages() {
        return window._app.serverContext.languages || [];
    }

    get primaryCountryCode() {
        return window._app.serverContext.primaryCountryCode || '';
    }

    get modes() {
        return window._app.serverContext.modes || [];
    }

    get isPrivateMode() {
        const modes = window._app.serverContext.modes || [];
        const selectedMode = modes.find(x => x.isSelected) || {} as any;
        return selectedMode.id === 0;
    }

    get isAuthenticated() {
        return window._app.serverContext.isAuthenticated || false;
    }

    get displayNameB2B() {
        return window._app.serverContext.displayNameB2B || '';
    }

    get isBasketVisible() {
        return window._app.serverContext.isBasketVisible || false;
    }

    get isQuoteBasketVisible() {
        return window._app.serverContext.isQuoteBasketVisible || false;
    }

    get hideSiteModeProf() {
        return window._app.serverContext.hideSiteModeProf || false;
    }

    get isCheckoutView() {
        return window._app.serverContext.isCheckoutView || false;
    }

    get isCheckoutViewWithTopBar() {
        return window._app.serverContext.isCheckoutViewWithTopBar || false;
    }

    get hideNavigationSpacer() {
        return window._app.serverContext.hideNavigationSpacer || false;
    }

    get hideBreadcrumb() {
        return window._app.serverContext.hideBreadcrumb || false;
    }

    get previousUrlBreadcrumb() {
        return window._app.serverContext.previousUrlBreadcrumb || false;
    }

    get pages() {
        return window._app.serverContext.pages;
    }

    get dateFormat() {
        return window._app.serverContext.dateFormat;
    }

    get twoLetterISOLanguageName() {
        return window._app.serverContext.twoLetterISOLanguageName;
    }

    get languageName() {
        return window._app.serverContext.languageName;
    }

    get currencyName() {
        return window._app.serverContext.currencyName;
    }

    get alternateMarketData() {
        return window._app.serverContext.alternateMarketData;
    }

    get pointOfSale() {
        return window._app.serverContext.pointOfSale;
    }

    get initialHideB2BPricesState() {
        return window._app.serverContext.initialHideB2BPricesState;
    }

    get showNewsletterPopup() {
        return window._app.serverContext.showNewsletterPopup;
    }

    get showSignUpDiscountPopup() {
        return window._app.serverContext.showSignUpDiscountPopup;
    }

    get signUpDiscountPopupImageUrl() {
        return window._app.serverContext.signUpDiscountPopupImageUrl;
    }

    get cordCuttingLinkUrl() {
        return window._app.serverContext.cordCuttingLinkUrl;
    }

    get theme() {
        return window._app.serverContext.theme;
    }

    get menuTheme() {
        return window._app.serverContext.menuTheme;
    }

    get showStockB2B() {
        return window._app.serverContext.showStockB2B;
    }

    get salesConsultantB2B() {
        return window._app.serverContext.salesConsultantB2B;
    }
}

export default new ServerContextService();
