class LegacyBrowserService {
    public isBrowserGridSupported(): boolean {
        let gridSupported: boolean;
        try {
            gridSupported = CSS.supports("display", "grid");
        }
        catch (err) {
            gridSupported = false;
        }
        return gridSupported;
    }
}

export default new LegacyBrowserService();
