import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { namespace } from 'vuex-class';
import CheckoutService from '../services/checkout.service';
import NotificationService from '@/core/notification.service';
import DictionaryService from '@/core/dictionary.service';

enum MutationMethod {
    setPickupShops = 'setPickupShops',
    setKlarnaToken = 'setKlarnaToken',
    setKlarnaPaymentIdentifier = 'setKlarnaPaymentIdentifier',
    toggleLoadingPickupShops = 'toggleLoadingPickupShops',
    toggleIsProcessingOrder = 'toggleIsProcessingOrder'
}

const storeState: ICheckoutState = {
    pickupShops: [],
    isLoadingPickupShops: false,
    isProcessingOrder: false,
    klarnaToken: '',
    klarnaPaymentIdentifier: undefined
};

const getters: GetterTree<ICheckoutState, any> = {
    pickupShops: state => state.pickupShops || [],
    isLoadingPickupShops: state => state.isLoadingPickupShops,
    isProcessingOrder: state => state.isProcessingOrder,
    getKlarnaToken: state => state.klarnaToken,
    getKlarnaPaymentIdentifier: state => state.klarnaPaymentIdentifier
};

const actions: ActionTree<ICheckoutState, any> = {
    async fetchPickupShop({ commit }, requeset) {
        const timer = setTimeout(() => commit(MutationMethod.toggleLoadingPickupShops, true), 200);
        try {
            const response = await CheckoutService.getPickupShops(requeset);
            clearTimeout(timer);
            if (response.shops.length === 0) {
                NotificationService.error(
                    DictionaryService.get('Pages.Basket.InvalidAddressHeader'),
                    DictionaryService.get('Pages.Basket.InvalidAddressText'));
            } else {
                commit(MutationMethod.setPickupShops, response.shops);
            }
            commit(MutationMethod.toggleLoadingPickupShops, false);
            return response;
        } catch (error) {
            clearTimeout(timer);
            commit(MutationMethod.toggleLoadingPickupShops, false);
            return error;
        }
    },
    async getCountryTaxTotal({ commit }, countryCode) {
        return await CheckoutService.getCountryTaxTotal(countryCode);
    },
    async submitOrder({ commit }, order) {
        commit(MutationMethod.toggleIsProcessingOrder, true);
        try {
            return await CheckoutService.submitB2COrder(order);
        } catch (error) {
            console.log(error);
            commit(MutationMethod.toggleIsProcessingOrder, false);
        }
    },
    async initializeKlarna({ commit }) {
        try {
            const response = await CheckoutService.initializeKlarna();
            commit(MutationMethod.setKlarnaToken, response.klarnaToken);
            return response;
        } catch (error) {
            console.log(error);
            commit(MutationMethod.setKlarnaToken, '');
        }
    },
    setKlarnaPaymentIdentifier({ commit }, identifier) {
        commit(MutationMethod.setKlarnaPaymentIdentifier, identifier);
    }
};

const mutations: MutationTree<ICheckoutState> = {
    [MutationMethod.setPickupShops](state, shops) {
        state.pickupShops = shops;
    },
    [MutationMethod.toggleLoadingPickupShops](state, flag) {
        state.isLoadingPickupShops = flag;
    },
    [MutationMethod.toggleIsProcessingOrder](state, flag) {
        state.isProcessingOrder = flag;
    },
    [MutationMethod.setKlarnaToken](state, token) {
        state.klarnaToken = token;
    },
    [MutationMethod.setKlarnaPaymentIdentifier](state, identifier) {
        state.klarnaPaymentIdentifier = identifier;
    }
};

export default {
    namespaced: true,
    state: storeState,
    getters,
    actions,
    mutations
};

const moduleName = 'checkoutb2c';
const moduleObject = namespace(moduleName);

export const CheckoutGetter = moduleObject.Getter;
export const CheckoutMutation = moduleObject.Mutation;
export const CheckoutAction = moduleObject.Action;
export const CheckoutState = moduleObject.State;
