import { onMounted, onUnmounted } from 'vue';

export function useEventListener<T extends EventTarget, E extends Event>(
    target: T,
    event: string,
    callback: (event: E) => void
) {
    onMounted(() => target.addEventListener(event, callback as EventListener));
    onUnmounted(() => target.removeEventListener(event, callback as EventListener));
}
