
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import DownloadCenterImage from './DownloadCenterImage.vue';
import DownloadCenterFileTypeAccordion from '@/ui/views/download-center/DownloadCenterFileTypeAccordion.vue';
import {
    AppGetter,
    downloadCenterAction,
    downloadCenterGetter
} from '@/store';
import { multiDownloadUrl } from '@/core/constants';
import TrackingService from '@/core/tracking.service';
import { Dictionary } from 'vuex';
import { router } from '@/router';
import QueryParamService, { QueryParams } from '@/core/query-params.service';
import SelectCtrlMultiple from '@/ui/shared/form/SelectCtrlMultiple.vue';

@Component({
    name: 'download-center',
    components: {
        DownloadCenterFileTypeAccordion,
        DownloadCenterImage,
        SelectCtrlMultiple
    }
})
export default class DownloadCenter extends Vue {
    @AppGetter
    public primaryCountryCode: string;

    @AppGetter
    public borderColor: (opacity: number) => string;

    @AppGetter
    public textColor: (opacity: number) => string;

    @AppGetter
    public theme: ColorTheme;

    @downloadCenterGetter
    public getSelectionForType: (typeId: string) => string[];

    @downloadCenterGetter
    public amountOfResults: number;

    @downloadCenterGetter
    public selectedProductIds: string[];

    @downloadCenterAction
    public initializeFileTypes: (typeIds: string[]) => void;

    @downloadCenterAction
    public setSelectedProductIds: (productIds: string[]) => void;

    @downloadCenterAction
    public clearSelection: (typeId: string) => void;

    @Prop()
    public downloadTypes: DownloadCenterTypeViewObject[];

    @Prop()
    public products: DownloadCenterProductViewObject[];

    selectedTypes: DownloadCenterTypeViewObject[] = [];
    multiDownloadUrl: string = multiDownloadUrl;

    get _selectedProductIds(): string[] {
        return this.selectedProductIds;
    }

    set _selectedProductIds(selection: string[]) {
        this.setSelectedProductIds(selection);
        this.updateRoute();
    }

    get totalNumberOfSelectedFiles(): any {
        return [].concat(...this.selectedTypes.map(selectedType => this.getSelectionForType(selectedType.id))).length;
    }

    getSelectedFilesForType(typeId: string): string[] {
        return this.getSelectionForType(typeId);
    }

    created() {
        this.initializeFileTypes(this.downloadTypes.map(type => type.id));
        this.updateSelectedOptionsFromQueryParams();
    }

    ensureCorrectProductIdCase() {
        const { productid } = this.$router.currentRoute.query;
        if(productid){
            delete this.$router.currentRoute.query["productid"]
            const query = {
                ...this.$router.currentRoute.query,
                ["productId"]: productid
            };
            this.$router.replace({ query })
        }
    }

    updateSelectedOptionsFromQueryParams() {
        this.ensureCorrectProductIdCase();
        const { type, productId } = this.$router.currentRoute.query;
        if (type) {
            const typeOfTypeQueryParam = typeof type;
            if (typeOfTypeQueryParam === 'string') {
                // only 1 productId selected
                this.selectedTypes.push(this.downloadTypes.find(item => item.id === type));
            } else if (typeOfTypeQueryParam === 'object') {
                // multiple productIds have been selected
                (type as unknown as string[]).forEach(id => {
                    this.selectedTypes.push(this.downloadTypes.find(item => item.id === id));
                });
            }
        } else if (this.primaryCountryCode === 'US') {
            this.selectedTypes.push(this.downloadTypes.find(downloadType => downloadType.id === 'cutsheetpdf'));
            this.updateRoute();
        }
        if (productId) {
            const typeOfProductIdQueryParam = typeof productId;
            if (typeOfProductIdQueryParam === 'string') {
                // only 1 productId selected
                this.setSelectedProductIds([productId as string]);
            } else {
                this.setSelectedProductIds(productId as unknown as string[]);
            }
        }
    }

    updateSelectedTypes(selectedTypes: DownloadCenterTypeViewObject[]): void {
        this.selectedTypes = selectedTypes;
        this.updateRoute();
    }

    updateRoute(): void {
        const selectedTypesIds: string[] = this.selectedTypes.map(type => type.id);
        const accordionQueryParams = QueryParamService.getParam(QueryParams.AccordionState);
        const accordionQueryParamsArray: string[] = typeof accordionQueryParams === 'string' ? [accordionQueryParams] : accordionQueryParams as unknown as string[];
        const accordionQueryParamsToBePersisted: string[] = accordionQueryParamsArray ? accordionQueryParamsArray.filter(accordionQueryParam => selectedTypesIds.indexOf(accordionQueryParam) >= 0) : [];
        const query = {
            ...router.currentRoute.query,
            [QueryParams.AccordionState]: accordionQueryParamsToBePersisted,
            ['type' as string]: selectedTypesIds,
            ['productId' as string]: this.selectedProductIds
        } as Dictionary<string>;
        this.$router.replace({ query });
    }

    downloadSelectedButtonClicked() {
        TrackingService.event('Ecommerce', 'Download center', 'Download: ' + this.selectedTypes.map(selectedType => selectedType.id).join(', '));
    }

    onDownloadSubmit(): void {
        setTimeout(() => {
            this.selectedTypes.forEach(type => {
                this.clearSelection(type.id);
            });
        }, 50);
    }
}
