import Http from '@/core/http/http.service';
import ServerContextService from '@/core/server-context.service';
import BasketViewModel = Vertica.LouisPoulsen.Application.Commerce.ViewModels.Consumer.BasketViewModel;
import { store } from '@/store';
import PageService from '@/core/page.service';
import InitializeAdyenViewModel = Vertica.LouisPoulsen.Application.Commerce.ViewModels.InitializeAdyenViewModel;
import AdyenPaymentViewModel = Vertica.LouisPoulsen.Application.Commerce.ViewModels.AdyenPaymentViewModel;
import QueryParamService from '@/core/query-params.service';
import DictionaryService from '@/core/dictionary.service';
import { Ref, computed, onMounted, ref } from 'vue';

export function usePaymentProviderAdyen(apiPrefix: string, dropin: Ref<HTMLElement>) {
    const basket = computed<BasketViewModel>(() => store.getters['basket/basket']);

    let adyenPaymentMethods: any = null;
    let adyenCheckout: any = null;
    const adyenDropin = ref(null);
    let adyenPaymentInformation: string | null = null;

    const showLoading = ref<boolean>(true);

    const showError = ref<boolean>(false);
    const errorMessage = ref<string>('');

    onMounted(() => {
        initAdyenCheckout();

        if (QueryParamService.getParam('failed') === '1') {
            showError.value = true;
            errorMessage.value = DictionaryService.get('Pages.Checkout.Adyen.Failed');
        } else if (QueryParamService.getParam('refused') === '1') {
            showError.value = true;
            errorMessage.value = DictionaryService.get('Pages.Checkout.Adyen.Refused');
        }
    });

    async function initAdyenCheckout() {
        const response = await Http.get(`/${apiPrefix}/initializeadyen`) as InitializeAdyenViewModel;

        await appendStyleAndScript(response.environment);

        adyenPaymentMethods = response.paymentMethods;
        const locale = ServerContextService.languages.find(l => l.isSelected).code;
        const isJapanMarket = locale.toLowerCase() === 'ja-JP'.toLowerCase();

        const configuration = {
            locale,
            environment: response.environment,
            clientKey: response.clientKey,
            paymentMethodsResponse: adyenPaymentMethods,
            amount: {
                value: basket.value.totalRaw,
                currency: basket.value.currency
            },
            showPayButton: false,
            paymentMethodsConfiguration: {
                applepay: {
                    amount: {
                        value: !isJapanMarket ? basket.value.totalRaw * 100 : this.basket.totalRaw,
                        currency: basket.value.currency
                    },
                    onSubmit: (state) => {
                        processPayment(state.data);
                    }
                }
            }
        };

        adyenCheckout = new (window as any).AdyenCheckout(configuration);

        adyenDropin.value = adyenCheckout.create('dropin', {
            onSubmit: (state, dropin) => {
                processPayment(state.data);
            },
            onAdditionalDetails: (state, dropin) => {
                processAdditionalDetails(state.data);
            },
            onChange: (state, component) => {
                if (state.isValid) {
                    adyenPaymentInformation = JSON.stringify(state.data.paymentMethod);
                } else {
                    adyenPaymentInformation = null;
                }
            }
        }).mount(dropin.value);

        showLoading.value = false;
    }

    async function appendStyleAndScript(environment) {
        return new Promise<void>((resolve, reject) => {
            const adyenStyle = 'adyen-style';
            const adyenScript = 'adyen-script';

            if (!document.getElementById(adyenStyle)) {
                const link = document.createElement('link');
                link.id = adyenStyle;
                link.rel = 'stylesheet';
                link.href = `https://checkoutshopper-${environment}.adyen.com/checkoutshopper/sdk/4.3.1/adyen.css`;
                document.head.appendChild(link);
            }

            if (!document.getElementById(adyenScript)) {
                const script = document.createElement('script');
                script.id = adyenScript;
                script.src = `https://checkoutshopper-${environment}.adyen.com/checkoutshopper/sdk/4.3.1/adyen.js`;
                script.async = true;
                script.onload = () => resolve();
                document.body.appendChild(script);
            } else {
                resolve();
            }
        });
    }

    function processPayment(data) {
        if (data && data.paymentMethod && data.paymentMethod.type === 'applepay') {
            if (data.paymentMethod.applePayToken) {
                data.paymentMethod['applepay.token'] = data.paymentMethod.applePayToken;
            } else {
                return;
            }
        }

        Http.post(`/${apiPrefix}/adyenpayment`, data).then((response: AdyenPaymentViewModel) => {
            handleResponse(response);
        });
    }

    function processAdditionalDetails(data) {
        Http.post(`/${apiPrefix}/adyenadditionaldetails`, data).then((response: AdyenPaymentViewModel) => {
            handleResponse(response);
        });
    }

    function handleResponse(response: AdyenPaymentViewModel) {
        showError.value = false;
        errorMessage.value = null;

        if (!response.success) {
            showError.value = true;
            errorMessage.value = response.errorMessage;

            // Some errors destroys the Adyen Drop-In component
            // We check if the component still exists
            // and initalizes it again if it's not there.
            const dropinElement = dropin.value;
            if (dropinElement.children.length === 0) {
                initAdyenCheckout();
            }
            return;
        }

        if (response.action) {
            adyenDropin.value.handleAction(response.action);
            return;
        }

        if (response.complete) {
        // We use hardRedirect here to be able to track the "transactionComplete" event.
        // CookieGuardedMessage that Vertica.AnalyticsTracker uses doesn't work with our SPA redirect.
        // We should fix it in Vertica.AnalyticsTracker, but for now we just use a hard redirect.
            PageService.hardRedirect(response.redirectUrl);
        }
    }

    function submitPayment() {
        adyenDropin.value.submit();
    }

    return {
        showError,
        errorMessage,
        showLoading,
        basket,
        submitPayment
    };
}
