export const HeadlineSize: typeof Vertica.LouisPoulsen.Application.Content.Blocks.PropertyLists.Enums.HeadlineSize = {
    Default: 0,
    Caption: 1,
    H3: 2,
    H2: 3,
    H1: 4,
    Hero: 5
};

export const QuickOrderSearchFilter: typeof Vertica.LouisPoulsen.Application.QuickOrder.ViewModels.QuickOrderSearchFilter = {
    All: 0,
    Products: 1,
    Spareparts: 2
};

export enum BasketType {
    B2C = 0,
    B2B = 1,
    Quotation = 2,
    POS = 3,
}

export enum LeftRightAlignment {
    Left = 'Left',
    Right = 'Right'
}

export enum ProductTileView {
    Product,
    Room,
}

export enum StoreLocatorView {
    Map,
    List,
}

export enum CordCutting {
    Standard = 'Standard',
    Custom = 'Custom'
}

export enum PaymentProvider {
    Dibs = 0,
    Klarna = 1,
    Adyen = 2,
    Paypal = 3
}

export enum MfaType {
    None = 0,
    Email = 1,
    Otp = 2
}

export const enum FilterAndSortType {
    Radio = 0,
    Checkbox = 1,
    Slider = 2
}

export const enum FilterAndSortRequestType {
    Sort = 0,
    Facet = 1
}

export const enum FilterAndSort {
    Default = 'Default',
    Bestsellers = 'Bestsellers',
    News = 'News',
    PriceAscending = 'PriceAscending',
    PriceDescending = 'PriceDescending'
}

export enum ProductRegistrationMode {
    WebOrder = 0,
    SerialNumber = 1,
    CatalogSearch = 2
}
