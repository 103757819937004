import { store } from '@/store';
import CommunicationService from './communication.service';
import NewFeatureInfoResponse = Vertica.LouisPoulsen.Application.NewFeatureInfo.Api.Responses.NewFeatureInfoResponse;
import NewFeatureInfo2 = Vertica.LouisPoulsen.Application.NewFeatureInfo.Api.Responses.NewFeatureInfo;
import { StorageService } from '@/core/storage.service';
import { pop as popNewFeatureFromQueue, push as pushNewFeaturesToQueue } from './new-features-queue';

const lastCheckedStorageKey = 'communicationpopup-last-checked';

const hoursBetweenPolls = 10;
function timeToPollForNewFeatures():boolean {
    const lastChecked:string = StorageService.getItem(lastCheckedStorageKey);
    if (lastChecked !== null) {
        const now = new Date();
        if (now.getTime() - parseInt(lastChecked, 10) < 1000 * 60 * 60 * hoursBetweenPolls) {
            return false;
        }
    }
    return true;
}

export async function getNewFeatureInfo(): Promise<NewFeatureInfo2 | void> {
    const isAuthenticated = store.getters['app/isAuthenticated'];
    if (!isAuthenticated) {
        return;
    }
    if (timeToPollForNewFeatures()) {
        const featureResponse:NewFeatureInfoResponse = await CommunicationService.checkForNewFeatures();
        if (featureResponse) {
            StorageService.setItem(lastCheckedStorageKey, new Date().getTime());
            if (featureResponse.newFeatureInfo.length > 0) {
                if (featureResponse.newFeatureInfo.length > 1) {
                    pushNewFeaturesToQueue(featureResponse.newFeatureInfo.slice(1));
                }
                return featureResponse.newFeatureInfo[0];
            }
        } else {
            return popNewFeatureFromQueue();
        }
    } else {
        return popNewFeatureFromQueue();
    }
}
