
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({
    name: 'flowbox',
    components: {}
})
export default class Flowbox extends Vue {
    @Prop({
        required: true,
        type: String
    })
    public flowKey: string;

    @Prop({
        required: true,
        type: String
    })
    public culture: string;

    @Prop({
        required: false,
        type: String
    })
    public productId: string;

    @Prop({
        required: true,
        type: String
    })
    public elementUid: string;

    get elementId() {
        return 'js-flowbox-flow-' + this.elementUid;
    }

    cookiesAcceptButton = document.querySelector('#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll');
    cookiesSaveButton = document.querySelector('#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection');
    allowCookies = this.setAllowCookies();
    flowBoxOverlayOpenScrollYPosition;

    flowBoxOverlayObserver = new MutationObserver((mutations) => {
        mutations.forEach(mutation => {
            if (mutation.type === 'attributes') {
                if ((mutation.target as HTMLBodyElement).classList.contains('flbx-body-lock')) {
                    this.flowBoxOverlayOpenScrollYPosition = window.pageYOffset;
                    document.documentElement.classList.add('modal-open');
                } else {
                    document.documentElement.classList.remove('modal-open');

                    // The timeout below is necessary because flowbox do some nasty stuff behind our back. The timeout can be set to 0 seconds as we have done. It's just to circumvent some nasty flowbox stuff.
                    // This ensures that we always keep the same Y position after flowboxs modal have been opened
                    setTimeout(() => { window.scrollTo(0, this.flowBoxOverlayOpenScrollYPosition); }, 0);
                }
            }
        });
    });

    mounted() {
        const timeout = this.getBrowserTimeouts();
        setTimeout(() => {
            if (document.getElementById('flowbox-js-embed')) { this.initFlowbox(); }

            const initFlowboxFunction = this.initFlowbox;
            (function(d, id) {
                if (!(window as any).flowbox) { const f: any = function() { f.q.push(arguments); }; f.q = []; (window as any).flowbox = f; }
                if (d.getElementById(id)) { return; }
                const s = d.createElement('script'); const fjs = d.scripts[d.scripts.length - 1]; s.id = id; s.async = true;
                s.src = 'https://connect.getflowbox.com/flowbox.js';
                s.setAttribute('data-cookieconsent', 'statistics');
                s.addEventListener('load', initFlowboxFunction);
                fjs.parentNode.insertBefore(s, fjs);
            })(document, 'flowbox-js-embed');
        }, timeout);

        document.addEventListener('click', (event) => {
            if ((event.target as HTMLInputElement).matches('.c-property-selector-color') || (event.target as HTMLInputElement).matches('.c-property-selector__option--color')) {
                this.updateFlow();
            }
        }, false);

        if (this.cookiesAcceptButton) {
            this.cookiesAcceptButton.addEventListener('click', function() {
                if (document.querySelector('.bzfy-o-context')) {
                    this.updateFlow();
                }
            });
        }
        if (this.cookiesSaveButton) {
            this.cookiesSaveButton.addEventListener('click', function() {
                if (document.querySelector('.bzfy-o-context')) {
                    this.updateFlow();
                }
            });
        }
    }

    setAllowCookies() {
        if ((window as any).Cookiebot) {
            if ((window as any).consent) {
                this.allowCookies = (window as any).consent.statistics;
            }
        }
    }

    beforeDestroy() {
        this.flowBoxOverlayObserver.disconnect();
    }

    initFlowbox() {
        this.setAllowCookies();
        (window as any).flowbox('init', {
            container: '#' + this.elementId,
            key: this.flowKey,
            locale: this.culture,
            lazyLoad: false,
            productId: new URLSearchParams(window.location.search).get('v'),
            allowCookies: this.allowCookies
        });

        this.$emit('flowbox-loaded');

        /*
        * Overlay element is removed and added again when changing post inside the overlay.
        * Therefore we watch for mutations on the class list and childList.
        * Class changes are used to get the initial pageOffset and setting 'modal-open' class on the html-tag.
        * ChildList is used to override the scroll position when overlay opdates.
        * */
        this.flowBoxOverlayObserver.observe(document.body, {
            attributeFilter: ['class'],
            childList: true
        });
    }

    updateFlow() {
        this.setAllowCookies();
        (window as any).flowbox('update', {
            key: this.flowKey,
            productId: new URLSearchParams(window.location.search).get('v'),
            allowCookies: this.allowCookies
        });
    }

    getBrowserTimeouts() {
        const userAgent = window.navigator.userAgent.toLowerCase();
        let timeout = 500;

        switch (true) {
        case userAgent.includes('safari'):
            timeout = 1500;
            break;
        case userAgent.includes('firefox'):
            timeout = 800;
            break;
        case userAgent.includes('chrome'):
            timeout = 500;
            break;
        case userAgent.includes('edge'):
            timeout = 300;
            break;
        }

        return timeout;
    }
}
