class ColorService {
    isTooBright(hexColor: string) : boolean {
        if (!hexColor) return false;
        const trimmed = hexColor.trim();
        const c = trimmed.substring(Math.max(trimmed.indexOf('#') + 1, 0)).substring(0, 6);
        if (!c.match(/[0-9A-Fa-f]{6}/g)) return false; // check if characters of string after possible # match rrggbb format
        const rgb = parseInt(c, 16); // convert rrggbb to decimal
        const r = (rgb >> 16) & 0xff; // extract red
        const g = (rgb >> 8) & 0xff; // extract green
        const b = (rgb >> 0) & 0xff; // extract blue
        const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709
        return luma > 213;
    }

    isColorLight(color: string) : boolean {
        if (!color) return false;

        let colorMatch: number | any[] | RegExpMatchArray;
        let r: number;
        let g: number;
        let b: number;

        // Check the format of the color, HEX or RGB?
        if (color.match(/^rgb/)) {
            // If RGB --> store the red, green, blue values in separate variables
            colorMatch = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

            r = colorMatch[1];
            g = colorMatch[2];
            b = colorMatch[3];
        } else {
            // If hex --> Convert it to RGB: http://gist.github.com/983661
            colorMatch = +('0x' + color.slice(1).replace(color.length < 5 && /./g, '$&$&'));

            r = colorMatch >> 16;
            g = colorMatch >> 8 & 255;
            b = colorMatch & 255;
        }

        // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
        const hsp = Math.sqrt(
            0.299 * (r * r) +
            0.587 * (g * g) +
            0.114 * (b * b)
        );

        // Using the HSP value, determine whether the color is light or dark
        return hsp > 225;
    }
}

export default new ColorService();
