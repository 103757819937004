import { render, staticRenderFns } from "./ProductColorPicker.vue?vue&type=template&id=ac0126b8&scoped=true&"
import script from "./ProductColorPicker.vue?vue&type=script&lang=ts&setup=true&"
export * from "./ProductColorPicker.vue?vue&type=script&lang=ts&setup=true&"
import style0 from "./ProductColorPicker.vue?vue&type=style&index=0&id=ac0126b8&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ac0126b8",
  null
  
)

export default component.exports