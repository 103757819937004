class LineTagService {
    trackAddCart(){
        //@ts-ignore
        _lt('send', 'cv', {  type: 'addcart'},['7cdf0851-4746-4966-a58d-b6a18b85c9f1']);
    }
    
    trackCompleteRegistration(){
        //@ts-ignore
        _lt('send', 'cv', {type: 'CompleteRegistration'},['7cdf0851-4746-4966-a58d-b6a18b85c9f1']);
    }
}

export default new LineTagService();
