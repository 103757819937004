import { GetterTree, MutationTree, ActionTree } from 'vuex';
import { namespace } from 'vuex-class';
import CheckoutService from '../services/checkout.service';
import { BasketType } from '@/enums';

enum MutationMethod {
    toggleIsProcessingOrder = 'toggleIsProcessingOrder'
}

const storeState: ICheckoutStateB2B = {
    isProcessingOrder: false
};

const getters: GetterTree<ICheckoutStateB2B, any> = {
    isProcessingOrder: state => state.isProcessingOrder
};

const actions: ActionTree<ICheckoutStateB2B, any> = {
    async submitOrder({ commit }, request: Vertica.LouisPoulsen.Application.Commerce.Requests.Business.SaveCheckoutB2BWebRequest) {
        request.basketType = BasketType.B2B as any;
        commit(MutationMethod.toggleIsProcessingOrder, true);
        try {
            return await CheckoutService.submitB2BCheckout(request);
        } catch (error) {
            console.log(error);
            commit(MutationMethod.toggleIsProcessingOrder, false);
        }
    },

    async submitQuote({ commit }, request: Vertica.LouisPoulsen.Application.Commerce.Requests.Business.SaveCheckoutB2BWebRequest) {
        request.basketType = BasketType.Quotation as any;
        commit(MutationMethod.toggleIsProcessingOrder, true);
        try {
            return await CheckoutService.submitB2BCheckout(request);
        } catch (error) {
            console.log(error);
            commit(MutationMethod.toggleIsProcessingOrder, false);
        }
    }

};

const mutations: MutationTree<ICheckoutStateB2B> = {
    [MutationMethod.toggleIsProcessingOrder](state, flag) {
        state.isProcessingOrder = flag;
    }
};

export default {
    namespaced: true,
    state: storeState,
    getters,
    actions,
    mutations
};

const moduleName = 'checkoutb2b';
const moduleObject = namespace(moduleName);

export const CheckoutB2BGetter = moduleObject.Getter;
export const CheckoutB2BAction = moduleObject.Action;
