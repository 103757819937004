import Vue from "vue";
import * as VueGoogleMaps from "vue2-google-maps";
import ServerContextService from "../core/server-context.service";

Vue.use(VueGoogleMaps, {
    load: {
        key: "AIzaSyBBg8qaQej2X5tljh0FI77r5RXYYdL3Ix0",
        libraries: "geometry,places",
        region: ServerContextService.primaryCountryCode
    }
});

