
import Vue from "vue";
import ViewportEventsService from "../../core/responsive/viewport/viewport-events.service";

Vue.directive("fully-in-viewport", {
    inserted(el, binding) {
        const callbackIndex = ViewportEventsService.setCallback(() => {
            isElementInView(el).then(isInView => {
                if (isInView) {
                    binding.value();
                    ViewportEventsService.removeCallback(callbackIndex);
                }
            });
        });
        el.setAttribute("viewport-index", callbackIndex + "");
    },
    unbind(el) {
        const callbackIndex = el.getAttribute("viewport-index");
        ViewportEventsService.removeCallback(parseInt(callbackIndex, 10));
    }
});

function isElementInView(element: HTMLElement): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
        //delay this calculation cause we are not gaurenteed that element is in dom on inserted hook
        setTimeout(() => {

            /** Abort, no element provided or no getBoundingClientRect */
            if (element === undefined || !element.getBoundingClientRect) {
                resolve(false);
            }
            const rect = element.getBoundingClientRect();
            /** Abort, element has not been rendered yet */
            if (rect.height === 0 && rect.width === 0) {
                resolve(false);
            }

            /** Some portion of the element is within the viewport */
            if (rect.top >= 0 && rect.bottom <= (window.innerHeight || document. documentElement.clientHeight)) {
                resolve(true);
            }

            resolve(false);
        }, 50);
    });
}
