
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Mixins } from 'vue-mixin-decorator';
import { BreakpointsMixin } from '@/core/responsive/breakpoints/breakpoint.mixin';
import TrackingService from '@/core/tracking.service';

@Component({
    name: 'distribution-diagram-image'
})
export default class DistributionDiagramImage extends Mixins<BreakpointsMixin>(BreakpointsMixin) {
    @Prop({ default: '' })
    public imageUrl: string;

    public hasError = false;
    public isImageEnlarged: boolean = false;

    public openModal() {
        if (this.imageUrl && !this.hasError) {
            this.isImageEnlarged = true;
            TrackingService.event('Mini page', 'Light distribution diagram', 'Open');
        } else {
            this.isImageEnlarged = false;
        }
    }

    public closeModal() {
        this.isImageEnlarged = false;
    }

    public imageError() {
        this.hasError = true;
    }
}
