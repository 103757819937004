import Http from '@/core/http/http.service';
import ImpersonationResponse = Vertica.LouisPoulsen.Application.Authentication.Api.Responses.ImpersonationResponse;
import ValidateCustomerRequest = Vertica.LouisPoulsen.Application.Authentication.Api.Requests.ValidateCustomerRequest;
import ValidateCustomerResponse = Vertica.LouisPoulsen.Application.Authentication.Api.Responses.ValidateCustomerResponse;
import ImpersonateCustomerRequest = Vertica.LouisPoulsen.Application.Authentication.Api.Requests.ImpersonateCustomerRequest;
import DeliveryCountrySelectItemViewObject = Vertica.LouisPoulsen.Application.Content.Pages.Basket.Business.DeliveryCountrySelectItemViewObject;

class ImpersonationService {
    public initialize() : Promise<DeliveryCountrySelectItemViewObject[]> {
        return Http.get('impersonation/initialize');
    }

    public get() : Promise<ImpersonationResponse> {
        return Http.get('impersonation');
    }

    public validateCustomer(request: ValidateCustomerRequest) : Promise<ValidateCustomerResponse> {
        return Http.post('impersonation', request);
    }

    public set(request: ImpersonateCustomerRequest): Promise<ImpersonationResponse> {
        return Http.put('impersonation', request);
    }

    public setNoImpersonation(): Promise<void> {
        return Http.get('impersonation/none');
    }

    public end(): Promise<void> {
        return Http.remove('impersonation');
    }
}

export default new ImpersonationService();
