import Http from '../../../core/http/http.service';
import NewFeatureInfoResponse = Vertica.LouisPoulsen.Application.NewFeatureInfo.Api.Responses.NewFeatureInfoResponse;

class CommunicationService {
    public async checkForNewFeatures() {
        return Http.get<NewFeatureInfoResponse>('new-features')
            .then((response) => {
                return response;
            });
    }
}

export default new CommunicationService();
