
export interface IShowNavigationButtons {
    loggedIn: boolean;
    inCheckout: boolean;
    hideSiteModeProf: boolean;
}

class MainNavigationService {
    public getNavigationButtonState(state: IShowNavigationButtons): NavigationButtonState {
        const { loggedIn, inCheckout, hideSiteModeProf } = state;

        if (inCheckout) { return NavigationButtonState.HideAll; }

        if (loggedIn || hideSiteModeProf) { return NavigationButtonState.HideSiteMode; }

        return NavigationButtonState.ShowAll;
    }
}

export class NavigationButtonState {
    public static ShowAll = 0;
    public static HideSiteMode = 1;
    public static HideAll = 2;
}

export default new MainNavigationService();
