import * as en from 'vue2-datepicker/locale/en';
import * as ca from 'vue2-datepicker/locale/ca';
import * as zh from 'vue2-datepicker/locale/zh-cn';
import * as cs from 'vue2-datepicker/locale/cs';
import * as nl from 'vue2-datepicker/locale/nl';
import * as da from 'vue2-datepicker/locale/da';
import * as fr from 'vue2-datepicker/locale/fr';
import * as de from 'vue2-datepicker/locale/de';
import * as he from 'vue2-datepicker/locale/he';
import * as hu from 'vue2-datepicker/locale/hu';
import * as it from 'vue2-datepicker/locale/it';
import * as ko from 'vue2-datepicker/locale/ko';
import * as ja from 'vue2-datepicker/locale/ja';
import * as nb from 'vue2-datepicker/locale/nb';
import * as pt from 'vue2-datepicker/locale/pt';
import * as pl from 'vue2-datepicker/locale/pl';
import * as ro from 'vue2-datepicker/locale/ro';
import * as ru from 'vue2-datepicker/locale/ru';
import * as es from 'vue2-datepicker/locale/es';
import * as sv from 'vue2-datepicker/locale/sv';

export default {
    en,
    ca,
    zh,
    cs,
    nl,
    da: {
        ...da,
        formatLocale: {
            ...da.formatLocale,
            firstDayOfWeek: 1,
            monthsShort: da.formatLocale.months,
            weekdaysMin: [
                'S',
                'M',
                'Ti',
                'O',
                'To',
                'F',
                'L'
            ]
        }
    },
    fr,
    de,
    he,
    hu,
    it,
    ko,
    ja,
    nb,
    pt,
    pl,
    ro,
    ru,
    es,
    sv
};
