
import { Ref, onMounted, ref, watch } from 'vue';
import { StorageService } from '@/core';
import CheckoutService from '@/services/checkout.service';
import { injectParentValidator } from '@/core/app.service';
import { useTranslations } from '@/core/translate.mixin';
import { useAddressAutoComplete } from '@/services/address-auto-complete.service';

interface IShippingAddress {
    city: string,
    extendedFields: { stateCode: string }
}

interface IYubinBangoRefs {
    region: Ref;
    locality: Ref;
}

export function useAlternativeDeliveryFormJapan<TShippingAddress extends IShippingAddress>(props: any, emit: any, refs: IYubinBangoRefs) {
    const selectedShippingCountry = ref<AddressCountryViewObject>({} as AddressCountryViewObject);
    const selectedShippingState = ref<StateViewObject>({} as StateViewObject);
    const form = ref<TShippingAddress>(props.value) as Ref<TShippingAddress>;

    // Inject validator
    const $validator = injectParentValidator();
    const { t } = useTranslations();
    const labelNamespace = 'Pages.Checkout.Customer';
    const generalMaxLength = 35;
    const phoneNumberMaxLength = 16;
    const postalCodeLength = 7;

    const { setUpHiddenInputChange, reloadYubinBango } = useAddressAutoComplete();

    onMounted(() => {
        const dictionary = {
            custom: {
                firstName_: {
                    required: t(`${labelNamespace}.FirstNameRequiredErrorMessage`),
                    max: t(`${labelNamespace}.FirstNameMaxLengthErrorMessage`).replace('{0}', generalMaxLength.toString())
                },
                lastName_: {
                    required: t(`${labelNamespace}.LastNameRequiredErrorMessage`),
                    max: t(`${labelNamespace}.LastNameMaxLengthErrorMessage`).replace('{0}', generalMaxLength.toString())
                },
                postalcode_: {
                    required: t(`${labelNamespace}.PostalCodeRequiredErrorMessage`),
                    length: t(`${labelNamespace}.PostalCodeLengthErrorMessage`).replace('{0}', postalCodeLength.toString())
                },
                state_: {
                    required: t(`${labelNamespace}.StateRequiredErrorMessage`)
                },
                city_: {
                    required: t(`${labelNamespace}.CityRequiredErrorMessage`),
                    max: t(`${labelNamespace}.CityMaxLengthErrorMessage`).replace('{0}', generalMaxLength.toString())
                },
                address_: {
                    max: t(`${labelNamespace}.AddressMaxLengthErrorMessage`).replace('{0}', generalMaxLength.toString())
                },
                phone_: {
                    required: t(`${labelNamespace}.PhoneNumberRequiredErrorMessage`),
                    regex: t(`${labelNamespace}.PhoneNumberInvalidFormatErrorMessage`),
                    max: t(`${labelNamespace}.PhoneNumberMaxLengthErrorMessage`).replace('{0}', phoneNumberMaxLength.toString())
                }
            }
        };
        $validator.localize('en', dictionary);
        setSelectedCountryAndState();
        const deliveryInfo = Object.assign(form.value, { countryCode: selectedShippingCountry.value.countryCode });
        emit('input', deliveryInfo);
        emit('updateShippingCountry', selectedShippingCountry.value);
        setDeliveryInfoInLocalStorage(deliveryInfo);

        setUpHiddenInputChange(refs.region.value, onRegionChange);
        setUpHiddenInputChange(refs.locality.value, onLocalityChange);
        reloadYubinBango(props.yubinBangoLoaded);
    });

    function setSelectedCountryAndState() {
        selectedShippingCountry.value = props.model.shippingAddressCountries[0];
        selectedShippingState.value = props.model.states.find(c => c.code === form.value.extendedFields.stateCode);
    }

    watch(() => props.value, onDeliveryInfoChange, { deep: true });

    function onDeliveryInfoChange() {
        form.value = props.value;
        emit('input', form.value);
        setSelectedCountryAndState();
    }

    function onShippingStateChanged(state: StateViewObject) {
        const deliveryInfo = Object.assign(form.value, { extendedFields: Object.assign(form.value.extendedFields, { stateCode: state.code }) });
        emit('input', deliveryInfo);
        setDeliveryInfoInLocalStorage(deliveryInfo);
    }

    function setDeliveryInfoInLocalStorage(value: TShippingAddress) {
        StorageService.setItemAs(CheckoutService.deliveryInfoLocalStorageKey, value);
    }

    function blur() {
        setDeliveryInfoInLocalStorage(form.value);
    }

    function onRegionChange() {
        const prefecture = props.model.states.find(x => x.displayName === refs.region.value.value);
        if (prefecture) {
            selectedShippingState.value = prefecture;
            onShippingStateChanged(selectedShippingState.value);
            setTimeout(() => $validator.validate('state_'), 100);
        }
    }

    function onLocalityChange() {
        if (refs.locality.value.value) {
            form.value.city = refs.locality.value.value;
            setTimeout(() => $validator.validate('city_'), 100);
        }
    }
    return {
        labelNamespace,
        selectedShippingCountry,
        selectedShippingState,
        form,
        onShippingStateChanged,
        blur,
        generalMaxLength,
        postalCodeLength,
        phoneNumberMaxLength
    };
}
