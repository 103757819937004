
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { WarrantyFormAction, AppGetter } from '../../../store';
import $ from 'cash-dom';
import VueProgrammaticInvisibleGoogleRecaptcha from 'vue-programmatic-invisible-google-recaptcha';
import userAgent from '@/services/userAgent.service';

    @Component({
        name: 'warrant-form',
        components: {
            VueProgrammaticInvisibleGoogleRecaptcha
        }
    })
export default class WarrantyForm extends Vue {
        @AppGetter
    public recaptchaKey: string;

        @Prop({ default: false })
        public addTopMargin: boolean;

        @WarrantyFormAction
        public submitFormOptions: (request) => Promise<string>;

        public submittingFormData: boolean = false;
        public formSubmitted: boolean = false;

        public request: WarrantyFormPageWebRequest = {
            name: '',
            amount: '',
            comment: '',
            email: '',
            orderNumber: '',
            itemNumber: '',
            recaptchaToken: ''
        };

        public _uid: any; // Vue provided uniq ID
        public componentId: string = 'returnFormRecaptcha';
        public runAnimations = userAgent.isPrerender;

        initAnimations(): void {
            this.runAnimations = true;
        }

        public validateAndSubmitRequest() {
            this.$validator
                .validateAll()
                .then(isValid => {
                    if (isValid) {
                        (this.$refs[this.componentId + this._uid] as any).execute();
                    } else {
                        this.scrollInvalidElementIntoView();
                    }
                })
                .catch(x => console.log('catch' + x));
        }

        public submitForm(recaptchaToken) {
            this.submittingFormData = true;
            const formData: any = new FormData();
            formData.append('name', this.request.name);
            formData.append('amount', this.request.amount);
            formData.append('comment', this.request.comment);
            formData.append('email', this.request.email);
            formData.append('orderNumber', this.request.orderNumber);
            formData.append('itemNumber', this.request.itemNumber);
            formData.append('recaptchaToken', recaptchaToken);

            const element: HTMLInputElement = document.querySelector('#warrantyForm input[type=\'file\']');
            for (let i = 0; i < element.files.length; i++) {
                formData.append('upload[]', element.files[i], element.files[i].name);
            }

            this.submitFormOptions((formData as WarrantyFormPageWebRequest)).then(
                () => {
                    this.handleSuccess();
                },
                () => {
                    this.submittingFormData = false;
                }
            );
        }

        private handleSuccess() {
            this.submittingFormData = false;
            this.formSubmitted = true;

            setTimeout(() => {
                this.formSubmitted = false;
            }, 15000);
        }

        private scrollInvalidElementIntoView() {
            const firstInvalidElement = $('.is-danger').first()[0];
            if (firstInvalidElement) {
                firstInvalidElement.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' });
            }
        }
}
