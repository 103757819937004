import Http from '@/core/http/http.service';
import IUpdatePasswordForm from '@/ui/views/updatepassword/updatepasswordform.interface';
import ChangeOneTimePasswordWebRequest = Vertica.LouisPoulsen.Application.OldAuthentication.Requests.OldChangeOneTimePasswordWebRequest;
import ChangeOneTimePasswordViewModel = Vertica.LouisPoulsen.Application.OldAuthentication.ViewModels.OldChangeOneTimePasswordViewModel;

class ChangeOneTimePasswordService {
    public async updatePassword(formData: IUpdatePasswordForm, redirectUrl: string): Promise<ChangeOneTimePasswordViewModel> {
        const { currentPassword, newPassword, confirmPassword } = formData;

        const request: ChangeOneTimePasswordWebRequest = {
            currentPassword,
            newPassword,
            confirmPassword,
            redirectUrl
        };

        return Http.post('oldauthentication/changeonetimepassword', request)
            .then((response: ChangeOneTimePasswordViewModel) => {
                return response;
            });
    }
}

export default new ChangeOneTimePasswordService();
