
import Vue from 'vue';
import Component from 'vue-class-component';
import { QueryParams } from '@/core';
import Http from '@/core/http/http.service';
import ProductConfigurationSaveViewModel = Vertica.LouisPoulsen.Application.ProductConfigurator.ViewModels.Save.ProductConfigurationSaveViewModel;
import QueryParamService from '@/core/query-params.service';
import { Prop } from 'vue-property-decorator';

@Component({
    name: 'product-configurator'
})
export default class ProductConfigurator extends Vue {
    @Prop({
        required: true,
        type: String
    })
    public remoteUrl: string;

    private showSpinner: boolean = true;
    private showOverlayLoading: boolean = false;
    private instance: any = null;
    private allowPaste: boolean = false;

    mounted() {
        this.loadConfigurator().then(() => {
            this.instance = (window as any).UnityLoader.instantiate('gameContainer', this.remoteUrl + '/$web/LPPC.json?v=' + new Date().getTime(), { onProgress: (window as any).UnityProgress });

            const recaptureInputAndFocus = function() {
                const canvas = document.getElementById('#canvas') as any;
                canvas.style.outline = 'none';
                canvas.onselectstart = () => false;
                if (canvas) {
                    canvas.setAttribute('tabindex', '1');
                    canvas.focus();
                } else { setTimeout(recaptureInputAndFocus, 100); }
            };

            (window as any).lppcOnConfiguratorLoad = () => {
                this.showSpinner = false;

                const configuratorCode = QueryParamService.getParam(QueryParams.ProductConfiguratorCode);
                if (configuratorCode) {
                    this.instance.SendMessage('SerializationController', 'OnLoadConfiguration', configuratorCode);
                }
            };
            (window as any).lppcOnSpecificationClick = (data) => {
                const jsonData = JSON.parse(data) as any;
                Http.post('productconfigurator/save', jsonData).then((response: ProductConfigurationSaveViewModel) => {
                    QueryParamService.deleteQueryParam(QueryParams.ProductConfiguratorCode);
                    QueryParamService.setQueryParamValue(QueryParams.ProductConfiguratorCode, jsonData.code);
                    this.$router.push(response.redirectUrl);
                });
            };
            (window as any).lppcCopyToClipboard = (valueToCopy) => {
                /* This method will copy the input string "value" to the users clipboard.
                 * It is done by adding an input tag to the don with the "value" set to the input string.
                 * Then selecting that value and executing the "copy" command on document.
                 * It is done in this way instead of using "Clipboard API" because, according to MDN, there are/can be some issues depending on browsers.
                 */

                const input = document.createElement('textArea') as HTMLTextAreaElement;
                input.setAttribute('class', 'u-absolute u-bottom-0 u-w-px u-h-0 u-overflow-hidden u-pointer-events-none');
                input.value = valueToCopy;
                document.body.appendChild(input);
                input.select();
                document.execCommand('copy');
                document.body.removeChild(input);
            };
            (window as any).lppcOnPasteAllowed = (allow) => {
                this.allowPaste = allow;
            };
            (window as any).lppcShowLoading = (showLoading) => {
                this.showOverlayLoading = showLoading;
            };
            (window as any).lppcOnGetBaseUrl = () => {
                const baseUrl = window.location.protocol + '//' + window.location.host;
                this.instance.SendMessage('SerializationController', 'SetBaseUrl', baseUrl);
            };

            window.addEventListener('paste', (e: ClipboardEvent) => {
                if (this.allowPaste) {
                    const value = e.clipboardData.getData('text');
                    this.instance.SendMessage('LoadInputField', 'PasteTextFromClipboard', value);
                }
            });

            recaptureInputAndFocus();
        });
    }

    private loadConfigurator() {
        this.showSpinner = true;

        return new Promise<void>((resolve) => {
            const scripts: string[] = [
                this.remoteUrl + '/template/UnityProgress.js',
                this.remoteUrl + '/$web/UnityLoader.js'
            ];

            const promises = [];
            scripts.forEach((v) => {
                const promise = new Promise<void>((_resolve) => {
                    const script = document.createElement('script');
                    script.setAttribute('src', v);
                    script.addEventListener('load', () => { _resolve(); });
                    document.head.appendChild(script);
                });
                promises.push(promise);
            });

            Promise.all(promises).then(() => { resolve(); });
        });
    }
}
