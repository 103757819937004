import '@babel/polyfill';
import Vue from 'vue';
import { sync } from 'vuex-router-sync';
import { router } from './router';
import { store } from './store';
import './styling/main.less';
import './filters';
import './ui/shared/image/onerror.directive';
import './ui/shared/in-viewport.directive';
import './ui/shared/fully-in-viewport.directive';
import './ui/shared/submit.directive';
import './main.config';
import './main.plugins';
import './enums';
sync(store, router);

// tslint:disable-next-line:no-unused-expression
new Vue({
    el: '#app',
    router,
    store
});
