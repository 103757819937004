import { ActionTree } from "vuex";
import { namespace } from "vuex-class";
import WarrantyformService from "../services/warrantyform.service";


const actions: ActionTree<any, any> = {
    async submitFormOptions({commit}, request: WarrantyFormPageWebRequest ) {
        return await WarrantyformService.submitFormOptions(request);
    }
};


export default {
    namespaced: true,
    actions,
};

const moduleName = "warrantyform";
const moduleObject = namespace(moduleName);

export const WarrantyFormAction = moduleObject.Action;
