import { onMounted, onBeforeUnmount, ref } from 'vue';
import { useRootElement } from './rootElement';

export function useRootComponentWidth() {
    const rootComponentWidth = ref();
    const { rootElement } = useRootElement();
    const resizeObserver = new ResizeObserver(() => {
        if (rootElement.value) {
            rootComponentWidth.value = rootElement.value.clientWidth;
        }
    });

    onMounted(() => {
        if (rootElement.value) {
            resizeObserver.observe(rootElement.value);
        }
    });
    onBeforeUnmount(() => resizeObserver.disconnect());

    return {
        rootComponentWidth
    };
}
