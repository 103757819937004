export default {
    // tslint:disable-next-line:max-line-length
    path: 'M7.5,0C3.4,0,0,3.4,0,7.5c0,5.6,7.5,13.9,7.5,13.9S15,13.1,15,7.5C15,3.4,11.6,0,7.5,0z M7.5,10C6.1,10,5,8.9,5,7.5 S6.1,5,7.5,5S10,6.1,10,7.5S8.9,10,7.5,10z',
    anchor: { x: 7.5, y: 22 },
    fillColor: '#000000',
    fillOpacity: 1,
    strokeColor: '#000000',
    strokeWeight: 0,
    scale: 2
};
