declare var window: any;

export enum GATrackingEventName {
    GAEvent = 'GAEvent',
    searchSuggestType = 'searchSuggestType',
    searchSuggestSelect = 'searchSuggestSelect'
}

class TrackingService {

    public event(category: string, action: string, label?: string): void {
        this.pushToDataLayer({
            'event': GATrackingEventName.GAEvent,
            'eventCategory': category,
            'eventAction': action,
            'eventLabel':  label
        });
    }

    public pushToDataLayer(data: any){
        (window as any).dataLayer.push(data);
    }


}

export default new TrackingService();
