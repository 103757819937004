
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

    @Component({
        name: 'product-tender-info-item'

    })
export default class ProductTenderInfoItem extends Vue {
        @Prop()
    public model: TenderInfoViewObject;
}

