class NumberService {
    public parseIntWithDefault(value: string) : number | null {
        const number: number = parseInt(value, 10);
        return Number.isNaN(number) ? null : number;
    }

    formatCurrency(amount: number | undefined | null, withUnit = false, showZeroAmount = false, lang = 'da-DK', currency = 'DKK'): string | undefined {
        if ((!amount || typeof amount !== 'number') && !showZeroAmount) {
            return;
        }

        const amountAsNumber = amount as number;
        const amountActual = isNaN(amountAsNumber) ? 0 : amountAsNumber;

        const currentLang = lang === 'de-CH' || lang === 'fr-CH' ? 'de-DE' : lang; // *-CH is incorrectly formatted in Intl.NumberFormat

        const numberFormat = new Intl.NumberFormat(currentLang, {
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
            ...(withUnit ? { currency, style: 'currency' } : {})
        });

        return numberFormat.format(amountActual);
    }
}

export default new NumberService();
