/*
Params are documented here:
https://imageresizing.net/docs/v4/docs/basics
https://imageresizing.net/docs/v4/plugins/webp

We have only implemented the params we needed
*/
import { ImageResizerOptions } from './imageResizerTypes';

export function getImageUrlWithImageResizerParams(baseUrl: string, options: ImageResizerOptions): string {
    let url = baseUrl + '?';
    if (options.height) {
        url += `&height=${options.height}`;
    }
    if (options.width) {
        url += `&width=${options.width}`;
    }
    if (options.bgcolor) {
        url += `&bgcolor=${options.bgcolor}`;
    }
    if (options.mode) {
        url += `&mode=${options.mode}`;
    }
    if (options.format) {
        url += `&format=${options.format}`; // if format is not provided, ImageResizer defaults to original (or closests match)
    }
    if (options.quality) {
        url += `&quality=${options.quality}`;
    }
    if (options.lossless !== undefined) {
        url += `&lossless=${options.lossless}`;
    }
    if (options.noalpha !== undefined) {
        // PS: Beware that the option only have effect if you also set bgcolor
        url += `&noalpha=${options.noalpha}`;
    }

    return encodeURI(url.replace('?&', '?'));
}
