import Vue from 'vue';
import VeeValidate from 'vee-validate';
import DictionaryService from '../core/dictionary.service';

const validateConfig = {
    dictionary: {
        en: {
            messages: {
                required: () => DictionaryService.get('Shared.Validation.Required'),
                email: () => DictionaryService.get('Shared.Validation.Email'),
                numeric: () => DictionaryService.get('Shared.Validation.Numeric'),
                confirmed: () => DictionaryService.get('Shared.Validation.Confirm'),
                password_requirements: () => DictionaryService.get('Shared.Validation.PasswordRequirements'),
                myaccount_password_requirements: () => DictionaryService.get('Shared.Validation.MyAccountPasswordRequirements'),
                username_requirements: () => DictionaryService.get('Shared.Validation.UsernameRequirements')
            }
        }
    }
};

Vue.use(VeeValidate, validateConfig);

VeeValidate.Validator.extend('password_requirements', {
    message: () => { 'error validation password'; },
    validate: password => {
        const containsUppercaseCharacter = /[A-Z]/g.test(password);
        const containsNumber = /[0-9]/g.test(password);
        const min9Characters = password.length >= 9;
        const max30Characters = password.length <= 30;
        const noWhitespaces = /^\S*$/g.test(password);

        return containsUppercaseCharacter &&
            containsNumber &&
            min9Characters &&
            max30Characters &&
            noWhitespaces;
    }
});

VeeValidate.Validator.extend('myaccount_password_requirements', {
    message: () => { 'error validation password'; },
    validate: password => {
        const containsUppercaseCharacter = /[A-Z]/g.test(password);
        const containsSpecialCharacter = /[^A-Za-z0-9]/g.test(password);
        const containsNumber = /[0-9]/g.test(password);
        const min8Characters = password.length >= 8;
        const max30Characters = password.length <= 30;
        const noWhitespaces = /^\S*$/g.test(password);

        return containsUppercaseCharacter &&
        containsSpecialCharacter &&
            containsNumber &&
            min8Characters &&
            max30Characters &&
            noWhitespaces;
    }
});

VeeValidate.Validator.extend('username_requirements', {
    message: () => { 'error validation username'; },
    validate: username => {
        const min5Characters = username.length >= 5;
        const max40Characters = username.length <= 40;
        const noWhitespaces = /^\S*$/g.test(username);

        return min5Characters &&
            max40Characters &&
            noWhitespaces;
    }
});

VeeValidate.Validator.extend('sales_tax', {
    getMessage(field, params, data) {
        return DictionaryService.get('Shared.Validation.UsSalesTax');
    },
    validate: salesTaxText => {
        return salesTaxText && salesTaxText !== '-';
    }
});
