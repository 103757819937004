
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { savingsDataModel } from '@/ui/shared/blocks/LedCalculator/LedCalculatorTypes';

@Component
export default class BlockLedCalculatorSavings extends Vue {
    @Prop({
        type: [Object, null],
        default: null,
        required: false
    }) savingsData: savingsDataModel | null;

    @Prop({
        type: Number,
        required: true
    }) calculationPeriod: number;

    @Prop({
        type: Number,
        required: true
    }) numberOfFixtures: number;

    @Prop({
        type: Boolean,
        required: true
    }) isDirty: boolean;
}
