
import Vue from 'vue';
import Component from 'vue-class-component';
import { AppGetter, SearchAction } from '../../../store';
import { QueryParams } from '../../../core/query-params.service';

@Component({
    name: 'on-page-search',
})
export default class onPageSearch extends Vue {
    @AppGetter
    public searchResultUrl: string;
    @SearchAction
    public search: (term: string) => void;
    public searchTerm: string = '';

    mounted() {
        this.focusInput();
    }

    private focusInput() {
        const input = this.$refs['onPageSearchInput'];
        if (input) {
            (input as HTMLInputElement).focus();
        }
    }

    navigateToSearch() {
        const query = {};
        query[QueryParams.SearchTerm] = this.searchTerm;
        this.$router.push({ path: this.searchResultUrl, query });
        this.searchTerm = '';
    }
}
