import Http from '@/core/http/http.service';
import StockTransferOrderViewModel = Vertica.LouisPoulsen.Application.PointOfSale.StockTransferOrder.StockTransferOrderViewModel;
import StockTransferOrderUpdateWebRequest = Vertica.LouisPoulsen.Application.PointOfSale.StockTransferOrder.StockTransferOrderUpdateWebRequest;

class StockTransferOrderService {
    public async get(orderNumber: string): Promise<StockTransferOrderViewModel> {
        const response = await Http.get<StockTransferOrderViewModel>('stocktransferorder/get', { orderNumber });
        return response;
    }

    public async update(request: StockTransferOrderUpdateWebRequest): Promise<StockTransferOrderViewModel> {
        const response = await Http.post<StockTransferOrderViewModel>('stocktransferorder/update', request);
        return response;
    }
}

export default new StockTransferOrderService();
