
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { GdprFormAction, AppGetter } from '../../../store';
import $ from 'cash-dom';
import VueProgrammaticInvisibleGoogleRecaptcha from 'vue-programmatic-invisible-google-recaptcha';
import userAgent from '@/services/userAgent.service';

@Component({
    name: 'block-gdpr-form',
    components: {
        VueProgrammaticInvisibleGoogleRecaptcha
    }
})
export default class BlockGdprForm extends Vue {
    @AppGetter
    public recaptchaKey: string;

    @Prop()
    public model: GdprFormBlocToJsonkViewModel;

    @GdprFormAction
    public submitFormOptions: (request) => Promise<string>;

    public submittingFormData: boolean = false;
    public request: GdprFormBlockWebRequest = {
        country: '',
        relation: '',
        concern: '',
        firstName: '',
        lastName: '',
        company: '',
        email: '',
        phone: '',
        information: '',
        relationOther: '',
        recaptchaToken: ''
    };

    public _uid: any; // Vue provided uniq ID
    public componentId: string = 'gdprFormRecaptcha';
    public runAnimations = userAgent.isPrerender;

    initAnimations(): void {
        this.runAnimations = true;
    }

    public validateAndSubmitRequest() {
        this.$validator
            .validateAll()
            .then(isValid => {
                if (isValid) {
                    (this.$refs[this.componentId + this._uid] as any).execute();
                } else {
                    this.scrollInvalidElementIntoView();
                }
            })
            .catch(x => console.log('catch' + x));
    }

    public submitForm(recaptchaToken) {
        this.submittingFormData = true;
        this.request.recaptchaToken = recaptchaToken;
        this.submitFormOptions(this.request).then(
            () => {
                this.handleSuccess();
            },
            () => {
                this.submittingFormData = false;
            }
        );
    }

    private handleSuccess() {
        this.submittingFormData = false;
        if (this.model.confirmationLink) {
            this.$router.push({ path: this.model.confirmationLink });
        }
    }

    private scrollInvalidElementIntoView() {
        const firstInvalidElement = $('.is-danger').first()[0];
        if (firstInvalidElement) {
            firstInvalidElement.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' });
        }
    }
}
