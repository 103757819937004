import { render, staticRenderFns } from "./VideoPlayer.vue?vue&type=template&id=61f44d60&scoped=true&"
import script from "./VideoPlayer.vue?vue&type=script&lang=ts&"
export * from "./VideoPlayer.vue?vue&type=script&lang=ts&"
import style0 from "./VideoPlayer.vue?vue&type=style&index=0&id=61f44d60&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61f44d60",
  null
  
)

export default component.exports