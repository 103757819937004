import { ref } from 'vue';
import { useEventListener } from './event';

export function useMediaQuery(mediaQueryString: string) {
    const mediaQuery = window.matchMedia(mediaQueryString);
    const mediaQueryMatches = ref<boolean>(mediaQuery.matches);

    useEventListener<MediaQueryList, MediaQueryListEvent>(mediaQuery, 'change', (event: MediaQueryListEvent) => {
        mediaQueryMatches.value = event.matches;
    });

    return { mediaQueryMatches };
}
