import { GetterTree, MutationTree, ActionTree } from 'vuex';
import { namespace } from 'vuex-class';
import ProjectService from '../services/project.service';
import QueryParamService, { QueryParams } from '@/core/query-params.service';
import DictionaryService from '../core/dictionary.service';
import { router } from '../router';

function normalizeRequest(request: ProjectSearchWebRequest): ProjectSearchWebRequest {
    request.projectCategory = normalizeField(request.projectCategory);
    return request;
}

function normalizeField(field: string) {
    return field === DictionaryService.get('Pages.ProjectList.ShowAllOptionText') ? '' : field;
}

enum MutationMethod {
    toggleSearching = 'toggleSearching',
    setProjectSearchResult = 'setProjectSearchResult'
}
interface IProjectState {
    projectSearchResult: ProjectSearchResultViewModel;
    isSearching: boolean;
    hasSearched: boolean;
}
const storeState: IProjectState = {
    projectSearchResult: {
        filterOptions: {
            projectCategories: []
        },
        pages: 1,
        projects: []
    },
    isSearching: false,
    hasSearched: false
};

const getters: GetterTree<IProjectState, any> = {
    projects: state => state.projectSearchResult.projects,
    projectCategories: state => state.projectSearchResult.filterOptions.projectCategories,
    totalPages: state => state.projectSearchResult.pages,
    isSearching: state => state.isSearching,
    hasSearched: state => state.hasSearched
};

const actions: ActionTree<IProjectState, any> = {
    async search({ commit }, request: ProjectSearchWebRequest) {
        commit(MutationMethod.toggleSearching);
        const normalizedRequest = normalizeRequest(request);
        const page = (normalizedRequest.startPage + normalizedRequest.pages - 1).toString();
        const category = normalizedRequest.projectCategory;
        if (QueryParamService.getParam(QueryParams.ProjectSearchPage) !== page ||
            QueryParamService.getParam(QueryParams.ProjectSearchProjectCategory) !== category) {
            const query = QueryParamService.appendQueryParams([
                { name: QueryParams.ProjectSearchPage, value: page },
                { name: QueryParams.ProjectSearchProjectCategory, value: category }
            ]);
            router.replace({ query });
        }
        try {
            const result = await ProjectService.search({ ...normalizedRequest });
            commit(MutationMethod.setProjectSearchResult, result);
            commit(MutationMethod.toggleSearching);
            return result;
        } catch (error) {
            commit(MutationMethod.toggleSearching);
            return error;
        }
    }
};

const mutations: MutationTree<IProjectState> = {
    [MutationMethod.setProjectSearchResult](state, result: ProjectSearchResultViewModel) {
        state.projectSearchResult = result;
        state.hasSearched = true;
    },
    [MutationMethod.toggleSearching](state) {
        state.isSearching = !state.isSearching;
    }
};

export default {
    namespaced: true,
    state: storeState,
    getters,
    actions,
    mutations
};

const moduleName = 'project';
const moduleObject = namespace(moduleName);

export const ProjectGetter = moduleObject.Getter;
export const ProjectMutation = moduleObject.Mutation;
export const ProjectAction = moduleObject.Action;
export const ProjectState = moduleObject.State;
