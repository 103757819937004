
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
//import forEach from 'lodash-es/forEach';
​
​
@Component({
    name: 'script-embed',
})
export default class ScriptEmbed extends Vue {
​
    @Prop({type: String, required: true})
    private src!: string;
​
    @Prop({type: Function, required: false, default: () => {}})
    private onload!: (src:string) => void;
​
    private mounted() {
​
        let scripts: string[] = Array
            .from(document.querySelectorAll('script'))
            .map(script => script.src);
​
        if (!scripts.includes(this.src)) {
            this.scriptLoad(this.src, this.init);
        }
        else{
            this.init();
        }
​
    }
​
    private init():void{
        this.$emit('onload', this.src);
        this.onload(this.src);
    }
​
    private scriptLoad(src, callback) {
        let script:any = document.createElement('script'),
            loaded;
​
        script.setAttribute('src', src);
​
        if (callback) {
            script.onreadystatechange = script.onload = function() {
                if (!loaded) {
                    callback();
                }
                loaded = true;
            };
        }
        document.getElementsByTagName('head')[0].appendChild(script);
    }
​
}
