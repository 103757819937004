import Http from '@/core/http/http.service';
import IForgotPasswordForm from '@/ui/views/forgotpassword/forgotpasswordform.interface';
import ForgotPasswordWebRequest = Vertica.LouisPoulsen.Application.Authentication.Requests.ForgotPasswordWebRequest;
import RedirectViewModel = Vertica.LouisPoulsen.Application.Infrastructure.WebApi.Response.RedirectViewModel;

class ForgotPasswordService {
    public async forgotPassword(formData: IForgotPasswordForm): Promise<RedirectViewModel> {
        const { username } = formData;

        const request: ForgotPasswordWebRequest = {
            userName: username
        };

        return Http.post('authentication/password/forgot', request)
            .then((response: RedirectViewModel) => {
                return response;
            });
    }
}

export default new ForgotPasswordService();
