import { getCurrentInstance, onMounted, ref } from 'vue';

export function useRootElement() {
    const rootElement = ref<HTMLElement | null>();

    onMounted(() => {
        const instance = getCurrentInstance();
        if (instance && instance.proxy && instance.proxy.$el) {
            rootElement.value = instance.proxy.$el as HTMLElement;
        }
    });

    return {
        rootElement
    };
}
