
import Vue from 'vue';
import Component from 'vue-class-component';
import Http from '@/core/http/http.service';
    import InitializePaypalViewModel = Vertica.LouisPoulsen.Application.Commerce.ViewModels.InitializePaypalViewModel;
import { Prop, Watch } from 'vue-property-decorator';
    import OrderPaypalViewModel = Vertica.LouisPoulsen.Application.Commerce.ViewModels.OrderPaypalViewModel;
    import ValidatePaypalViewModel = Vertica.LouisPoulsen.Application.Commerce.ViewModels.ValidatePaypalViewModel;
import PageService from '@/core/page.service';
    import ValidatePaypalWebRequest = Vertica.LouisPoulsen.Application.Commerce.ViewModels.ValidatePaypalWebRequest;

    @Component({
        name: 'payment-provider-paypal',
        components: {}
    })
export default class PaymentProviderPaypal extends Vue {
    public isLoading: boolean = true;

    public paypalErrors: string[] = [];

    mounted() {
        Http.post('/paymentb2c/initializepaypal').then(async(response: InitializePaypalViewModel) => {
            await this.ensurePaypalScript(response);

            this.isLoading = false;

            this.processPayment();
        });
    }

    public processPayment() {
        const paypal = (window as any).paypal;

        paypal.Buttons({
            fundingSource: paypal.FUNDING.PAYPAL,
            style: {
                shape: 'rect',
                color: 'black',
                layout: 'vertical',
                label: 'paypal'
            },
            createOrder: async function(data, actions) {
                const orderResponse = await Http.post<OrderPaypalViewModel>('/paymentb2c/getorderdatapaypal');
                return actions.order.create(orderResponse);
            },
            onApprove: async function(data, actions) {
                // Authorize the order explicitly here
                await actions.order.authorize();

                const request: ValidatePaypalWebRequest = { orderId: data.orderID };
                const response = await Http.post<ValidatePaypalViewModel>('/paymentb2c/validatepaypal', request);
                if (response.success) {
                    // We use hardRedirect here to be able to track the "transactionComplete" event.
                    // CookieGuardedMessage that Vertica.AnalyticsTracker uses doesn't work with our SPA redirect.
                    // We should fix it in Vertica.AnalyticsTracker, but for now we just use a hard redirect.
                    PageService.hardRedirect(response.redirectUrl);
                }
            }
        }).render('#paypal-button-container');
    }

    private async ensurePaypalScript(response: InitializePaypalViewModel) {
        return new Promise<void>((resolve, reject) => {
            const paypalScript = 'paypal-script';

            if (!document.getElementById(paypalScript)) {
                const script = document.createElement('script');
                script.id = paypalScript;
                script.src = `https://www.paypal.com/sdk/js?intent=authorize&client-id=${response.clientId}&currency=${response.currency}&locale=${response.locale}`;
                script.async = true;
                script.onload = () => resolve();
                document.body.appendChild(script);
            } else {
                resolve();
            }
        });
    }
}
