

import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { AppGetter, CatalogGetter } from '@/store';
import Http from '@/core/http/http.service';
import TrackingService from '@/core/tracking.service';
import SelectCtrlMultiple from '@/ui/shared/form/SelectCtrlMultiple.vue';

export enum WebRequestEnum {
    VariantFiles = 'VariantFiles',
    VariantFilesByType = 'VariantFilesByType',
    ProductFiles = 'ProductFiles',
}

interface selectedFilesGroupsViewModel {
    [key: string]: any[]

}
interface IDownloadFormItem {
    id: string;
    type: string;
    displayName?: string;
}

@Component({
    name: 'download-selection',
    components: {
        SelectCtrlMultiple
    }
})
export default class DownloadSelection extends Vue {
    @AppGetter
    public selectedLanguage: any;

    @AppGetter theme: ColorTheme;

    @AppGetter
    public borderColor: (opacity: number) => string;

    @CatalogGetter
    public product: ProductBaseViewModel<VariantBaseViewObject>;

    @CatalogGetter
    public selectedConfigVariant: ProfessionalVariantViewObject;

    @CatalogGetter
    public gaTrackingGoal: string;

    @Prop({ type: Boolean, default: false })
    public variantMode: boolean;

    @Prop()
    public modalMode: boolean;

    @Prop({ type: Array, default: () => [] })
    public itemTypeFilter: string[];

    public downloadAllArray: IDownloadFormItem[] = [];
    public dataLoaded: boolean = false;
    public downloadsList: DownloadFileTypeViewObject[] = [];
    public singleChoiceGroups: { [type: string]: boolean } = {};

    private downloadsListSorted : {
        multipleChoices: {item: DownloadFileTypeViewObject, selected: string[]}[],
        singleChoices: {item: DownloadFileTypeViewObject, selected: boolean}[]} = {
            multipleChoices: [],
            singleChoices: []
        };

    get selectedFilesGroups() : selectedFilesGroupsViewModel {
        const result = {};
        if (!this.downloadsListSorted) {
            return {};
        }
        if (this.downloadsListSorted.multipleChoices) {
            this.downloadsListSorted.multipleChoices.forEach(option => { result[option.item.type] = option.selected; });
        }
        if (this.downloadsListSorted.singleChoices) {
            this.downloadsListSorted.singleChoices.forEach(option => {
                const type = option.item.type;
                result[type] = option.selected ? [{ type, id: option.item.files[0].id }] : [];
            });
        }
        return result;
    }

    get fileGroupsWithSelections(): string[] {
        return Object.keys(this.selectedFilesGroups).filter(key => this.selectedFilesGroups[key].length >= 1);
    }

    get totalNumberOfSelectedFiles(): any {
        return [].concat(...this.downloadsList.map(downloadType => this.selectedFilesGroups[downloadType.type])).length;
    }

    created() {
        this.initDownloadListData();
    }

    sortDownloadsListForView() {
        const multipleChoices = [];
        const singleChoices = [];
        for (const item of this.downloadsList) {
            if (item.files.length > 1) {
                multipleChoices.push({ item, selected: [] });
            } else {
                singleChoices.push({ item, selected: false });
            }
        }
        this.downloadsListSorted = { multipleChoices, singleChoices };
    }

    private createDownloadAllFilesRequest() {
        this.downloadsList.forEach((option: DownloadFileTypeViewObject) => {
            option.files.forEach((file: FileViewObject) => {
                const item: IDownloadFormItem = {
                    type: option.type,
                    id: file.id
                };
                this.downloadAllArray.push(item);
            });
        });
    }

    private initDownloadListData() {
        let requestParams: any;
        let requestUrl: string;

        if (this.variantMode) {
            requestUrl = WebRequestEnum.VariantFiles;
            (requestParams as Vertica.LouisPoulsen.Application.Content.Api.VariantFilesWebRequest) = {
                variantId: this.selectedConfigVariant.id
            };
        } else {
            requestUrl = WebRequestEnum.ProductFiles;
            (requestParams as Vertica.LouisPoulsen.Application.Content.Api.ProductFilesWebRequest) = {
                productId: this.product.id
            };
        }

        Http.get(`/downloadcenter/${requestUrl}`, requestParams).then((data: Vertica.LouisPoulsen.Application.Content.Pages.Product.Download.DownloadFilesViewModel) => {
            // Check if any filters, else just use all of data model
            if (this.itemTypeFilter.length > 0) {
                this.itemTypeFilter.forEach((filterItem) => {
                    const item = data.items.find(x => x.type === filterItem);
                    if (item) {
                        this.downloadsList.push(item);
                    }
                });
            } else {
                this.downloadsList = data.items;
            }
            this.createDownloadAllFilesRequest();
            this.dataLoaded = true;
            this.sortDownloadsListForView();
        });
    }

    get formSubmitUrl(): string {
        return encodeURI(`${this.baseUrl()}MultiDownload?overridelanguage=` + this.selectedLanguage.code);
    }

    private baseUrl(): string {
        return '/api/downloadcenter/';
    }

    private downloadSelectedButtonClicked() {
        TrackingService.event('Ecommerce', this.gaTrackingGoal, 'Download: ' + this.fileGroupsWithSelections.join('|'));
    }

    private downloadAllButtonClicked() {
        const distinctTypes = Array.from(new Set(this.downloadAllArray.map((item: IDownloadFormItem) => item.type)));
        TrackingService.event('Ecommerce', this.gaTrackingGoal, 'Download: ' + distinctTypes.join('|'));
    }
}
