class CookieService {

    public readonly APP_DATA = "lp-app-data";

    set(key: string, val: string) {
        const date = new Date();
        const forever = date.getTime() + 1000 * 24 * 60 * 60 * 1000;
        date.setTime(forever);
        const expires = "expires=" + date.toUTCString();
        document.cookie = key + "=" + val + ";path=/;" + expires;
    }

    has(key: string): boolean {
        const entry = this.get(key);
        return entry !== undefined;
    }

    get(key: string): string | undefined {
        const nameEQ = key + "=";
        const ca = document.cookie.split(";");
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === " ") {
                c = c.substring(1, c.length);
            }
            if (c.indexOf(nameEQ) === 0) {
                return c.substring(nameEQ.length, c.length);
            }
        }
        return undefined;
    }
}

export default new CookieService();
