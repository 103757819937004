class ImageUploadService {
    mapForUpload(file: File, content: string | ArrayBuffer) : CarouselImageWithExtension {
        const title = file.name;
        return {
            title,
            buffer: content,
            id: null,
            altText: title,
            extension: title.substring(title.lastIndexOf('.') + 1),
            link: null,
            imageUrl: null
        };
    }

    mapUploadedImageForUpload(image: CarouselImageViewObject) : CarouselImageWithExtension {
        return {
            buffer: image.url,
            extension: image.extension,
            title: image.title,
            id: image.id,
            imageUrl: null,
            altText: image.title,
            link: null
        };
    }

    mapUploadedImageForView(image: CarouselImageViewObject) : CarouselImageWithExtension {
        return {
            buffer: '',
            extension: image.extension,
            title: image.title,
            id: image.id,
            imageUrl: image.url,
            altText: image.title,
            link: null
        };
    }

    isPdf(image: CarouselImageWithExtension) : boolean { return image.extension.toLowerCase() === 'pdf'; }
}

export default new ImageUploadService();
