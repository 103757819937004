export type SortingDirection = 'asc' | 'desc';

export interface ISorting {
    sortBy: string;
    direction: SortingDirection;
}

export const enum SalesDocumentMobileOverlayType {
    order = 0,
    invoice = 1,
    quote = 2
}

export class SalesDocumentTableConfig {
    // Config for each column
    // eslint-disable-next-line no-use-before-define
    public columns: SalesDocumentTableColumn[];

    // Property on data that is unique for each row.
    public rowKey?: string;

    // Sets the default sorting to match the backend data
    public defaultSort?: ISorting;

    // Which fields be used when filtering
    public filterFields?: string[];

    // Property to use for the sub table
    public subTableField?: string;

    // Config used for the sub table
    // eslint-disable-next-line no-use-before-define
    public subTableConfig?: SalesDocumentSubTableConfig;
}

export class SalesDocumentSubTableConfig extends SalesDocumentTableConfig {
    // Property on data that determines overlay template logic.
    public mobileOverlayType?: SalesDocumentMobileOverlayType;
}

export class SalesDocumentTableColumn {
    // Label string to show in the table header
    public headerLabel?: string;

    // Property to use for sorting - if omitted sorting is disabled
    public sortField?: string;

    // Property to use in cell
    public valueField?: string;

    // Property to wrap value in certain classes
    public valueClass?: string;

    // Property to use to show image
    public imageField?: string;

    // Property to use to show image
    public imagesField?: string;

    // If imageField is set - this decides the image size
    public imageSize?: number;

    // Width class used for both header and each cell
    public widthClass?: string;

    // Decides whether the cell should link or not
    public linkField?: string;

    // Sets the target of the link
    public linkTarget?: string;

    // If this field is empty the cell content will be hidden
    public hideContentField?: string;

    // If this field is empty the cell will show this value
    public hiddenContentReplacement?: string;

    // Name of Vue component to insert in each cell
    public component?: string;

    // Used to show SalesInfo - references variant id field
    public salesInfoField?: string;
}
