import {GATrackingEventName} from "@/core/tracking.service";
import TrackingService from '@/core/tracking.service';

class SearchTrackingService {

    private selectedSearchSuggest:string = '';
    private trackSearchInputTiming: number = 500;
    private trackSearchInputTimer: number | null = null;

    public trackSelectedSearch(term:string):void{
        if(this.selectedSearchSuggest && this.selectedSearchSuggest.length > 0){

            if(this.trackSearchInputTimer){
                this.clearTrackSearchInputTimeout();
                this.trackSearchInputTimeout(term);
            }

            TrackingService.pushToDataLayer({
                'event': GATrackingEventName.searchSuggestSelect,
                'searchClick': this.selectedSearchSuggest
            });
        }
    }

    public trackSearchInput(term:string):void{
        this.setSelectedSearchSuggest('None');
        if(term && term.length > 0){
            this.clearTrackSearchInputTimeout();
            this.trackSearchInputTimer = setTimeout(()=>{
                this.trackSearchInputTimeout(term)
            }, this.trackSearchInputTiming);
        }
    }

    public setSelectedSearchSuggest(term:string):void{
        this.selectedSearchSuggest = term;
    }

    private trackSearchInputTimeout(term:string):void{
        TrackingService.pushToDataLayer({
            'event': GATrackingEventName.searchSuggestType,
            'searchTerm': term
        });
        this.clearTrackSearchInputTimeout();
    }

    private clearTrackSearchInputTimeout():void{
        if(this.trackSearchInputTimer){
            clearTimeout(this.trackSearchInputTimer)
            this.trackSearchInputTimer = null;
        }
    }

}

export default new SearchTrackingService();
