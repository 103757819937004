import Vue from 'vue';
import VueRouter, { Route } from 'vue-router';
import bus from '../core/bus';
import $ from 'cash-dom';
import { Position } from 'vue-router/types/router';
import { ensure as pageReadyForScroll, cancel as cancelPendingWaiting } from '../core/ensure';
import QueryParamService, { QueryParams } from '@/core/query-params.service';
import ScrollService from '@/core/scroll.service';

const { replace } = VueRouter.prototype;
const { isNavigationFailure, NavigationFailureType } = VueRouter;
// Ignore NavigationDuplicated errors for now
VueRouter.prototype.replace = function(location) {
    return replace.call(this, location).catch(error => {
        if (!isNavigationFailure(error, NavigationFailureType.duplicated)) {
            throw Error(error);
        }
    });
};

Vue.use(VueRouter);
preventAutoScroll();

export const SpaPageReadyEventKey = 'SpaPageReady';
export const SpaScrollContentAttrSelector = '[data-content-scroll-container]';

export const router = new VueRouter({
    scrollBehavior(to: Route, from: Route, savedPosition: Position | void) {
        // Make sure an unfinished waiting to scroll is canceled first.
        cancelPendingWaiting();
        if (to.path === from.path) {
            // Only query or params changed - don't scroll
            return null;
        }

        return new Promise((resolve, reject) => {
            bus.once(SpaPageReadyEventKey, async() => {
                // TEMP: Support for existing scrollToId query urls
                // TODO: Refactor to hash links for the router so resolve position - ie.
                /// da-dk/catalog/private/table/vl38-table?v=91681-5744163211-01#spareparts

                const scrollToId = QueryParamService.getParam(QueryParams.ScrollToId);
                if (scrollToId) {
                    const waitForDomToRender = 500;
                    setTimeout(() => {
                        const elementToScrollTo = document.getElementById(scrollToId);
                        ScrollService.scrollToElement(elementToScrollTo, 0, 300);
                    }, waitForDomToRender);
                    resolve({ x: 0, y: 0 });
                    return;
                } else if (to.hash) {
                    resolve({ selector: to.hash });
                    return;
                } else if (!savedPosition) {
                    // New page with no hash - scroll to top
                    resolve({ x: 0, y: 0 });
                    return;
                }

                // We have a saved position. New SPA page is now loaded but async elements may not be there yet, so page could be too short
                try {
                    const position = await pageReadyForScroll<Position | void>(() => actualDocumentHeight() >= (savedPosition as Position).y + window.innerHeight, savedPosition);
                    resolve(position);
                } catch (e) {
                    // Timeout or cancelled - dont scroll
                    resolve();
                }
            });
        });
    },
    mode: 'history'
});

// Get call stack for route change for debugging existing logic
// router.beforeEach((to, from, next) => {
//     debugger;
//     next();
// });

function actualDocumentHeight(): number {
    const bodyHeight = document.body.clientHeight;
    const scrollContainerHeight = $(SpaScrollContentAttrSelector).innerHeight();
    return Math.max(bodyHeight, scrollContainerHeight);
}

function preventAutoScroll() {
    if ('scrollRestoration' in history) {
        try {
            history.scrollRestoration = 'manual';
        } catch (e) {
            // Ignore
        }
    }
}
