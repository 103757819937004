
import { Component, Prop } from 'vue-property-decorator';
import BlockLedCalculatorFixture from '@/ui/shared/blocks/LedCalculator/BlockLedCalculatorFixture.vue';
import { TranslateMixin } from '@/core/translate.mixin';
import { Mixins } from 'vue-mixin-decorator';
import {fixtureDetailsDataModel, selectedFixturesModel} from '@/ui/shared/blocks/LedCalculator/LedCalculatorTypes';
import { ledCalculatorController } from '@/services';
import LedCalculatorFixtureViewObject = Vertica.LouisPoulsen.Application.LedCalculator.ViewModels.LedCalculatorFixtureViewObject;
import LedCalculatorUpgradeKitViewObject = Vertica.LouisPoulsen.Application.LedCalculator.ViewModels.LedCalculatorUpgradeKitViewObject;

@Component({
    components: {
        BlockLedCalculatorFixture
    }
})
export default class BlockLedCalculatorSelectFixtures extends Mixins<TranslateMixin>(TranslateMixin) {
    @Prop({
        type: Number,
        required: true
    }) numberOfFixtures: number;

    @Prop({
        type: Number,
        required: true
    }) calculationPeriod: number;

    @Prop({
        type: Number,
        required: true
    }) annualOperatingHours: number;

    @Prop({
        type: Number,
        required: true
    }) annualOperatingHoursNight: number;

    @Prop({
        type: [Object, null],
        default: null,
        required: false
    }) existingFixtureInfo: fixtureDetailsDataModel | null;

    @Prop({
        type: [Object, null],
        default: null,
        required: false
    }) upgradeFixtureInfo: fixtureDetailsDataModel | null;

    @Prop({
        type: Boolean,
        required: true
    }) isDirty: boolean;

    existingFixtureOptions: LedCalculatorFixtureViewObject[] | null = null;

    upgradeFixtureOptions: LedCalculatorUpgradeKitViewObject[] | null = null;

    selectedExistingFixture: LedCalculatorFixtureViewObject | null = null;
    selectedLedFixture: LedCalculatorUpgradeKitViewObject | null = null;

    get numberOfFixturesString(): string {
        return this.numberOfFixtures.toString();
    }

    get calculationPeriodString(): string {
        return this.calculationPeriod.toString();
    }

    get existingFixtureKwhDescription(): string {
        return this.t('Shared.LedCalculator.ExistingFixture.KwhDescription', [this.numberOfFixturesString, this.calculationPeriodString]);
    }

    get existingFixturePriceDescription(): string {
        return this.t('Shared.LedCalculator.ExistingFixture.PriceDescription', [this.numberOfFixturesString, this.calculationPeriodString]);
    }

    get upgradeFixtureKwhDescription(): string {
        return this.t('Shared.LedCalculator.UpgradedFixture.KwhDescription', [this.numberOfFixturesString, this.calculationPeriodString]);
    }

    get upgradeFixturePriceDescription(): string {
        return this.t('Shared.LedCalculator.UpgradedFixture.PriceDescription', [this.numberOfFixturesString, this.calculationPeriodString]);
    }

    get ledOperatingTimeNormal(): string {
        return (this.annualOperatingHours - this.annualOperatingHoursNight).toString()
    }

    get selectedFixtures(): selectedFixturesModel {
        return {
            existingFixture: this.selectedExistingFixture,
            ledFixture: this.selectedLedFixture
        }
    };

    async created(): Promise<void> {
        this.existingFixtureOptions = await ledCalculatorController.getFixtures().then(res => res.fixtures);
    }

    onExistingFixtureChange(selectedExistingFixture: LedCalculatorFixtureViewObject): void {
        this.selectedExistingFixture = selectedExistingFixture;
        ledCalculatorController.getLedUpgradesForFixture(selectedExistingFixture.id).then(res => {
            this.$emit('replacementCostUpdate', res.listPrice);
            this.upgradeFixtureOptions = res.upgradeKits;
        });
    }
    onUpgradedFixtureChange(selectedLedFixture: LedCalculatorUpgradeKitViewObject): void {
        this.selectedLedFixture = selectedLedFixture;
        this.$emit('fixturesSelected', this.selectedFixtures);
    }
}
