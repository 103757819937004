import { ActionTree } from "vuex";
import { namespace } from "vuex-class";
import ReturnformService from "../services/returnform.service";


const actions: ActionTree<any, any> = {
    async submitFormOptions({commit}, request: ReturnProductPageWebRequest ) {
        return await ReturnformService.submitFormOptions(request);
    }
};


export default {
    namespaced: true,
    actions,
};

const moduleName = "returnform";
const moduleObject = namespace(moduleName);

export const ReturnFormAction = moduleObject.Action;
