
import * as CSS from 'csstype';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import ImageInfoPointPopup from './ImageInfoPointPopup.vue';
import { AppGetter } from '../../store';

@Component({
    name: 'image-info-point',
    components: {
        ImageInfoPointPopup,
    },
})
export default class ImageInfoPoint extends Vue {
    @Prop({ required: true })
    public id: number;

    @Prop({ required: true })
    public top: number;

    @Prop({ required: true })
    public left: number;

    @Prop({ required: true })
    public headline: string;

    @Prop({ default: false })
    public isOpen: boolean;

    @Prop({ default: 'bottom' })
    public infoBoxPosition: string;

    @Prop() public body: string;
    @Prop() public buttonText: string;
    @Prop() public buttonLink: string;
    @Prop() public products: IImageInfoPointProduct[];

    get pointStyle() {
        const styles: CSS.Properties = {
            left: `${this.left}%`,
            top: `${this.top}%`,
        };
        return styles;
    }
    get infoBoxLineStyle() {
        const styles: CSS.Properties = {
            left: `${this.left}%`,
            top: `${this.top}%`,
            transitionDelay: this.isOpen ? '0ms' : '300ms',
        };
        return styles;
    }
    get infoBoxStyle() {
        const margin = '10px';
        const originX = this.left <= 50 ? 'left' : 'right';
        const originY = this.top <= 50 ? 'top' : 'bottom';
        const left = this.left <= 50 ? `${this.left}%` : margin;
        const right = this.left <= 50 ? margin : `${100 - this.left}%`;
        const top = this.top <= 50 ? `${this.top}%` : margin;
        const bottom = this.top <= 50 ? margin : `${100 - this.top}%`;

        const styles: CSS.Properties = {
            opacity: this.isOpen ? 1 : 0,
            transform: this.isOpen ? 'scale(1)' : 'scale(0)',
            transformOrigin: `${originY} ${originX}`,
            left: left,
            right: right,
            top: top,
            bottom: bottom,
        };
        return styles;
    }
}
