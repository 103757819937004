
import Vue from 'vue';
import Component from 'vue-class-component';
import { Mixins } from 'vue-mixin-decorator';
import { BreakpointsMixin } from '../../../core/responsive/breakpoints/breakpoint.mixin';
import { CatalogGetter } from '../../../store';
import DistributionDiagramImage from './DistributionDiagramImage.vue';

@Component({
    name: 'product-light-distribution',
    components: {
        DistributionDiagramImage,
    },
})
export default class ProductLightDistribution extends Mixins<BreakpointsMixin>(BreakpointsMixin) {
    @CatalogGetter
    public selectedConfigVariant: ProfessionalVariantViewObject;

    public diagramTitles = [];

    mounted() {
        var diagrams = this.selectedConfigVariant.lightDistributionDiagrams;

        if (diagrams.some(x => x.polarImageUrl !== null)) {
            this.diagramTitles.push('Pages.ProductDetail.LightDistribution.PolarTitleText');
        }
        if (diagrams.some(x => x.beamImageUrl !== null)) {
            this.diagramTitles.push('Pages.ProductDetail.LightDistribution.CartesianTitleText');
        }
        if (diagrams.some(x => x.isoluxImageUrl !== null)) {
            this.diagramTitles.push('Pages.ProductDetail.LightDistribution.IsoluxTitleText');
        }
        if (diagrams.some(x => x.bugImageUrl !== null)) {
            this.diagramTitles.push('Pages.ProductDetail.LightDistribution.BugTitleText');
        }
    }

    get containerStyles() {
        let width = '100%';
        if (this.breakpointIsActive['small']) {
            width = '210vw';
        }
        if (this.breakpointIsActive['medium']) {
            width = '120vw';
        }
        return { width };
    }

    get itemStyles() {
        let width = '33%';
        if (this.breakpointIsActive['small']) {
            width = '70vw';
        }
        if (this.breakpointIsActive['medium']) {
            width = '40vw';
        }
        return { width };
    }
}
