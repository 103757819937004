import Http from "../core/http/http.service";


class WarrantyformService {
    submitFormOptions(request: WarrantyFormPageWebRequest ): Promise<WarrantyFormPageWebRequest > {
        return Http.post<WarrantyFormPageWebRequest >("warrantyformpage/sendwarrantyform", request);
    }
}

export default new WarrantyformService();

