import Vue from "vue";

Vue.directive("onerror", {
    inserted(el) {
        el.addEventListener("error", () => {
            const dummyImage = "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==";
            el.removeAttribute("src-set");
            el.classList.add("on-error");
            el.setAttribute("src", dummyImage);
            console.log("HANDLED ON ERROR");
        });
    }
});
