import HrefLanguage = Vertica.LouisPoulsen.Application.Mvc.Spa.ViewModels.HrefLanguage;
import { forEach } from 'lodash';
class DomService {
    private static URL = 'data-page-meta-url';
    private static TITLE = 'data-page-meta-title';
    private static KEYWORDS = 'data-page-meta-keywords';
    private static DESCRIPTION = 'data-page-meta-description';
    private static HREFLANG = 'data-page-meta-hreflang';

    public toggleElementClass(selector: string, targetClass: string) {
        const element = document.querySelector(selector);
        if (!element) {
            return;
        }
        const hasClass = element.classList.contains(targetClass);
        hasClass
            ? element.classList.remove(targetClass)
            : element.classList.add(targetClass);
    }

    public hasElementClass(selector: string, targetClass: string) {
        const element = document.querySelector(selector);
        if (!element) {
            return false;
        }
        return element.classList.contains(targetClass);
    }

    public addElementClass(selector: string, targetClass: string) {
        const element = document.querySelector(selector);
        if (!element) {
            return;
        }
        element.classList.add(targetClass);
    }

    public removeElementClass(selector: string, targetClass: string) {
        const element = document.querySelector(selector);
        if (!element) {
            return;
        }
        element.classList.remove(targetClass);
    }

    public updateMetaData(metaData: Metadata) {
        this.setCanonicalUrl(metaData.url);
        this.setPageTitle(metaData.title);
        this.setMetaDescription(metaData.description);
        this.setMetaKeywords(metaData.keywords);
        this.setMetaHrefLang(metaData.hrefLanguages);
    }

    public setPageTitle(title: string): void {
        document.title = this.sanitizeInput(title);
        this.setContent(DomService.TITLE, title);
    }

    public setMetaDescription(description: string): void {
        this.setContent(DomService.DESCRIPTION, description);
    }

    public setMetaKeywords(keywords: string): void {
        this.setContent(DomService.KEYWORDS, keywords);
    }

    public setMetaHrefLang(hrefLanguages: HrefLanguage[]): void {
        const elements = this.getLinkElements('alternate');
        this.removeElementsFromDOM(elements);
        const headElement = this.getHeadElement();
        if (headElement) {
            hrefLanguages.forEach(item => {
                const element: HTMLLinkElement = document.createElement('link');
                element.rel = 'alternate';
                element.hreflang = item.language;
                element.href = item.url;
                headElement.appendChild(element);
            });
        }
    }

    private removeElementsFromDOM(elements) {
        forEach(elements, element => {
            element.parentNode.removeChild(element);
        });
    }

    public setCanonicalUrl(canonicalUrl: string): void {
        const canonicalUrlElement = this.getCanonicalUrlElement();
        canonicalUrlElement.href = this.sanitizeInput(canonicalUrl);
        this.setContent(DomService.URL, this.sanitizeInput(canonicalUrl));
    }

    private setContent(attributeName: string, content: string) {
        const elements = this.getMetaElements(attributeName);
        for (let i = 0; i < elements.length; i++) {
            const element = elements[i];
            element.content = this.sanitizeInput(content);
        }
    }

    private sanitizeInput(input: string): string {
        return input || '';
    }

    private getCanonicalUrlElement(): HTMLLinkElement {
        return this.getOrCreate<HTMLLinkElement>('link[rel=canonical]', () => {
            const element = document.createElement('link');
            element.rel = 'canonical';
            const headElement = this.getHeadElement();
            if (headElement) {
                headElement.appendChild(element);
            }
            return element;
        });
    }

    private getHeadElement(): HTMLHeadElement | null {
        return document.querySelector('head');
    }

    private getMetaElements(attributeName: string): NodeListOf<HTMLMetaElement> {
        const elements = document.querySelectorAll<HTMLMetaElement>('meta[' + attributeName + ']');
        return elements;
    }

    private getLinkElements(relValue): NodeListOf<HTMLLinkElement> {
        const elements = document.querySelectorAll<HTMLLinkElement>('link[rel=\'' + relValue + '\']');
        return elements;
    }

    private getOrCreate<TElement extends HTMLElement>(selector: string, create?: () => TElement): TElement {
        const element = document.querySelector(selector) as TElement;
        if (element) {
            return element;
        }
        if (create) {
            return create();
        }
    }
}

export enum BodyDomClasses {
    pdpActive = 'pdp-professional--active'
}

export default new DomService();
