/**
 * This is a standard slide load manager intended for simple use cases.
 *
 * Note that the logic is optimized for the simple case, where only one slide is visible at a time
 * and there is no paginator, that would allow the user to jump to any slide at any time (which would
 * probably require a bit more aggression in the preloading).
 *
 */
import { SwiperManager } from '..';
import { useCustomLoadManager, DesicionContext, SlideRenderState } from './custom';
import { useSaveData } from '@/ui/composables';

export const useStandardLoadManager = (swiperManager: SwiperManager) => {
    const saveData = useSaveData();

    const decisionLogic = (index: number, context: DesicionContext): SlideRenderState => {
        const { activeIndex, isAnimating, hasHovered, hasInteracted, timerStatuses } = context;

        if (index === activeIndex) {
            return SlideRenderState.Full;
        }

        const getIndexAtOffset = (offset: number): number | undefined => {
            if (swiperManager.swiperOptions.loop) {
                return (activeIndex + offset + swiperManager.slides.value.length) % swiperManager.slides.value.length;
            } else {
                const newIndex = activeIndex + offset;
                if (newIndex < 0 || newIndex >= swiperManager.slides.value.length) {
                    return undefined;
                }
                return newIndex;
            }
        };

        const [timerOneCompleted, timerTwoCompleted] = timerStatuses;
        const hasInteractedSomehow = (hasHovered || hasInteracted);

        const nextSlide = getIndexAtOffset(1);
        const prevSlide = getIndexAtOffset(-1);
        if ((index === nextSlide) || (index === prevSlide)) {
            if (timerOneCompleted || hasInteractedSomehow) return SlideRenderState.VisuallyReady;
        }

        if (isAnimating) return SlideRenderState.None; // don't preload other than prev/next while sliding, it hurts performance
        if (saveData) return SlideRenderState.None; // don't preload if user has save data mode enabled

        const nextNextSlide = getIndexAtOffset(2);
        if (index === nextNextSlide) {
            if (hasInteractedSomehow) {
                return SlideRenderState.VisuallyReady;
            }
            if (timerOneCompleted) {
                return SlideRenderState.Prefetch;
            }
        }

        if (timerTwoCompleted && hasInteracted) {
            return SlideRenderState.Prefetch;
        }

        return SlideRenderState.None;
    };

    const timers = [
        { delay: 500, random: 200 }, // When to load prev and next, and prefetch next-next
        { delay: 2000, random: 500 } // When to prefetch slide resources for all slides (provided the user has interacted)
    ];

    return useCustomLoadManager(swiperManager, decisionLogic, timers);
};
