import Http from '../../../core/http/http.service';
import {
    SalesDocumentMobileOverlayType,
    SalesDocumentTableConfig
} from '@/ui/views/salesdocumenttable/salesdocument.types';

class InvoiceService {
    public async invoices(request: InvoiceSearchWebRequest) : Promise<InvoiceSearchViewModel> {
        const url = 'invoice/search';

        return Http.cancelableRequest('POST', url, 'invoiceRequest', request)
            .then((response: InvoiceSearchViewModel) => {
                return response;
            });
    }

    public tableConfig(allowSortingAndFiltering:boolean = true): SalesDocumentTableConfig{
        return {
            rowKey: 'invoiceNo',
            columns:[
                {
                    headerLabel: 'Pages.PartnerPortal.Invoices.InvoicePage.InvoiceTableInvoiceNo',
                    widthClass: 'sub-md:u-w-6/24 u-w-2/24 u-mr-6',
                    ...(allowSortingAndFiltering && {sortField: 'invoiceNo'}),
                    sortField: 'invoiceNo',
                    valueField: 'invoiceNo',
                    valueClass: 'table-bold'
                },
                {
                    headerLabel: '',
                    widthClass: 'sub-md:u-hidden u-w-1/24 u-pr-2',
                    component: 'icon-pdf',
                    linkField: 'invoicePdfLink',
                    linkTarget: '_blank',
                    hideContentField: 'invoicePdfLink',
                    hiddenContentReplacement: 'N/A'
                },
                {
                    headerLabel: 'Pages.PartnerPortal.Invoices.InvoicePage.InvoiceTableReference',
                    widthClass: 'u-flex-1 u-pr-2',
                    ...(allowSortingAndFiltering && {sortField: 'reference'}),
                    valueField: 'reference',
                },
                {
                    headerLabel: 'Pages.PartnerPortal.Invoices.InvoicePage.InvoiceTableCreationDate',
                    widthClass: 'sub-md:u-hidden u-w-4/24 u-pr-2',
                    ...(allowSortingAndFiltering && {sortField: 'creationDateSortable'}),
                    valueField: 'creationDate',
                },
                {
                    headerLabel: 'Pages.PartnerPortal.Invoices.InvoicePage.InvoiceTableNetValue',
                    widthClass: 'sub-md:u-hidden u-w-4/24 u-pr-2',
                    ...(allowSortingAndFiltering && {sortField: 'netValueSortable'}),
                    valueField: 'netValue',
                },
                {
                    headerLabel: 'Pages.PartnerPortal.Invoices.InvoicePage.InvoiceTableOrder',
                    widthClass: 'sub-md:u-hidden u-w-3/24 u-pr-2',
                    ...(allowSortingAndFiltering && {sortField: 'orderNo'}),
                    valueField: 'orderNo',
                    linkField: 'orderLink'
                }
            ],
            defaultSort: {
                sortBy: 'creationDateSortable',
                direction: 'desc'
            },

            filterFields: allowSortingAndFiltering ? [
                'invoiceNo',
                'reference',
                'creationDate',
                'netValue',
                'orderNo',
                'customerName',
                'lines.variantId',
                'lines.description',
                'lines.netValue',
                'lines.orderNumber',
                'lines.shipping'
            ] : [],

            subTableField: 'lines',
            subTableConfig: {
                rowKey: 'position',
                mobileOverlayType: SalesDocumentMobileOverlayType.invoice,
                columns: [
                    {
                        headerLabel: 'Pages.PartnerPortal.Invoices.InvoicePage.InvoiceTableLinePos',
                        widthClass: 'md:u-w-1/24',
                        valueField: 'position',
                    },
                    {
                        headerLabel: '',
                        widthClass: 'md:u-w-70p md:u-mr-5',
                        imageField: 'imageUrl',
                        imagesField: 'images',
                        imageSize: 70
                    },
                    {
                        headerLabel: 'Pages.PartnerPortal.Invoices.InvoicePage.InvoiceTableLineMaterial',
                        widthClass: 'md:u-w-3/24 u-pr-2',
                        valueField: 'variantId',
                        linkField: 'pdpLink',
                        salesInfoField: 'variantId'
                    },
                    {
                        headerLabel: 'Pages.PartnerPortal.Invoices.InvoicePage.InvoiceTableLineDescription',
                        widthClass: 'md:u-flex-1 u-pr-2',
                        valueField: 'description',
                        valueClass: 'u-capitalize',
                        linkField: 'pdpLink'
                    },
                    {
                        headerLabel: 'Pages.PartnerPortal.Invoices.InvoicePage.InvoiceTableLineQuantity',
                        widthClass: 'md:u-w-1/24 u-pr-2',
                        valueField: 'quantity',
                        valueClass: 'u-inline-block u-bg-black u-px-2 u-rounded-full u-text-white u-text-small u-leading-body'
                    },
                    {
                        headerLabel: 'Pages.PartnerPortal.Invoices.InvoicePage.InvoiceTableLineNetValue',
                        widthClass: 'md:u-w-3/24 u-pr-2',
                        valueField: 'netValue',
                    },
                    {
                        headerLabel: 'Pages.PartnerPortal.Invoices.InvoicePage.InvoiceTableLineOrder',
                        widthClass: 'md:u-w-2/24 u-pr-2',
                        valueField: 'orderNumber',
                        linkField: 'orderUrl'
                    },
                    {
                        headerLabel: 'Pages.PartnerPortal.Invoices.InvoicePage.InvoiceTableLineShipping',
                        widthClass: 'md:u-w-3/24 u-pr-2',
                        valueField: 'shipping'
                    },
                    {
                        headerLabel: 'Pages.PartnerPortal.Invoices.InvoicePage.InvoiceTableLineTrace',
                        widthClass: 'md:u-w-1/24',
                        component: 'icon-trace',
                        linkField: 'traceUrl',
                        linkTarget: '_blank',
                        hideContentField: 'traceUrl',
                        hiddenContentReplacement: 'N/A'
                    }
                ]
            }
        }
    }
}

export const invoiceService = new InvoiceService();
