import { ref, watch, Ref } from 'vue';
import { SwiperManager } from '..';

export enum SlideRenderState {
    None = 0, // Slide should not be rendered
    Prefetch = 1, // Good time to fetch resources needed visually for the slide, but can be fetched lazily (ie img loading = lazy)
    VisuallyReady = 2, // Rendered visually, so its ready visually when user drags the slider. Functionality, such as a zoomed image for an image zoomer need not be loaded yet
    Full = 3 // Slide should be fully rendered and full functional
}

export interface DesicionContext {
    activeIndex: number;
    hasHovered: boolean;
    hasInteracted: boolean;
    isAnimating: boolean;
    timerStatuses: boolean[];
}

export interface LoadManagerTimerDefinition {
    delay: number;
    random: number;
}

export const useCustomLoadManager = (swiperManager: SwiperManager, loadDecisionLogic: (index:number, context:DesicionContext) => SlideRenderState, timers:LoadManagerTimerDefinition[] | []) => {
    // Purpose of the load status array is to ensure render status can only progress, avoiding off-loading slides that have already been loaded
    let loadStatus = <SlideRenderState[]>[];

    const timerRefs: Ref<boolean>[] = [];
    for (let i = 0; i < (timers.length || 0); i++) {
        timerRefs.push(ref(false));
    }

    const startTimers = () => {
        for (let i = 0; i < (timers.length || 0); i++) {
            timerRefs[i].value = false; ;
            setTimeout(() => {
                timerRefs[i].value = true;
            }, timers[i].delay + Math.floor(Math.random() * timers[i].random));
        }
    };

    watch(swiperManager.slides, () => {
        loadStatus = [];
        startTimers();
    });

    const getSlideRenderStatus = (index: number): SlideRenderState => {
        if (loadStatus[index] === SlideRenderState.Full) {
            return SlideRenderState.Full;
        }
        const result = loadDecisionLogic(index, {
            activeIndex: swiperManager.activeIndex.value,
            hasHovered: swiperManager.hasHovered.value,
            hasInteracted: swiperManager.hasInteracted.value,
            isAnimating: swiperManager.isAnimating.value,
            timerStatuses: timerRefs.map((timerRef) => timerRef.value)
        });
        if (result <= loadStatus[index]) {
            return loadStatus[index];
        }
        loadStatus[index] = result;
        return result;
    };

    startTimers();

    return { getSlideRenderStatus };
};
