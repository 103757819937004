import { GetterTree, MutationTree, ActionTree } from "vuex";
import { namespace } from "vuex-class";

export enum MutationMethod {
    setShowingSalesDocumentsByMaterial = "setShowingSalesDocumentsByMaterial"
}
interface orderViewState {
    showingSalesDocumentsByMaterial: boolean;
}
const orderViewState: orderViewState = {
    showingSalesDocumentsByMaterial: false

};

const getters: GetterTree<orderViewState, any> = {
    showingSalesDocumentsByMaterial: state => state.showingSalesDocumentsByMaterial
};

const actions: ActionTree<orderViewState, any> = {
    setShowingSalesDocumentsByMaterial({ commit }, value: boolean) {
        commit(MutationMethod.setShowingSalesDocumentsByMaterial, value);
    }
};

const mutations: MutationTree<orderViewState> = {
    [MutationMethod.setShowingSalesDocumentsByMaterial](state, value) {
        state.showingSalesDocumentsByMaterial = value;
    }
};

export default {
    namespaced: true,
    state: orderViewState,
    getters,
    actions,
    mutations
};

const moduleName = "orderViewStore";
const moduleObject = namespace(moduleName);

export const orderViewStoreGetter = moduleObject.Getter;
export const orderViewStoreMutation = moduleObject.Mutation;
export const orderViewStoreAction = moduleObject.Action;
export const orderViewStoreState = moduleObject.State;
