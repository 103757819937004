import Http from '@/core/http/http.service';
import LedCalculatorInitializeViewModel = Vertica.LouisPoulsen.Application.LedCalculator.ViewModels.LedCalculatorInitializeViewModel;
import LedCalculatorChangeViewModel = Vertica.LouisPoulsen.Application.LedCalculator.ViewModels.LedCalculatorChangeViewModel;
import CalculateWebRequest = Vertica.LouisPoulsen.Application.LedCalculator.Requests.CalculateWebRequest;
import LedCalculatorResultViewObject = Vertica.LouisPoulsen.Application.LedCalculator.ViewModels.LedCalculatorResultViewObject;
import LedCalculatorFormWebRequest = Vertica.LouisPoulsen.Application.LedCalculator.Requests.LedCalculatorFormWebRequest;

class ledCalculatorController {
    public async getFixtures(): Promise<LedCalculatorInitializeViewModel> {
        return Http.get<LedCalculatorInitializeViewModel>('ledcalculator/initialize');
    }

    public async getLedUpgradesForFixture(fixtureId: number): Promise<LedCalculatorChangeViewModel> {
        return Http.get<LedCalculatorChangeViewModel>('ledcalculator/change?fixtureId=' + fixtureId);
    }

    public async calculate(payload: CalculateWebRequest): Promise<LedCalculatorResultViewObject> {
        return Http.post<LedCalculatorResultViewObject>('ledcalculator/calculate', payload)
    }

    public async sendForm(payload: LedCalculatorFormWebRequest): Promise<string> {
        return Http.post<string>('ledcalculator/sendform', payload)
    }
}

export default new ledCalculatorController();
