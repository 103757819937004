import { router } from '../router';
import { includes, without, omitBy, isArray } from 'lodash';
import { parse, isValid, format } from 'date-fns';
import { Dictionary } from 'vuex';
import { internalDateFormat } from '@/core/constants';

export class QueryParams {
    public static readonly SelectedVariantId = 'v';
    public static readonly SelectedTab = 't';
    public static readonly AccordionState = 't';
    public static readonly ConfiguredVariantId = 'c';
    public static readonly SearchTerm = 's';
    public static readonly ScrollToId = 'st';
    public static readonly ProjectSearchPage = 'pp';
    public static readonly ProjectSearchProjectCategory = 'ppj';

    public static readonly InvoicesStartDate = 'is';
    public static readonly InvoicesEndDate = 'ie';
    public static readonly InvoicesInvoiceNumber = 'id';

    public static readonly QuickOrderSearchTerm = 'qt';
    public static readonly QuickOrderSearchFilter = 'qf';
    public static readonly QuickOrderSearchResultPageNumber = 'qp';
    public static readonly QuickOrderSearchProductId = 'qpid';

    public static readonly OrdersStartDate = 'os';
    public static readonly OrdersEndDate = 'oe';
    public static readonly OrdersStatus = 'ost';
    public static readonly OrdersOrderNumber = 'id';

    public static readonly QuotationStartDate = 'qs';
    public static readonly QuotationEndDate = 'qe';
    public static readonly QuotationValidTo = 'qv';
    public static readonly QuotationQuoteNumber = 'id';

    public static readonly ClientFilterValue = 'cfv';
    public static readonly ClientSortValue = 'csv';
    public static readonly ClientSortDirection = 'csd';

    public static readonly ReflectionsSearchLoad = 'rl';
    public static readonly ReflectionsSearchTag = 'rt';

    public static readonly DualLanguageSignal = 'dls';

    public static readonly ProductConfiguratorCode = 'lppc';

    public static readonly StockTransferOrderNumber = 'sto';

    public static readonly showInAR: string = 'showInAR';
}

class QueryParamService {
    public getParam(param: string) : string {
        return router.currentRoute.query[param] as string;
    }

    public hasParam(param: string) : boolean {
        return router.currentRoute.query.hasOwnProperty(param);
    }

    public getDateParam(param: string) : Date | null {
        const date = parse(this.getParam(param), internalDateFormat, new Date());
        return isValid(date) ? date : null;
    }

    public appendQueryParam(param: string, value: string) {
        const append = {};
        append[param] = value;
        const query = {
            ...router.currentRoute.query,
            ...append
        };
        return query;
    }

    public appendQueryParams(params: Array<{name: string, value?: string, date?: Date}>) {
        const append = {};
        params.forEach(param => {
            if (param.date) {
                append[param.name] = format(param.date, internalDateFormat);
            } else {
                append[param.name] = param.value;
            }
        });
        const query = {
            ...router.currentRoute.query,
            ...append
        };
        return query;
    }

    public removeQueryParam(param: string) {
        return this.appendQueryParam(param, undefined);
    }

    public deleteQueryParam(param: string) {
        const query = { ...router.currentRoute.query };
        delete query[param];
        router.replace({ query });
        return query;
    }

    public getChangedQueries(route: RelativeUrl, oldRoute: RelativeUrl): string[] {
        const object = omitBy(route.query, (v, k) => (oldRoute.query[k] as string) === (v as string));
        return Object.keys(object);
    }

    public hasQueryChanged(queries, changedQueries): boolean {
        return queries.some(i => changedQueries.includes(i));
    }

    public setQueryParamValue(param:string, value:string, history = false): void {
        this.updateQueryParamValue(param, value, history, true);
    }

    public removeQueryParamValue(param, value, history = false): void {
        this.updateQueryParamValue(param, value, history, false);
    }

    public hasQueryParamValue(param, value): boolean {
        const currentValues: string[] = this.normalizeValues(this.getParam(param));
        return includes(currentValues, value);
    }

    private updateQueryParamValue(param:string, value:string, history:boolean, state:boolean) {
        const currentValues: string[] = this.normalizeValues(this.getParam(param));
        const newValues:string[] = state ? Array.from(new Set([...currentValues, value])) : without(currentValues, value);

        const query = {
            ...router.currentRoute.query,
            [param]: newValues
        } as Dictionary<string>;

        if (newValues.length < 1) {
            delete query[param];
        }

        if (history) {
            router.push({ query });
        } else {
            router.replace({ query });
        }
    }

    private normalizeValues(values:undefined | string | string[]): string[] {
        const normalizedValues = isArray(values) ? values : values ? [values] : [];
        return normalizedValues;
    }
}

export default new QueryParamService();
