import Http from '../../../core/http/http.service';
import ICreatePasswordForm from '@/ui/views/createpassword/createpasswordform.interface';
import RedirectViewModel = Vertica.LouisPoulsen.Application.Infrastructure.WebApi.Response.RedirectViewModel;
import ChooseNewPasswordRequest = Vertica.LouisPoulsen.Application.Authentication.Api.Requests.ChooseNewPasswordRequest;

class CreatePasswordService {
    public async createPassword(formData: ICreatePasswordForm, token: string): Promise<RedirectViewModel> {
        const { password, confirmPassword } = formData;

        const request: ChooseNewPasswordRequest = {
            newPassword: password,
            newPasswordAgain: confirmPassword
        };

        return Http.post(`authentication/password/forgot/${token}`, request)
            .then((response: RedirectViewModel) => {
                return response;
            });
    }
}

export default new CreatePasswordService();
