import NewsletterService from '@/services/newsletter.service';
import { computed, reactive } from 'vue';
import { store } from '.';
import { useTranslations } from '@/core/translate.mixin';

const newsletterState = reactive<INewsletterState>(
    { prefilledNewsletterSignupInformation: null }
);

export function useNewsletter() {
    async function getFormOptions() {
        return await NewsletterService.getFormOptions();
    }

    async function submitFormOptions(request: SubscribeToNewsletterWebRequest) {
        return await NewsletterService.submitFormOptions(request);
    }

    async function getCountryData(request: NewsletterExistingCountryWebRequest) {
        return await NewsletterService.getCountryData(request);
    }

    async function submitMarketingEmailPermission(request: AcceptMarketingEmailsWebRequest) {
        return await NewsletterService.acceptMarketingEmails(request);
    }

    async function submitSignUpDiscountForm(request: SignUpDiscountEmailWebRequest): Promise<string> {
        return await NewsletterService.submitSignUpDiscountForm(request);
    }

    async function updateEmailProfile(request: UpdateEmailProfileWebRequest): Promise<string> {
        return await NewsletterService.updateEmailProfile(request);
    }

    function setPrefilledNewsletterSignupInformation(prefilledNewsletterSignupInformation: IPrefilledNewsletterSignupRequestForm) {
        newsletterState.prefilledNewsletterSignupInformation = prefilledNewsletterSignupInformation;
    }

    function getPrefilledNewsletterSignupInformation() {
        return newsletterState.prefilledNewsletterSignupInformation;
    }

    const isPrivateMode = computed<boolean>(() => store.getters['app/isPrivateMode']);
    const privacyPolicyUrl = computed<string>(() => store.getters['app/privacyPolicyUrl']);
    const { t } = useTranslations();

    const privacyPolicyStatementHtml = computed<string>(() => (isPrivateMode.value
        ? t('Shared.Consent.PrivacyPolicyPrefixResidential')
        : t('Shared.Consent.PrivacyPolicyPrefixProfessional'))
        .replace('{0}', privacyPolicyUrl.value));
    const newsletterFlagText = computed<string>(() => (isPrivateMode.value ? t('Shared.Consent.MarketingResidential') : t('Shared.Consent.MarketingProfessional')));
    const profilingFlagText = computed<string>(() => (isPrivateMode.value ? t('Shared.Consent.ProfilingResidential') : t('Shared.Consent.ProfilingProfessional')));

    return {
        getFormOptions,
        privacyPolicyStatementHtml,
        newsletterFlagText,
        profilingFlagText,
        submitFormOptions,
        getCountryData,
        submitMarketingEmailPermission,
        submitSignUpDiscountForm,
        updateEmailProfile,
        setPrefilledNewsletterSignupInformation,
        getPrefilledNewsletterSignupInformation
    };
}
