import { GetterTree, MutationTree, ActionTree } from 'vuex';
import { namespace } from 'vuex-class';
import PageService from '../core/page.service';
import ServerContextService from '../core/server-context.service';
import Axios from 'axios';

enum MutationMethod {
    updatePage = 'updatePage',
    toggleAppLoading = 'toggleAppLoading',
    setInitialData = 'setInitialData',
    togglePageIsChanging = 'togglePageIsChanging',
    setCustomBreadcrumbStyling = 'setCustomBreadcrumbStyling'
}

const storeState: IAppState = {
    loading: {
        app: false
    },
    statusCode: 200,
    isCheckoutViewWithTopBar: false,
    pageData: {
        analyticsTrackerScript: '',
        content: '',
        breadcrumb: [
            {
                displayName: 'Search',
                url: '/Search',
                absoluteUrl: ''
            },
            {
                displayName: 'Lamp 1',
                url: '/lamp',
                absoluteUrl: ''
            }
        ],
        isCheckoutView: false,
        hideNavigationSpacer: false,
        isCheckoutViewWithTopBar: false,
        hideBreadcrumb: false,
        previousUrlBreadcrumb: false,
        isNoIndexPage: false,
        metaData: {
            description: '',
            keywords: '',
            title: '',
            url: '',
            image: '',
            hrefLanguages: []
        },
        theme: {
            pageBackgroundColor: '',
            textColor: '',
            footerBackgroundColor: '',
            productTileBackgroundColor: '',
            breadcrumbColor: ''
        },
        menuTheme: {
            whiteMenuText: false,
            removeTopPadding: false,
            removeSpacingBeforeContent: false
        }
    },
    languages: [],
    primaryCountryCode: '',
    modes: [],
    usp: null,
    footer: null,
    menu: [],
    myAccountRoot: null,
    portalRoot: null,
    editMode: false,
    isChangingPage: false,
    isAuthenticated: false,
    displayNameB2B: '',
    showStock: true,
    isBasketVisible: false,
    isQuoteBasketVisible: false,
    hideSiteModeProf: false,
    hasChangedRoute: false,
    pages: {} as IPageCollection,
    recaptchaKey: '',
    showNewsletterPopup: true,
    showSignUpDiscountPopup: false,
    signUpDiscountPopupImageUrl: '',
    cordCuttingLinkUrl: '',
    isPointOfSaleMode: false,
    pointOfSale: null,
    customBreadcrumbStyling: null
};

const storeGetters: GetterTree<IAppState, any> = {
    statusCode: state => state.statusCode,
    pageData: state => (key: keyof PageDataViewModel) => state.pageData[key],
    metaData: state => state.pageData.metaData,
    isInEditMode: state => state.editMode,
    isAuthenticated: state => state.isAuthenticated,
    displayNameB2B: state => state.displayNameB2B,
    isChangingPage: state => state.isChangingPage,
    isOnFrontPage: (_, getters) => currentPath => getters.selectedMode.frontpageUrl === currentPath,
    frontpageUrl: (_, getters) => getters.selectedMode.frontpageUrl,
    isCheckoutView: state => state.pageData.isCheckoutView || false,
    hideNavigationSpacer: state => state.pageData.hideNavigationSpacer || false,
    isBasketVisible: state => state.isBasketVisible,
    isQuoteBasketVisible: state => state.isQuoteBasketVisible,
    hideSiteModeProf: state => state.hideSiteModeProf || false,
    hasModeChanged: state => currentPath => hasModeChanged(currentPath, state.modes.find(x => !x.isSelected)),
    hasChangedRoute: state => state.hasChangedRoute,
    basketUrl: state => state.pages.basket || '',
    basketLoginUrl: state => state.pages.basketLogin || '',
    quoteBasketUrl: state => state.pages.quoteBasket || '',
    logoutUrl: state => state.pages.logout || '',
    findRetailersUrl: state => state.pages.retailers || '',
    findDistributorsUrl: state => state.pages.distributors || '',
    checkoutUrl: state => state.pages.checkout || '',
    termsUrl: state => state.pages.terms || '',
    privacyPolicyUrl: state => state.pages.privacyPolicy || '',
    isOnSearchPage: state => currentPath => state.pages.searchResult === currentPath,
    isCheckoutViewWithTopBar: state => state.isCheckoutViewWithTopBar,
    catalogRootUrl: (_, getters) => getters.selectedMode.catalogRootUrl,
    searchResultUrl: state => state.pages.searchResult || '',
    breadcrumb: state => state.pageData.breadcrumb,
    hideBreadcrumb: state => state.pageData.hideBreadcrumb,
    previousUrlBreadcrumb: state => state.pageData.previousUrlBreadcrumb,
    appLoading: state => state.loading.app,
    modes: state => state.modes,
    selectedMode: state => state.modes.find(x => x.isSelected) || ({} as IMode),
    targetMode: state => state.modes.find(x => x.isSelected === false) || {},
    isPrivateMode: state => (state.modes.find(x => x.isSelected) || ({} as IMode)).id === 0,
    languages: state => state.languages || [],
    primaryCountryCode: state => state.primaryCountryCode || '',
    selectedLanguage: state => state.languages.find(x => x.isSelected) || {},
    menu: state => state.menu || [],
    myAccountRoot: state => state.myAccountRoot || null,
    portalRoot: state => state.portalRoot || null,
    footer: state => state.footer || [],
    recaptchaKey: state => state.recaptchaKey || '',
    showNewsletterPopup: state => state.showNewsletterPopup,
    showSignUpDiscountPopup: state => state.showSignUpDiscountPopup,
    signUpDiscountPopupImageUrl: state => state.signUpDiscountPopupImageUrl,
    cordCuttingLinkUrl: state => state.cordCuttingLinkUrl,
    isPointOfSaleMode: state => state.pointOfSale != null,
    pointOfSale: state => state.pointOfSale,
    theme: state => state.pageData.theme || {},
    menuTheme: state => state.pageData.menuTheme || {},
    usp: state => state.usp || null,
    showBodyPadding: (state, getters) => (!state.pageData.menuTheme || !state.pageData.menuTheme.removeTopPadding) && !getters.isCheckoutView,
    showStock: state => state.showStock,
    borderColor: (_, getters) => (opacity: number, defaultClass = '') => borderColor(getters.theme, opacity, defaultClass),
    textColor: (_, getters) => (opacity: number, defaultClass = '') => textColor(getters.theme, opacity, defaultClass),
    isB2CAuthenticated: (state, getters) => getters.isPrivateMode && state.isAuthenticated,
    isB2BAuthenticated: (state, getters) => !getters.isPrivateMode && state.isAuthenticated,
    showAuthTopBar: (_, getters) => getters.isB2BAuthenticated,
    showEmptyTopBar: (_, getters) => getters.isB2CAuthenticated && !getters.usp,
    customBreadcrumbStyling: state => state.customBreadcrumbStyling
};

const actions: ActionTree<IAppState, any> = {
    async fetchPage({ commit }, route) {
        const timer = setTimeout(() => commit(MutationMethod.togglePageIsChanging, true), 5);
        try {
            const page = await PageService.get(route);
            commit(MutationMethod.updatePage, page);
            clearTimeout(timer);
            commit(MutationMethod.togglePageIsChanging, false);
            return page;
        } catch (error) {
            if (Axios.isCancel(error)) {
                console.log(route, error.message);
            } else {
                console.log(error);
                clearTimeout(timer);
                commit(MutationMethod.togglePageIsChanging, false);
            }
        }
    },
    // tslint:disable-next-line:no-shadowed-variable
    initialize(context) {
        const initialData : IInitialData = {
            statusCode: ServerContextService.statusCode,
            languages: ServerContextService.languages,
            primaryCountryCode: ServerContextService.primaryCountryCode,
            menu: ServerContextService.menu,
            myAccountRoot: ServerContextService.myAccountRoot,
            portalRoot: ServerContextService.portalRoot,
            usp: ServerContextService.usp,
            footer: ServerContextService.footer,
            recaptchaKey: ServerContextService.recaptchaKey,
            modes: ServerContextService.modes,
            breadcrumb: ServerContextService.initialBreadcrumb,
            hideBreadcrumb: ServerContextService.hideBreadcrumb,
            previousUrlBreadcrumb: ServerContextService.previousUrlBreadcrumb,
            editMode: !ServerContextService.spaMode,
            isCheckoutView: ServerContextService.isCheckoutView,
            isCheckoutViewWithTopBar: ServerContextService.isCheckoutViewWithTopBar,
            hideNavigationSpacer: ServerContextService.hideNavigationSpacer,
            isAuthenticated: ServerContextService.isAuthenticated,
            displayNameB2B: ServerContextService.displayNameB2B,
            isBasketVisible: ServerContextService.isBasketVisible,
            isQuoteBasketVisible: ServerContextService.isQuoteBasketVisible,
            hideSiteModeProf: ServerContextService.hideSiteModeProf,
            showNewsletterPopup: ServerContextService.showNewsletterPopup,
            pages: ServerContextService.pages,
            showSignUpDiscountPopup: ServerContextService.showSignUpDiscountPopup,
            signUpDiscountPopupImageUrl: ServerContextService.signUpDiscountPopupImageUrl,
            cordCuttingLinkUrl: ServerContextService.cordCuttingLinkUrl,
            theme: {
                pageBackgroundColor: ServerContextService.theme.pageBackgroundColor,
                textColor: ServerContextService.theme.textColor,
                footerBackgroundColor: ServerContextService.theme.footerBackgroundColor,
                productTileBackgroundColor: ServerContextService.theme.productTileBackgroundColor,
                breadcrumbColor: ServerContextService.theme.breadcrumbColor
            },
            menuTheme: ServerContextService.menuTheme,
            showStock: ServerContextService.showStockB2B,
            pointOfSale: ServerContextService.pointOfSale
        };
        context.commit(MutationMethod.setInitialData, initialData);
    },
    setCustomBreadcrumbStyling({ commit }, styling) {
        commit(MutationMethod.setCustomBreadcrumbStyling, styling);
    }
};

const mutations: MutationTree<IAppState> = {
    [MutationMethod.updatePage](state, result: IPageResult) {
        state.pageData = result.data;
        state.isCheckoutViewWithTopBar = result.data.isCheckoutViewWithTopBar;
        state.hasChangedRoute = true;
        state.statusCode = result.statusCode;
    },
    [MutationMethod.toggleAppLoading](state, flag) {
        state.loading.app = flag;
    },
    [MutationMethod.setInitialData](state, data: IInitialData) {
        state.pageData.breadcrumb = data.breadcrumb;
        state.pageData.isCheckoutView = data.isCheckoutView;
        state.pageData.hideNavigationSpacer = data.hideNavigationSpacer;
        state.pageData.hideBreadcrumb = data.hideBreadcrumb;
        state.pageData.previousUrlBreadcrumb = data.previousUrlBreadcrumb;
        state.pageData.theme = data.theme;
        state.pageData.menuTheme = data.menuTheme;

        state.statusCode = data.statusCode;
        state.isCheckoutViewWithTopBar = data.isCheckoutViewWithTopBar;
        state.usp = data.usp;
        state.footer = data.footer;
        state.recaptchaKey = data.recaptchaKey;
        state.languages = data.languages;
        state.primaryCountryCode = data.primaryCountryCode;
        state.modes = data.modes;
        state.menu = data.menu;
        state.myAccountRoot = data.myAccountRoot;
        state.portalRoot = data.portalRoot;
        state.editMode = data.editMode;
        state.isAuthenticated = data.isAuthenticated;
        state.displayNameB2B = data.displayNameB2B;
        state.showStock = data.showStock;
        state.isBasketVisible = data.isBasketVisible;
        state.isQuoteBasketVisible = data.isQuoteBasketVisible;
        state.hideSiteModeProf = data.hideSiteModeProf;
        state.pages = data.pages;
        state.showNewsletterPopup = data.showNewsletterPopup;
        state.showSignUpDiscountPopup = data.showSignUpDiscountPopup;
        state.signUpDiscountPopupImageUrl = data.signUpDiscountPopupImageUrl;
        state.cordCuttingLinkUrl = data.cordCuttingLinkUrl;
        state.pointOfSale = data.pointOfSale;
    },
    [MutationMethod.togglePageIsChanging](state, flag) {
        state.isChangingPage = flag;
    },
    [MutationMethod.setCustomBreadcrumbStyling](state, styling) {
        state.customBreadcrumbStyling = styling;
    }
};

export default {
    namespaced: true,
    state: storeState,
    getters: storeGetters,
    actions,
    mutations
};

const moduleName = 'app';
const moduleObject = namespace(moduleName);

export const AppGetter = moduleObject.Getter;
export const AppMutation = moduleObject.Mutation;
export const AppAction = moduleObject.Action;
export const AppState = moduleObject.State;

export interface IAppActions {
    fetchPage?: (path: string) => Promise<PageDataViewModel>;
}

function hasModeChanged(newPath, targetMode) {
    const isFrontpageInOtherMode = newPath.indexOf(targetMode.frontpageUrl) !== -1;
    const isCatalogPageInOtherMode = newPath.indexOf(targetMode.catalogRootUrl) !== -1;
    if (isFrontpageInOtherMode || isCatalogPageInOtherMode) {
        return true;
    }
    return false;
}

const textOpacityTable = {
    25: 'u-text-dark-2',
    30: 'u-text-dark-3',
    50: 'u-text-dark-4',
    60: 'u-text-dark-5',
    40: 'u-text-dark-6',
    20: 'u-text-dark-20'
};

const borderOpacityTable = {
    25: 'u-border-dark-2',
    30: 'u-border-dark-3',
    50: 'u-border-dark-4',
    60: 'u-border-dark-5',
    40: 'u-border-dark-6',
    20: 'u-border-dark-20'
};

function borderColor(theme: ColorTheme, opacity: number, defaultClass = '') {
    if (theme.textColor) {
        return `custom-border-${opacity}`;
    }
    if (defaultClass) {
        return defaultClass;
    }
    return borderOpacityTable[opacity] || '';
}

function textColor(theme: ColorTheme, opacity: number, defaultClass = '') {
    if (theme.textColor) {
        return `custom-text-${opacity}`;
    }
    if (defaultClass) {
        return defaultClass;
    }
    return textOpacityTable[opacity] || '';
}
