import Http from '@/core/http/http.service';

class CountryService {
    getCountries() : Promise<CountryViewModel[]> {
        return Http.get<CountryViewModel[]>('countries/getcountries');
    }

    getCountryStates(countryCode: string) : Promise<StateViewObject[]> {
        return Http.get<any>('states/getstates?countrycode=' + countryCode);
    }
}

export default new CountryService();
