import Emitter, { emitterEvents } from './emitter.service';
import DictionaryService from '@/core/dictionary.service';

export interface INotificationEvent {
    title: string;
    message: string;
}

export enum NotificationType {
    SUCCESS,
    WARNING,
    ERROR
}

class NotificationService {
    constructor() {
        Emitter.on(emitterEvents.ApiErrorEvent, (data) => {
            this.error(
                DictionaryService.get('Shared.ErrorToaster.Headline'),
                data || DictionaryService.get('Shared.ErrorToaster.BodyText')
            );
        });
    }

    public success(title: string, message: string) {
        this.notify(NotificationType.SUCCESS, { title, message });
    }

    public warning(title: string, message: string) {
        this.notify(NotificationType.WARNING, { title, message });
    }

    public error(title: string, message: string) {
        this.notify(NotificationType.ERROR, { title, message });
    }

    public notify(type: NotificationType, data: INotificationEvent) {
        Emitter.emit(emitterEvents.NotificationEvent, {
            type,
            title: data.title,
            message: data.message
        });
    }

    public onNotification(handler: (event: INotificationEvent) => void) {
        Emitter.on(emitterEvents.NotificationEvent, handler);
    }
}

export default new NotificationService();
