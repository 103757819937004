import Http from '../core/http/http.service';
import UpdateLineItemWebRequest = Vertica.LouisPoulsen.Application.Commerce.Requests.UpdateLineItemWebRequest;
import UpdateGiftWithPurchaseVariantWebRequest = Vertica.LouisPoulsen.Application.Commerce.Requests.UpdateGiftWithPurchaseVariantWebRequest;
import RemoveFromBasketWebRequest = Vertica.LouisPoulsen.Application.Commerce.Requests.RemoveFromBasketWebRequest;
import UpdateLineReferenceWebRequest = Vertica.LouisPoulsen.Application.Commerce.Requests.UpdateLineReferenceWebRequest;
import EnsureValidForCheckoutWebRequest = Vertica.LouisPoulsen.Application.Commerce.Requests.EnsureValidForCheckoutWebRequest;
import { BasketType } from '@/enums';
import CalculateSalesTaxWebRequest = Vertica.LouisPoulsen.Application.Commerce.Requests.Consumer.CalculateSalesTaxWebRequest;
import CalculateSalesTaxResultViewModel = Vertica.LouisPoulsen.Application.Commerce.ViewModels.Consumer.CalculateSalesTaxResultViewModel;

class BasketService {
    public get(basketType: BasketType | null) {
        return Http.get('basket/get', { basketType });
    }

    public add(request: AddToBasketWebRequest) {
        return Http.post('basket/add', request);
    }

    public update(request: UpdateLineItemWebRequest) {
        return Http.post('basket/update', request);
    }

    public updateGift(request: UpdateGiftWithPurchaseVariantWebRequest) {
        return Http.post('basket/updateGiftWithPurchaseVariant', request);
    }

    public remove(request: RemoveFromBasketWebRequest) {
        return Http.post('basket/remove', request);
    }

    public updateLineReference(request: UpdateLineReferenceWebRequest) {
        return Http.post('basket/updatelinereference', request);
    }

    public ensureValidForCheckout(request: EnsureValidForCheckoutWebRequest) : Promise<ValidationResultViewModel> {
        return Http.post('basket/ensurevalidforcheckout', request);
    }

    public addFreeItem() : Promise<ValidationResultViewModel> {
        return Http.post('basket/addfreeitemfromdiscount');
    }

    public calculateSalesTax(request: CalculateSalesTaxWebRequest) : Promise<CalculateSalesTaxResultViewModel> {
        return Http.post('basket/calculatesalestax', request);
    }

    public UpdateShippingCostB2B() {
        return Http.get('basket/UpdateSapTotalsForB2B');
    }
}

export default new BasketService();
