
import Vue from 'vue';
import {
    Component,
    Prop
} from 'vue-property-decorator';
import QRcode from 'qrcode';
import { QRCodeGeneratorOptions } from '@/types/qRCodeGenerator.types';

@Component
export default class QRCodeGenerator extends Vue {
    @Prop() backgroundColor: string;

    @Prop({
        required: true,
        type: String
    }) qrLink: string;

    mounted(): void {
        QRcode.toCanvas(this.qrLink, this.qrOptions, (err, canvas) => {
            if (err) {
                throw err;
            }
            this.$el.appendChild(canvas);
        });
    }

    get qrOptions() : QRCodeGeneratorOptions {
        return {
            errorCorrectionLevel: 'M',
            width: 114,
            margin: 2,
            color: {
                light: this.backgroundColor
            }
        };
    }
}
