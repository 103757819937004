import m1 from '../../../assets/img/m1.svg';
import m2 from '../../../assets/img/m2.svg';
import m3 from '../../../assets/img/m3.svg';

export default [
    {
        url: m1,
        width: 50,
        height: 50,
        fontFamily: 'Helvetica Neue LT W01_55 Roman, Arial, sans-serif',
        fontWeight: 400,
        textColor: '#ffffff',
        textSize: 18
    },
    {
        url: m2,
        width: 50,
        height: 50,
        fontFamily: 'Helvetica Neue LT W01_55 Roman, Arial, sans-serif',
        fontWeight: 400,
        textColor: '#ffffff',
        textSize: 18
    },
    {
        url: m3,
        width: 50,
        height: 50,
        fontFamily: 'Helvetica Neue LT W01_55 Roman, Arial, sans-serif',
        fontWeight: 400,
        textColor: '#ffffff',
        textSize: 18
    }
];
