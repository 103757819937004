import { render, staticRenderFns } from "./NewNewsletterForm.vue?vue&type=template&id=fa8b0efc&scoped=true&"
import script from "./NewNewsletterForm.vue?vue&type=script&lang=ts&setup=true&"
export * from "./NewNewsletterForm.vue?vue&type=script&lang=ts&setup=true&"
import style0 from "./NewNewsletterForm.vue?vue&type=style&index=0&id=fa8b0efc&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fa8b0efc",
  null
  
)

export default component.exports