import viewportEvents from '@/core/responsive/viewport/viewport-events.service';
import breakpointService from '@/core/responsive/breakpoints/breakpoint.service';
import { forEach } from 'lodash';

export const SearchResultScrollTarget = '[data-search-result-scroll-target]';
export const ScrollCancelToken = '$$ScrollServiceSetContainerScroll$$';

class ScrollService {
    public scrollToTop() {
        window.scrollTo(0, 0);
    }

    public scrollToElement(element: HTMLElement, offset = 0, duration = 200) {
        if (!element) {
            return;
        }
        const position = element.getBoundingClientRect().top + offset;
        duration === 0
            ? this.simpleScroll(position)
            : this.animateScroll(position, duration);
    }

    private simpleScroll(to: number) {
        window.scrollTo(0, to);
    }

    private animateScroll(to, duration) {
        const startingY = window.pageYOffset;
        const diff = to - startingY;
        let start;
        window.requestAnimationFrame(function step(timestamp) {
            if (!start) { start = timestamp; }
            const time = timestamp - start;
            const percent = Math.min(time / duration, 1);

            window.scrollTo(0, startingY + diff * percent);

            if (time < duration) {
                window.requestAnimationFrame(step);
            }
        });
    }

    public scrollSearchResultIntoView(): void {
        const searchContainer = document.querySelector(SearchResultScrollTarget);

        if (!searchContainer) {
            return;
        }

        const offset = breakpointService.isActiveBreakpoint('large') ? 80 : -50;
        const scrollTop = viewportEvents.viewport.scrollY;
        const offsetTop = (searchContainer as HTMLElement).offsetTop;
        const isScrolledPast = scrollTop - offset > offsetTop;
        if (isScrolledPast) {
            window.scrollTo(0, offsetTop - offset);
        }
    }

    public setMultiContainerScroll(elems:Array<HTMLElement | Element>, duration/* ms */:number, x?:number, y?:number): void {
        forEach(elems, el => {
            this.setContainerScroll(el, duration, x, y);
        });
    }

    public setContainerScroll(el:HTMLElement | Element, duration/* ms */:number, x?:number, y?:number): void {
        const start = {
            x: el.scrollLeft,
            y: el.scrollTop
        };
        const diff = {
            ...(x !== undefined && { x: x - start.x }), // only set property if argument was supplied
            ...(y !== undefined && { y: y - start.y })
        };

        // Set cancel token for element
        el[ScrollCancelToken] = true;

        let tick;

        window.requestAnimationFrame(function step(timestamp) {
            if (!tick) { tick = timestamp; }
            const time = timestamp - tick;
            const percent = Math.min(time / duration, 1);

            if (diff.x) {
                el.scrollLeft = start.x + diff.x * percent;
            }

            if (diff.y) {
                el.scrollTop = start.y + diff.y * percent;
            }

            if (time < duration && el[ScrollCancelToken]) {
                window.requestAnimationFrame(step);
            }
        });
    }

    public clearContainerScroll(el:HTMLElement | Element): void {
        if (el[ScrollCancelToken]) {
            delete el[ScrollCancelToken];
        }
    }

    public clearMultiContainerScroll(elems:Array<HTMLElement | Element>): void {
        forEach(elems, el => {
            this.clearContainerScroll(el);
        });
    }
}

// t = current time
// b = start value
// c = change in value
// d = duration
(Math as any).easeInOutQuad = (t, b, c, d) => {
    t /= d / 2;
    if (t < 1) { return c / 2 * t * t + b; }
    t--;
    return -c / 2 * (t * (t - 2) - 1) + b;
};

export default new ScrollService();
