/*
Use to determine if the primary input mechanism can hover over elements
*/

import { useMediaQuery } from './mediaquery';

export function useCanHover() {
    const { mediaQueryMatches } = useMediaQuery('(hover: hover)');
    return {
        canHover: mediaQueryMatches
    };
}
