
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({
    name: 'error-message'
})
export default class ErrorMessage extends Vue {
    @Prop({
        required: false,
        type: String
    })
        message: string;
}
