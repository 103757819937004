
import Vue from 'vue';
import { AppGetter } from '../../store';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import FlowboxDynamicTagBlockViewModel = Vertica.LouisPoulsen.Application.Content.Blocks.FlowboxDynamicTagBlockViewModel;

@Component({
    name: 'flowbox-with-dynamic-tags',
    components: {}
})
export default class FlowboxWithDynamicTags extends Vue {
    @AppGetter
    public isInEditMode: boolean;

    @Prop()
    public data: FlowboxDynamicTagBlockViewModel;

    selectedTag = '';

    mounted() {
        const selectedTag = this.data.tags.find(tag => tag.selected);
        if (selectedTag) {
            this.selectedTag = selectedTag.tagName;
        }
    }

    get hasHeadline() {
        const headline = this.data.headline;
        return headline !== null && headline !== '';
    }

    updateFlow() {
        if ((window as any).flowbox) {
            (window as any).flowbox('update', {
                key: this.data.flowKey,
                tags: [this.selectedTag]
            });
        }
    }

    onSelect(tagName: string) {
        this.selectedTag = tagName;
        this.updateFlow();
    }
}
