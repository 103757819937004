import { render, staticRenderFns } from "./MiniBasketItemB2B.vue?vue&type=template&id=f39f119a&scoped=true&"
import script from "./MiniBasketItemB2B.vue?vue&type=script&lang=ts&setup=true&"
export * from "./MiniBasketItemB2B.vue?vue&type=script&lang=ts&setup=true&"
import style0 from "./MiniBasketItemB2B.vue?vue&type=style&index=0&id=f39f119a&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f39f119a",
  null
  
)

export default component.exports