
    import Vue from "vue";
    import Component from "vue-class-component";
    import Tab from "./Tab.vue";
    import QueryParamService, {QueryParams} from "../../core/query-params.service";
    import {Prop, Watch} from "vue-property-decorator";
    import {RouteState} from "../../store";
    import {StorageService} from "../../core/storage.service";
    import TrackingService from '@/core/tracking.service';

    @Component({
        name: "tabs",
        components: {
            Tab
        }
    })
    export default class Tabs extends Vue {
        public tabs: Tab[] = [];
        public listElement: HTMLUListElement;

        @RouteState public query: any;

        @Prop({default: true})
        public replaceRoute: boolean;

        @Prop({default: false})
        public rememberLocalTabState: boolean;

        @Prop({default: "tabkey"})
        public tabKey: string;

        @Prop({
            type: String,
            required: true
        })
        public trackingName: string;

        public selectedTabIdIfQueryDisabled: string = "";

        private isNewPathNavigated:boolean = false;

        mounted() {
            this.listElement = <HTMLUListElement>this.$el.getElementsByTagName("ul")[0];
            this.tabs = this.$children as Tab[];
            this.initActiveTab();
        }

        destroyed() {
            if (this.replaceRoute && !this.isNewPathNavigated) {
                this.removeQueryParam();
            }
        }

        public initActiveTab() {
            let tabId: string;
            let localTabId = this.getLocalTabId();
            tabId = this.replaceRoute ? this.getQueryParam() : this.selectedTabIdIfQueryDisabled;
            let tab = this.tabs.find(t => t.id === tabId);
            if (!tab && this.rememberLocalTabState)
                tab = this.tabs.find(t => t.id === localTabId);

            //If no tab was found, use last local tab else use default
            const selectedTab = tab || this.tabs[0];
            this.selectTab(selectedTab);

            // we need to wait for vue to set all attributes on html
            this.$nextTick().then(() => {
                const activeListItem = document.getElementById(selectedTab.id);
                this.scrollInFocus(activeListItem);
            });
        }

        get selectedTabId() {
            if (this.replaceRoute) {
                let id = this.query[QueryParams.SelectedTab];
                if (!id) {
                    id = this.selectedTabIdIfQueryDisabled;
                }
                return id;
            }
            return this.selectedTabIdIfQueryDisabled;
        }

        public selectTab(selectedTab: Tab) {
            if (selectedTab.isDisabled) {
                return;
            }
            if (this.replaceRoute) {
                this.updateQueryParam(selectedTab);
            } else {
                this.selectedTabIdIfQueryDisabled = selectedTab.id;
            }
            this.setTabToActive(selectedTab);
        }

        @Watch('$route')
        private onRouteChange(route, oldRoute) {
            if (route.path !== oldRoute.path) {
                this.isNewPathNavigated = true;
            }
        }

        @Watch("query")
        public onQueryChanged(newQuery, oldQuery) {
            if (this.hasTabInQueryChanged(newQuery, oldQuery) && !this.isNewPathNavigated) {
                this.initActiveTab();
            }
        }

        private hasTabInQueryChanged(newQuery, oldQuery) {
            const tab = QueryParams.SelectedTab;
            return newQuery[tab] !== oldQuery[tab];
        }

        private updateQueryParam(selectedTab: Tab) {
            const query = QueryParamService.appendQueryParam(QueryParams.SelectedTab, selectedTab.id);
            this.$router.replace({query});
        }

        private getQueryParam(): string | undefined {
            return QueryParamService.getParam(QueryParams.SelectedTab);
        }

        private removeQueryParam() {
            const query = QueryParamService.removeQueryParam(QueryParams.SelectedTab);
            this.$router.replace({query});
        }

        private scrollInFocus(li: HTMLElement): void {
            if (li) {
                const paddingSize: number = 0.25;
                if (this.listElement.scroll) {
                    this.listElement.scroll({
                        top: 0,
                        left: li.offsetLeft - (document.body.clientWidth * paddingSize),
                        behavior: "smooth"
                    });
                }
            }
        }

        private setTabToActive(selectedTab: Tab) {
            this.selectedTabIdIfQueryDisabled = selectedTab.id;
            this.tabs.forEach(tab => tab.id === selectedTab.id ? tab.isActive = true : tab.isActive = false);
            this.setLocalTabState(selectedTab);


        }

        private onTabClicked(clickedTab: Tab){
            this.selectTab(clickedTab);
            TrackingService.event("Mini page", this.trackingName, clickedTab.id);
        }

        private setLocalTabState(selectedTab: Tab) {
            if (this.rememberLocalTabState) {
                StorageService.setItemAs<string>(this.tabKey, selectedTab.id);
            }
        }

        private getLocalTabId(): string | undefined {
            if (this.rememberLocalTabState) {
                return StorageService.getItemAs<string>(this.tabKey);
            }
            return;
        }
    }

    export enum TabEnum {
        AboutProduct = "about",
        DataAndMounting = "data",
        Spareparts = "spareparts",
        Marketing = "marketing",
        Configurator = "config",
        ProductConfigurator = "product-config"
    }
