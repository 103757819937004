
import Vue from "vue";
import Component from "vue-class-component";
import { CatalogGetter } from "@/store";
import ProfessionalVariantViewObject = Vertica.LouisPoulsen.Application.Content.Pages.Product.ProfessionalVariantViewObject;

@Component({
  name: "product-data-specs"
})
export default class ProductDataSpecs extends Vue {
    @CatalogGetter public selectedConfigVariant: ProfessionalVariantViewObject;
}
