import Http from '../../../core/http/http.service';
import PageService from '../../../core/page.service';
import IOldLoginForm from './old.loginform.interface';
import IMigrationForm from './migration.form.interface';
import OldLoginViewModel = Vertica.LouisPoulsen.Application.OldAuthentication.ViewModels.OldLoginViewModel;
import CreateUsernamePasswordViewModel = Vertica.LouisPoulsen.Application.Authentication.ViewModels.CreateUsernamePasswordViewModel;

class OldLoginService {
    public async login(loginForm: IOldLoginForm, loginPageId: string, redirectUrl: string): Promise<OldLoginViewModel> {
        const { initials, userName: customerId, password } = loginForm;

        const userCredentials: Vertica.LouisPoulsen.Application.OldAuthentication.Requests.OldLoginWebRequest = {
            initials,
            customerId,
            password,
            redirectUrl,
            loginPageId
        };

        return Http.post('oldauthentication/login', userCredentials)
            .then((response: OldLoginViewModel) => {
                return response;
            });
    }

    public loginRedirect(redirectUrl: string): void {
        PageService.hardRedirect(redirectUrl);
    }

    public async migrate(form: IMigrationForm, token: string): Promise<CreateUsernamePasswordViewModel> {
        const { username, password, confirmPassword } = form;

        const request: Vertica.LouisPoulsen.Application.Authentication.Requests.CreateUsernamePasswordWebRequest = {
            token,
            username,
            password,
            confirmPassword
        };

        return Http.post('authentication/migrate-user', request)
            .then((response: CreateUsernamePasswordViewModel) => {
                return response;
            });
    }

    public async skipMigration(loginForm: IOldLoginForm, loginPageId: string, redirectUrl: string): Promise<OldLoginViewModel> {
        const { initials, userName: customerId, password } = loginForm;

        const userCredentials: Vertica.LouisPoulsen.Application.OldAuthentication.Requests.OldLoginWebRequest = {
            loginPageId,
            initials,
            customerId,
            password,
            redirectUrl
        };

        return Http.post('oldauthentication/SkipMigration', userCredentials)
            .then((response: OldLoginViewModel) => {
                return response;
            });
    }
}

export default new OldLoginService();
