var render, staticRenderFns
var script = {}


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./CordAdjustment3.vue?vue&type=custom&index=0&blockType=svg&version=1.1&xmlns=http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg&xmlns%3Axlink=http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink&x=0px&y=0px&viewBox=0%200%201484.53%202772.1&style=enable-background%3Anew%200%200%201484.53%202772.1%3B&xml%3Aspace=preserve"
if (typeof block0 === 'function') block0(component)

export default component.exports