import { ref, computed, watch, onMounted } from 'vue';
import { store } from '@/store';
import NewFeatureInfo2 = Vertica.LouisPoulsen.Application.NewFeatureInfo.Api.Responses.NewFeatureInfo;
import { getNewFeatureInfo } from './new-feature-fetch';

export function useNewFeatures() {
    const mounted = ref<boolean>(false);
    const isAuthenticated = computed<boolean>(() => store.getters['app/isAuthenticated']);

    const newFeatureInfo = ref<NewFeatureInfo2 | null>({
        headline: '',
        text: '',
        id: '',
        image: '',
        buttonText: ''
    });

    const showCommunicationModal = ref<boolean>(false);

    function closeModal() {
        showCommunicationModal.value = false;
    }

    function check() {
        getNewFeatureInfo().then((newFeature) => {
            if (newFeature) {
                newFeatureInfo.value = newFeature;
                showCommunicationModal.value = true;
            }
        });
    }

    const readyForCheck = computed(() => isAuthenticated.value && mounted.value);

    watch(readyForCheck, (value) => {
        if (value) {
            check();
        }
    });

    onMounted(() => {
        mounted.value = true;
    });

    return { showCommunicationModal, closeModal, newFeatureInfo };
}
