
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import DownloadCenterFile from '@/ui/views/download-center/DownloadCenterFile.vue';
import {
    AppGetter,
    downloadCenterAction,
    downloadCenterGetter
} from '@/store';
import {
    DynamicScroller,
    DynamicScrollerItem
} from '@/../node_modules/vue-virtual-scroller';
import DownloadCenterImagesWrapper from '@/ui/views/download-center/DownloadCenterImagesWrapper.vue';

@Component({
    components: {
        DownloadCenterImagesWrapper,
        DownloadCenterFile,
        DynamicScroller,
        DynamicScrollerItem
    }
})
export default class DownloadCenterFileTypeResult extends Vue {
    @downloadCenterGetter
    public getSelectionForType: (typeId: string) => string[];

    @downloadCenterAction
    public setSelection:(payload: {typeId: string, files: string[]}) => void;

    @downloadCenterAction
    public clearSelection:(typeId: string) => void;

    @downloadCenterAction
    public setNumberOfResults: (payload: {typeId: string, amount: number}) => void;

    @AppGetter
    public theme: ColorTheme;

    @Prop({
        type: Array,
        required: true
    }) filteredResult: FileReferenceViewObject[];

    @Prop({
        type: Object,
        required: true
    }) type: DownloadCenterTypeViewObject;

    pending = false;

    get hasResults(): boolean {
        return this.filteredResult.length > 0;
    }

    get selectedFiles(): string[] {
        return this.getSelectionForType(this.type.id);
    }

    get isAllFilesSelected(): boolean {
        return this.selectedFiles.length === this.filteredResult.length;
    }

    set isAllFilesSelected(value) {
        this.pending = true;
        setTimeout(() => {
            if (this.selectedFiles.length === this.filteredResult.length) {
                this.clearSelection(this.type.id);
            } else {
                this.setSelection({
                    typeId: this.type.id,
                    files: this.filteredResult.map(resultFile => resultFile.id)
                });
            }
            this.pending = false;
        }, 10);
    }
}
