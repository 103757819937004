import { computed, reactive } from 'vue';
import impersonationService from '@/services/impersonation.service';
import ImpersonateCustomerRequest = Vertica.LouisPoulsen.Application.Authentication.Api.Requests.ImpersonateCustomerRequest;
import serverContextService from '@/core/server-context.service';
import { store } from '.';

const state = reactive<IImpersonationState>(serverContextService.salesConsultantB2B
    ? {
        active: serverContextService.salesConsultantB2B.active,
        isImpersonating: serverContextService.salesConsultantB2B.isImpersonating,
        userId: serverContextService.salesConsultantB2B.authenticatedUserId,
        customerName: serverContextService.salesConsultantB2B.impersonatingCustomerName,
        redirectUrlForReset: serverContextService.salesConsultantB2B.redirectUrlForImpersonationReset,
        canAssignManualDiscountWhenImpersonating: serverContextService.salesConsultantB2B.canAssignManualDiscount,
        defaultDummyCustomerId: serverContextService.salesConsultantB2B.defaultDummyCustomerId,
        canImpersonateDummyCustomersOnly: serverContextService.salesConsultantB2B.canImpersonateDummyCustomersOnly,
        customerIsDummy: serverContextService.salesConsultantB2B.customerIsDummy
    }
    : {
        active: false,
        isImpersonating: false,
        userId: '',
        customerName: '',
        redirectUrlForReset: '',
        canAssignManualDiscountWhenImpersonating: false,
        defaultDummyCustomerId: '',
        canImpersonateDummyCustomersOnly: true,
        customerIsDummy: false
    });

const isAuthenticated = computed<boolean>(() => store.getters['app/isAuthenticated']);

export function useImpersonation() {
    const impersonation = computed<IImpersonationState | null>(() => isAuthenticated.value && serverContextService.salesConsultantB2B ? state : null);
    const isActiveSalesConsultant = computed<boolean>(() => !!impersonation.value && state.active);
    const isImpersonating = computed<boolean>(() => isActiveSalesConsultant.value && state.isImpersonating);
    const canAssignManualDiscount = computed<boolean>(() => isImpersonating.value && state.canAssignManualDiscountWhenImpersonating);
    const impersonatingDummyCustomer = computed<boolean>(() => isImpersonating.value && state.customerIsDummy);

    async function noImpersonation() {
        state.active = true;
        await impersonationService.setNoImpersonation();
    }

    async function finishImpersonation(request: ImpersonateCustomerRequest) {
        const finishResponse = await impersonationService.set(request);
        state.isImpersonating = true;
        state.customerName = finishResponse != null ? finishResponse.customerName : null;
        state.active = true;
        state.customerIsDummy = finishResponse != null && finishResponse.isDummy;
    }

    async function endImpersonation() {
        if (state.isImpersonating) {
            try {
                await impersonationService.end();
            } catch (error) {
                console.log(error);
            }
        }

        state.active = false;
        state.isImpersonating = false;
        state.customerName = null;
        state.customerIsDummy = false;
        fetchBasket();
        fetchQuoteBasket();
    };

    function fetchBasket() {
        store.dispatch('basket/fetchBasket');
    }
    function fetchQuoteBasket() {
        store.dispatch('quotebasket/fetchQuoteBasket');
    }

    return { impersonation, isActiveSalesConsultant, isImpersonating, canAssignManualDiscount, impersonatingDummyCustomer, noImpersonation, finishImpersonation, endImpersonation };
};
