declare var window: any;

class LoggingService {
    public debug(logObject: any): void {
        console.log(logObject);
    }

    public info(logObject: any) {
        console.info(logObject);
    }

    public warn(logObject: any) {
        console.warn(logObject);
    }

    public error(logObject: any) {
        console.error(logObject);
    }

    public exception(logObject: any, e: any) {
        console.error(logObject, e);
    }
}

export default new LoggingService();
