import Http from '@/core/http/http.service';
import DashboardViewModel = Vertica.LouisPoulsen.Application.ProductRegistration.Responses.ProductRegistrationDashboardViewModel;
import ProductRegistrationAddOrUpdateProductWebRequest = Vertica.LouisPoulsen.Application.ProductRegistration.Requests.ProductRegistrationAddOrUpdateProductWebRequest;
import RegisteredProductViewModel = Vertica.LouisPoulsen.Application.ProductRegistration.Responses.RegisteredProductViewModel;
import UpdateProductStatusWebRequest = Vertica.LouisPoulsen.Application.ProductRegistration.Requests.UpdateProductStatusWebRequest;
import DeleteProductWebRequest = Vertica.LouisPoulsen.Application.ProductRegistration.Requests.DeleteProductWebRequest;
import ProductViewObject = Vertica.LouisPoulsen.Application.ProductRegistration.Responses.ProductViewObject;
import { ProductRegistrationMode } from '@/enums';

import { StorageService } from '@/core';
import * as qs from 'qs';

class ProductRegistrationService {
    public beginProductRegistrationKey = 'productregistration_flow_begin';

    getProducts(): Promise<DashboardViewModel> {
        return Http.get<DashboardViewModel>('ProductRegistrationProducts/GetAll');
    }

    async search(term: string) {
        const result = await Http.cancelableRequest<ProductRegistrationProductTileViewModel[]>('GET',
            `search/ProductRegistrationSearchSuggest?term=${term}`,
            'productRegistrationSearchSuggest');
        return result;
    }

    async getProductStatus(serialNumber: string) {
        // TODO: Save content in local storage?
        const searchResults = await Http.get<ProductRegistrationProductTileViewModel[]>('ProductRegistrationProducts/status', { id: serialNumber });

        StorageService.setItemAs<RegisterProductViewModel>(this.beginProductRegistrationKey, {
            mode: ProductRegistrationMode.SerialNumber,
            request: {
                serialNumber,
                comment: '',
                sapProductId: '',
                dateBought: new Date(),
                name: '',
                colour: '',
                productDealer: '',
                type: ''
            },
            productOptions: searchResults
        });
    }

    async getProductDetails(productIds: string[]) {
        const details = await Http.get<Vertica.LouisPoulsen.Application.ProductRegistration.Responses.ProductRegistrationProductDetailsWebResponse>('ProductRegistrationProducts/details', { ids: productIds }, {
            paramsSerializer: function(params) {
                return qs.stringify(params, { arrayFormat: 'repeat' });
            }
        });
        return details;
    }

    selectProductAfterSearch(product: ProductRegistrationProductTileViewModel) {
        this.addProductBySearch({
            colour: '',
            comment: '',
            dateBought: new Date(),
            name: product.displayName,
            sapProductId: product.productId,
            productDealer: '',
            serialNumber: '',
            type: product.category
        }, product);
    }

    private addProductBySearch(request: ProductRegistrationAddOrUpdateProductWebRequest, searchResult: ProductRegistrationProductTileViewModel) {
        // TODO: Save content in local storage?
        StorageService.setItemAs<RegisterProductViewModel>(this.beginProductRegistrationKey, {
            mode: ProductRegistrationMode.CatalogSearch,
            request,
            productOptions: [searchResult]
        });
    }

    getViewModel() : RegisterProductViewModel {
        const state = StorageService.getItemAs<RegisterProductViewModel>(this.beginProductRegistrationKey);
        state.request.dateBought = new Date(state.request.dateBought);
        return state;
    }

    deleteState() {
        StorageService.removeItem(this.beginProductRegistrationKey);
    }

    submitAddProductRequest(request: ProductRegistrationAddOrUpdateProductWebRequest): Promise<ProductViewObject> {
        return Http.post<ProductViewObject>('ProductRegistrationProducts/add', request);
    }

    submitUpdateProductRequest(request: ProductRegistrationAddOrUpdateProductWebRequest, id: string): Promise<boolean> {
        return Http.patch<boolean>('ProductRegistrationProducts/update?id=' + id, request);
    }

    getProduct(id: string): Promise<RegisteredProductViewModel> {
        return Http.get<RegisteredProductViewModel>('ProductRegistrationProducts/Get?id=' + id);
    }

    getRetailers(): Promise<string[]> {
        return Http.get<string[]>('ProductRegistrationProducts/retailers');
    }

    updateProductStatus(id: string, statusId: number): Promise<boolean> {
        const request: UpdateProductStatusWebRequest = {
            id,
            statusId
        };
        return Http.post<boolean>('ProductRegistrationProducts/status', request);
    }

    deleteProduct(id: string): Promise<boolean> {
        const request: DeleteProductWebRequest = { id };
        return Http.post<boolean>('ProductRegistrationProducts/delete', request);
    }
}

export default new ProductRegistrationService();
