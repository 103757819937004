
import Vue from 'vue';
import Component from 'vue-class-component';
import {
    CatalogGetter,
    ProductConfiguratorMultiColorProperty
} from '@/store';
import {
    filter,
    uniqBy,
    forEach,
    find,
    intersection,
    isEmpty
} from 'lodash';
import MultiColorViewObject = Vertica.LouisPoulsen.Application.Content.Pages.Product.MultiColorViewObject;
import VariantListHeading = Vertica.LouisPoulsen.Application.Content.Pages.Product.VariantListViewModel.VariantListHeading;
import ProductVariantList from '@/ui/views/product-details/ProductVariantList.vue';
import { Prop } from 'vue-property-decorator';
import SelectCtrlMultiple, { selectedOption } from '@/ui/shared/form/SelectCtrlMultiple.vue';

    @Component({
        name: 'product-variant-sort-list',
        components: {
            ProductVariantList,
            SelectCtrlMultiple
        }
    })
export default class ProductVariantSortList extends Vue {
        @Prop({
            required: true,
            type: Array
        }) productVariantsList: ProfessionalVariantViewObject[];

        @CatalogGetter
        public productVariantLisHeaders: VariantListHeading[];

        private selectedFilters: {[key:string]: string[] | MultiColorViewObject[]} = {};
        private lastActiveFilter: string = '';
        private lastActiveFilterOptions: string[] | MultiColorViewObject[] = [];
        private filteredProductVariants = [];
        private productConfiguratorMultiColorProperty = ProductConfiguratorMultiColorProperty;

        get columnsWithValues(): VariantListHeading[] {
            return this.productVariantLisHeaders.filter(productVariantHeader => {
                return !this.productVariantsList.every(productVariant => {
                    return productVariant[productVariantHeader.key] === null || productVariant[productVariantHeader.key] === '-';
                });
            });
        }

        mounted() {
            this.setDefaultFilters();
            this.filterProductVariants();
        }

        private setDefaultFilters():void {
            const filters = {};
            forEach(this.productVariantLisHeaders, column => filters[column.key] = []);
            this.selectedFilters = filters;
        }

        private filterProductVariants() {
            const variantsForFilterNames = [];
            const filterCopy = { ...this.selectedFilters };
            forEach(filterCopy, (values, key) => {
                if (values.length === 0) {
                    delete filterCopy[key];
                } else {
                    let variantsForFilterName = [];
                    forEach(values, value => {
                        /*
                         * Using ES6 destructing and set, to create an array that is a combination of the previous filterResult (key, value combination) and a filtering of the complete list of product variants
                         * Destructing or "..." is a way to assign a value to another variable, while following the syntax
                         * "new Set()" is similar to concat, but does not allow duplicates.
                         * Read more about the code used here: https://codeburst.io/how-to-merge-arrays-without-duplicates-in-javascript-91c66e7b74cf#a902
                         */
                        if (key === ProductConfiguratorMultiColorProperty) {
                            variantsForFilterName = [
                                ...new Set([
                                    ...variantsForFilterName,
                                    ...this.productVariantsList.filter(variant => {
                                        return !!find(variant[ProductConfiguratorMultiColorProperty], { code: (value as MultiColorViewObject).code });
                                    })
                                ])
                            ];
                        } else {
                            variantsForFilterName = [...new Set([...variantsForFilterName, ...filter(this.productVariantsList, [key, value])])];
                        }
                    });
                    variantsForFilterNames.push(variantsForFilterName);
                }
            });
            if (isEmpty(filterCopy)) {
                this.filteredProductVariants = this.productVariantsList;
                return;
            }
            // using "spread" (...) to spread the array of arrays that is in the "variantsForFilterNames" variable to be only arrays.
            this.filteredProductVariants = intersection(...variantsForFilterNames);
        }

        private getPropertyOptions(type:string):string[] |MultiColorViewObject[] {
            if (type === ProductConfiguratorMultiColorProperty) {
                return this.colorOptions;
            } else {
                return uniqBy(this.filteredProductVariants.filter(v => v[type] !== null), type).map(v => v[type]);
            }
        }

        private onFilterSelected(filter: selectedOption): void {
            const filterIsEmpty: boolean = !!filter.value && !(filter.value as []).length;
            /*
             * If the selected filter is not in the same dropdown as the last one.
             * We set the new filter to be the last active
             * And lock its options to what is has when the filter was selected.
             * This is done so, while the user selects filters, the options in the remaining dropdowns become fewer, providing a better overview of what is available
             */
            if (!this.isLastActiveFilter(filter.key)) {
                this.lastActiveFilter = filter.key;
                this.lastActiveFilterOptions = this.getPropertyOptions(filter.key);
            }
            this.filterProductVariants();
            if (filterIsEmpty) {
                this.lastActiveFilterOptions = this.getPropertyOptions(filter.key);
            }
        }

        private isLastActiveFilter(filterKey: string): boolean {
            return this.lastActiveFilter === filterKey;
        }

        private get colorOptions():MultiColorViewObject[] {
            let options = [];

            this.filteredProductVariants.forEach(v => {
                options = options.concat(v[ProductConfiguratorMultiColorProperty]);
            });
            return uniqBy(options, 'code');
        }
}

