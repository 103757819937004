
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { TranslateMixin } from '@/core/translate.mixin';

@Component
export default class CopyToClipBoardButton extends Mixins<TranslateMixin>(TranslateMixin) {
    @Prop({
        required: true,
        type: String
    }) valueToCopy: string;

    @Prop({
        required: false,
        type: String,
        default: ''
    }) title: string;

    copyCompleted: boolean = false;
    copyCompletedTitle: string = this.t('Shared.Buttons.CopyToClipBoardCompleted');

    get buttonTitle(): string {
        return this.title || this.t('Shared.Buttons.CopyToClipBoard');
    }

    copyToClipboard(): void {
        /* This method will copy the input string "value" to the users clipboard.
         * It is done by adding an input tag to the don with the "value" set to the input string.
         * Then selecting that value and executing the "copy" command on document.
         * It is done in this way instead of using "Clipboard API" because, according to MDN, there are/can be some issues depending on browsers.
         */
        const input = document.createElement('textArea') as HTMLTextAreaElement;
        input.setAttribute('class', 'u-absolute u-bottom-0 u-w-px u-h-0 u-overflow-hidden u-pointer-events-none');
        input.value = this.valueToCopy;
        document.body.appendChild(input);
        input.select();
        document.execCommand('copy');
        document.body.removeChild(input);

        this.copyCompleted = true;
        setTimeout(() => {
            this.copyCompleted = false;
        }, 1500)
    }
}
