import { GetterTree, MutationTree, ActionTree } from 'vuex';
import { namespace } from 'vuex-class';
import ProductService from '../services/product.service';

enum MutationMethod {
    toggleSearching = 'toggleSearching',
    setSearchResult = 'setSearchResult',
    setHasSearched = 'setHasSearched'
}

const storeState: ISearchState = {
    searchResult: {} as SearchResultsViewModel,
    isSearching: false,
    hasSearched: false
};

const getters: GetterTree<ISearchState, any> = {
    searchResult: state => state.searchResult,
    isSearching: state => state.isSearching,
    hasSearched: state => state.hasSearched
};

const actions: ActionTree<ISearchState, any> = {
    async search({ commit }, searchTerm: string) {
        commit(MutationMethod.toggleSearching);
        commit(MutationMethod.setHasSearched, false);
        commit(MutationMethod.setSearchResult, {});
        try {
            const result = await ProductService.search(searchTerm);
            commit(MutationMethod.toggleSearching);
            commit(MutationMethod.setSearchResult, result);
            commit(MutationMethod.setHasSearched, true);
            return;
        } catch (error) {
            commit(MutationMethod.toggleSearching);
        } finally {
            return await Promise.resolve();
        }
    },
    resetSearch({ commit }) {
        commit(MutationMethod.setSearchResult, {});
    }
};

const mutations: MutationTree<ISearchState> = {
    [MutationMethod.setSearchResult](state, result: SearchResultsViewModel) {
        state.searchResult = result;
    },
    [MutationMethod.setHasSearched](state, flag: boolean) {
        state.hasSearched = flag;
    },
    [MutationMethod.toggleSearching](state) {
        state.isSearching = !state.isSearching;
    }
};

export default {
    namespaced: true,
    state: storeState,
    getters,
    actions,
    mutations
};

const moduleName = 'search';
const moduleObject = namespace(moduleName);

export const SearchGetter = moduleObject.Getter;
export const SearchMutation = moduleObject.Mutation;
export const SearchAction = moduleObject.Action;
export const SearchState = moduleObject.State;
