import Http from '../../../core/http/http.service';
import {
    SalesDocumentMobileOverlayType,
    SalesDocumentTableConfig
} from '@/ui/views/salesdocumenttable/salesdocument.types';
import DictionaryService from '@/core/dictionary.service';

export class OrderService {

    public getStatusOptions(): any {
        return [
            { name: DictionaryService.get('Pages.PartnerPortal.Orders.OrderPage.OrderStatusAll'), value: '0' },
            { name: DictionaryService.get('Pages.PartnerPortal.Orders.OrderPage.OrderStatusOpen'), value: '1' },
            { name: DictionaryService.get('Pages.PartnerPortal.Orders.OrderPage.OrderStatusShipped'), value: '2' }
        ];
    }

    public getStatusOption(value: string): any {
        return this.getStatusOptions().find((x) => x.value === value);
    }


    public async orders(request: OrderSearchWebRequest) : Promise<{orderData: OrderSearchViewModel}> {
        const url = 'order/search';

        return Http.cancelableRequest('POST', url, 'orderRequest', request)
            .then((response: OrderSearchViewModel) => {

                return {
                    orderData: response
                };
            });
    }

    public tableConfig(allowSortingAndFiltering:boolean = true): SalesDocumentTableConfig {
        return {
            rowKey: 'orderNo',
            columns: [
                {
                    headerLabel: 'Pages.PartnerPortal.Orders.OrderPage.OrderTableOrderNo',
                    widthClass: 'u-w-5/24 md:u-w-2/24',
                    ...(allowSortingAndFiltering && {sortField: 'orderNo'}),
                    valueField: 'orderNo',
                    valueClass: 'table-bold'
                },
                {
                    headerLabel: '',
                    widthClass: 'sub-md:u-hidden u-w-1/24 u-pr-2',
                    component: 'icon-pdf',
                    linkField: 'orderPdfLink',
                    linkTarget: '_blank',
                    hideContentField: 'orderPdfLink',
                    hiddenContentReplacement: 'N/A'
                },
                {
                    headerLabel: 'Pages.PartnerPortal.Orders.OrderPage.OrderTableReference',
                    widthClass: 'sub-md:u-hidden u-flex-1',
                    ...(allowSortingAndFiltering && {sortField: 'reference'}),
                    valueField: 'reference',
                },
                {
                    headerLabel: 'Pages.PartnerPortal.Orders.OrderPage.OrderTableCreationDate',
                    widthClass: 'sub-md:u-text-small sub-md:u-flex-1 u-w-4/24',
                    ...(allowSortingAndFiltering && {sortField: 'creationDateSortable'}),
                    valueField: 'creationDate',
                },
                {
                    headerLabel: 'Pages.PartnerPortal.Orders.OrderPage.OrderTableNetValue',
                    widthClass: 'sub-md:u-text-small sub-md:u-w-7/24 u-w-4/24',
                    ...(allowSortingAndFiltering && {sortField: 'netValueSortable'}),
                    valueField: 'netValue',
                },
                {
                    headerLabel: 'Pages.PartnerPortal.Orders.OrderPage.OrderTableStatus',
                    widthClass: 'sub-md:u-hidden u-w-4/24',
                    ...(allowSortingAndFiltering && {sortField: 'status'}),
                    valueField: 'status',
                }
            ],
            defaultSort: {
                sortBy: 'creationDateSortable',
                direction: 'desc'
            },

            filterFields: allowSortingAndFiltering ? [
                'orderNo',
                'reference',
                'creationDate',
                'netValue',
                'customerName',
                'lines.variantId',
                'lines.description',
                'lines.netValue',
                'lines.invoiceNumber',
                'lines.reference',
            ] : [],

            subTableField: 'lines',
            subTableConfig: {
                rowKey: 'position',
                mobileOverlayType: SalesDocumentMobileOverlayType.order,
                columns: [
                    {
                        headerLabel: 'Pages.PartnerPortal.Orders.OrderPage.OrderTableLinePos',
                        widthClass: 'u-w-1/24',
                        valueField: 'position',
                    },
                    {
                        headerLabel: '',
                        widthClass: 'u-w-70p md:u-mr-5',
                        imageField: 'imageUrl',
                        imagesField: 'images',
                        imageSize: 70,
                        hiddenContentReplacement: 'N/A'
                    },
                    {
                        headerLabel: 'Pages.PartnerPortal.Orders.OrderPage.OrderTableLineMaterial',
                        widthClass: 'u-w-3/24 u-pr-2',
                        valueField: 'variantId',
                        linkField: 'pdpLink',
                        salesInfoField: 'variantId'
                    },
                    {
                        headerLabel: 'Pages.PartnerPortal.Orders.OrderPage.OrderTableLineDescription',
                        widthClass: 'u-flex-1 u-pr-2',
                        valueField: 'description',
                        valueClass: 'u-capitalize',
                        linkField: 'pdpLink'
                    },
                    {
                        headerLabel: 'Pages.PartnerPortal.Orders.OrderPage.OrderTableLineQuantity',
                        widthClass: 'u-w-1/24 u-pr-2',
                        valueField: 'quantity',
                        valueClass: 'u-inline-block u-bg-black u-px-2 u-rounded-full u-text-white u-text-small u-leading-body'
                    },
                    {
                        headerLabel: 'Pages.PartnerPortal.Orders.OrderPage.OrderTableLineNetValue',
                        widthClass: 'u-w-3/24 u-pr-2',
                        valueField: 'netValue',
                    },
                    {
                        headerLabel: 'Pages.PartnerPortal.Orders.OrderPage.OrderTableLineReference',
                        widthClass: 'u-flex-1 u-pr-2',
                        valueField: 'reference',
                    },
                    {
                        headerLabel: 'Pages.PartnerPortal.Orders.OrderPage.OrderTableLineInvoiceNo',
                        widthClass: 'u-w-3/24 u-pr-2',
                        valueField: 'invoiceNumber',
                        linkField: 'invoiceUrl'
                    },
                    {
                        headerLabel: 'Pages.PartnerPortal.Orders.OrderPage.OrderTableLineShipping',
                        widthClass: 'u-w-2/24 u-pr-2',
                        valueField: 'shipping'
                    },
                    {
                        headerLabel: 'Pages.PartnerPortal.Orders.OrderPage.OrderTableLineTrace',
                        widthClass: 'u-w-1/24',
                        component: 'icon-trace',
                        linkField: 'traceUrl',
                        linkTarget: '_blank',
                        hideContentField: 'traceUrl',
                        hiddenContentReplacement: 'N/A'
                    }
                ]
            }
        }
    }

    public tableConfigOrdersByMaterial(allowSortingAndFiltering:boolean = true): SalesDocumentTableConfig {
        return {
            rowKey: 'material',
            columns: [
                {
                    headerLabel: '',
                    widthClass: 'u-w-70p md:u-mr-5',
                    imageField: 'imageUrl',
                    imagesField: 'images',
                    imageSize: 70,
                    hiddenContentReplacement: 'N/A'
                },
                {
                    headerLabel: 'Pages.PartnerPortal.Orders.OrderPage.OrderTableLineMaterial',
                    widthClass: 'u-w-3/24 u-pr-2',
                    valueField: 'material',
                    ...(allowSortingAndFiltering && {sortField: 'material'}),
                    linkField: 'pdpLink'
                },
                {
                    headerLabel: 'Pages.PartnerPortal.Orders.OrderPage.OrderTableLineDescription',
                    widthClass: 'u-flex-shrink u-flex-grow-2 u-flex-basis-0 u-pr-2',
                    valueField: 'description',
                    ...(allowSortingAndFiltering && {sortField: 'description'}),
                    valueClass: 'u-capitalize',
                    linkField: 'pdpLink'
                },
                {
                    headerLabel: 'Pages.PartnerPortal.Orders.OrderPage.OrderTableLineQuantity',
                    widthClass: 'u-w-1/24 u-flex-1 u-pr-2',
                    valueField: 'quantity',
                    valueClass: 'u-inline-block u-bg-black u-px-2 u-rounded-full u-text-white u-text-small u-leading-body'
                }
            ],
            defaultSort: {
                sortBy: 'material',
                direction: 'desc'
            },

            filterFields: allowSortingAndFiltering ? [
                'material',
                'description',
                'orderLines.orderNo',
                'orderLines.orderReference',
                'orderLines.reference',
                'orderLines.invoiceNumber',
                'orderLines.shipping',
            ] : [],

            subTableField: 'orderLines',
            subTableConfig: {
                rowKey: '',
                mobileOverlayType: SalesDocumentMobileOverlayType.order,
                columns: [
                    {
                        headerLabel: 'Pages.PartnerPortal.Orders.OrderPage.OrderTableOrderNo',
                        widthClass: 'u-w-5/24 md:u-w-2/24',
                        ...(allowSortingAndFiltering && {sortField: 'orderNo'}),
                        valueField: 'orderNo'
                    },
                    {
                        headerLabel: 'Pages.PartnerPortal.Orders.OrderPage.OrderTableReference',
                        widthClass: 'sub-md:u-hidden u-flex-1',
                        ...(allowSortingAndFiltering && {sortField: 'reference'}),
                        valueField: 'orderReference',
                    },
                    {
                        headerLabel: 'Pages.PartnerPortal.Orders.OrderPage.OrderTableLinePos',
                        widthClass: 'u-w-1/24',
                        valueField: 'position',
                    },
                    {
                        headerLabel: 'Pages.PartnerPortal.Orders.OrderPage.OrderTableLineQuantity',
                        widthClass: 'u-w-1/24 u-pr-2',
                        valueField: 'quantity',
                        valueClass: 'u-inline-block u-bg-black u-px-2 u-rounded-full u-text-white u-text-small u-leading-body'
                    },
                    {
                        headerLabel: 'Pages.PartnerPortal.Orders.OrderPage.OrderTableLineNetValue',
                        widthClass: 'u-w-3/24 u-pr-2',
                        valueField: 'netValue',
                    },
                    {
                        headerLabel: 'Pages.PartnerPortal.Orders.OrderPage.OrderTableLineReference',
                        widthClass: 'u-flex-1 u-pr-2',
                        valueField: 'reference',
                    },
                    {
                        headerLabel: 'Pages.PartnerPortal.Orders.OrderPage.OrderTableLineInvoiceNo',
                        widthClass: 'u-w-3/24 u-pr-2',
                        valueField: 'invoiceNumber',
                        linkField: 'invoiceUrl'
                    },
                    {
                        headerLabel: 'Pages.PartnerPortal.Orders.OrderPage.OrderTableLineShipping',
                        widthClass: 'u-w-2/24 u-pr-2',
                        valueField: 'shipping'
                    },
                    {
                        headerLabel: 'Pages.PartnerPortal.Orders.OrderPage.OrderTableLineTrace',
                        widthClass: 'u-w-1/24',
                        component: 'icon-trace',
                        linkField: 'traceUrl',
                        linkTarget: '_blank',
                        hideContentField: 'traceUrl',
                        hiddenContentReplacement: 'N/A'
                    }
                ]
            }
        }
    }
}

export const orderService = new OrderService();
