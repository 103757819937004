import Http from '@/core/http/http.service';
import YourStoriesFormBlockWebRequest = Vertica.LouisPoulsen.Application.Content.Requests.YourStoriesFormBlockWebRequest;

class TraderFormService {
    submitFormOptions(request: TraderSignupFormBlockWebRequest): Promise<boolean> {
        return Http.post<boolean>('trader/SubmitSignup', request);
    }

    submitYourStories(request: YourStoriesFormBlockWebRequest): Promise<boolean> {
        return Http.post<boolean>('trader/SubmitYourStories', request);
    }
}

export default new TraderFormService();
