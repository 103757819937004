
import { AppGetter } from '@/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({
    name: 'checkbox',
    $_veeValidate: {
        name() {
            return this.name;
        },
        value() {
            return this.value;
        },
        rejectsFalse: true
    }
})
export default class Checkbox extends Vue {
    public _uid: any; // Vue provided uniq ID

    @Prop({ type: String, required: true })
    public name: string;

    @Prop({ type: String, required: false })
    public text: string;

    @Prop()
    public value: boolean;

    @Prop()
    public disabled: boolean;

    @Prop()
    public error: string;

    @Prop()
    public customStyling: string;

    @Prop()
    public checkmarkColor: string;

    @Prop({ default: false })
    public displayErrorUnder: boolean;

    @AppGetter
    public textColor: (opacity: number) => string;

    @AppGetter
    public theme: ColorTheme;

    get checkmarkStyle() {
        if (this.theme.textColor) {
            const background = this.checkmarkColor || this.theme.pageBackgroundColor.replace('#', '%23') || 'white';
            return `.c-checkbox-${this._uid} span.c-checkbox__checkbox-mark {
               background-image: url("data:image/svg+xml,%3Csvg width='7' height='6' viewBox='0 0 7 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 3L3 5L6 1' stroke='${background}'/%3E%3C/svg%3E%0A");
            }
            `;
        }
        return '';
    }
}
