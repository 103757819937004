import Http from "../core/http/http.service";


class ReturnformService {
    submitFormOptions(request: ReturnProductPageWebRequest ): Promise<ReturnProductPageWebRequest > {
        return Http.post<ReturnProductPageWebRequest >("returnproductpage/sendreturnproductform", request);
    }
}

export default new ReturnformService();

