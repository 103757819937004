
    import Vue from "vue";
    import { Prop } from 'vue-property-decorator';
    import Component from "vue-class-component";

    @Component({
        name: "hr-on-recruit",
        components: {}
    })
    export default class HrOnRecruit extends Vue {

        @Prop({ default: false })
        public addTopMargin: boolean;

        @Prop()
        public scriptPath: string;

        public mainScriptLoaded :boolean = false;

        public onMainScriptLoaded():void {
            this.mainScriptLoaded = true;
        }

        get url() : string {
            // this script must be requested from the server every time the page loads
            let cacheBust = new Date().getTime();
            if(this.scriptPath && this.scriptPath.length && this.scriptPath.length > 0) {
                return `${this.scriptPath}?v=${cacheBust}`;
            } else { // fallback
                return `https://recruit.hr-on.com/frame-api/customers/louispoulsenas.js?v=${cacheBust}`;
            }
        }

    }
