
import { AppGetter } from '@/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({
    name: 'text-field',
    $_veeValidate: {
        name() {
            return this.name;
        },
        value() {
            return this.value;
        }
    }
})
export default class TextField extends Vue {
    @Prop() public label: string;

    @Prop({ type: Boolean, default: false })
    public disabled: boolean;

    @Prop({ type: Boolean, default: false })
    public getQuoteStyling: boolean;

    @Prop({ default: '' }) public value: string;

    @Prop() public error: string;

    @Prop() public name: string;

    @Prop() public placeholder: string;

    @AppGetter
    public textColor: (opacity: number) => string;

    @AppGetter
    public borderColor: (opacity: number, defaultClass: string) => string;

    @AppGetter
    public theme: ColorTheme;

    get hasValue() {
        return !!this.value;
    }

    public updateValue(e) {
        this.$emit('input', e.target.value);
    }

    get height() {
        const baseHeight = 34;
        let lineCount = (this.value || '').split(/\r*\n/).length;
        if (lineCount === 1) {
            lineCount = 0;
        }
        return (baseHeight + (lineCount * 20)) + 'px';
    }
}
