
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import ProductVariantSortList from '@/ui/views/product-details/variant-configurator/ProductVariantSortList.vue';
import { AppGetter, CatalogGetter } from '@/store';
import { groupBy } from 'lodash';

@Component({
    components: {
        ProductVariantSortList
    }
})
export default class ProductVariantSorting extends Vue {
    @AppGetter
    public theme: ColorTheme;

    @CatalogGetter
    public productVariants: ProfessionalVariantViewObject[];

    get groupProductVariants(): boolean {
        return this.productVariants.some(productVariant => productVariant.partType !== null);
    }

    get groupedProductVariants(): { [key: string]: ProfessionalVariantViewObject[] } {
        return groupBy(this.productVariants.filter(productVariant => productVariant.partType !== null), 'partType');
    }
}
