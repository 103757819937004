/**
 * Composable `useWebpFeatureCheck`
 *
 * This composable provides reactive state and utilities to check the browser's support for various WebP image features,
 * including lossy compression, lossless compression, and alpha transparency. It performs these checks asynchronously
 * on component initialization using the official Google WebP feature-checking methodology, slightly modernized to use
 * TypeScript and Promises.
 *
 * The composable returns the following reactive properties:
 * - `isWebpLossySupported`: `boolean | undefined` - Whether the browser supports WebP lossy compression. `undefined` indicates the check hasn't completed yet.
 * - `isWebpLosslessSupported`: `boolean | undefined` - Whether the browser supports WebP lossless compression. `undefined` indicates the check hasn't completed yet.
 * - `isWebpAlphaSupported`: `boolean | undefined` - Whether the browser supports WebP alpha transparency. `undefined` indicates the check hasn't completed yet.
 * - `areAllWebpFeaturesSupported`: `boolean | undefined` - Whether the browser supports all three WebP features (lossy, lossless, alpha). `undefined` indicates that not all checks have completed yet.
 * - `areWebpChecksCompleted`: `boolean` - Whether all WebP feature checks have been completed.
 *
 * Usage example:
 * import { useWebpFeatureCheck } from '@/composables/useWebpFeatureCheck';
 * const { isWebpLossySupported, isWebpLosslessSupported, isWebpAlphaSupported, areAllWebpFeaturesSupported, areWebpChecksCompleted } = useWebpFeatureCheck();
 *
 * @returns {object} Reactive properties for WebP feature support checks.
 */

import { ref, computed } from 'vue';

const lossy = ref<boolean | undefined>();
const lossless = ref<boolean | undefined>();
const supportsAlpha = ref<boolean | undefined>();

// Official google feature checker (check_webp_feature) tweaked and modernized a bit, to use typescript and promises
type WebpFeature = 'lossy' | 'lossless' | 'alpha';
function checkWebpFeature(feature: WebpFeature): Promise<boolean> {
    return new Promise((resolve) => {
        const kTestImages = {
            lossy: 'UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA',
            lossless: 'UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA==',
            alpha: 'UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAARBxAR/Q9ERP8DAABWUDggGAAAABQBAJ0BKgEAAQAAAP4AAA3AAP7mtQAAAA=='
        };
        const img = new Image();
        img.onload = function() {
            resolve((img.width > 0) && (img.height > 0));
        };
        img.onerror = function() {
            resolve(false);
        };
        img.src = 'data:image/webp;base64,' + kTestImages[feature];
    });
}

async function checkWebpFeatures() {
    lossy.value = await checkWebpFeature('lossy');
    lossless.value = await checkWebpFeature('lossless');
    supportsAlpha.value = await checkWebpFeature('alpha');
}

checkWebpFeatures();

const checksCompleted = computed(() => {
    return ((lossy.value !== undefined) && (lossless.value !== undefined) && (supportsAlpha.value !== undefined));
});

const areAllWebpFeaturesSupported = computed(() => {
    return ((lossy.value === true) && (lossless.value === true) && (supportsAlpha.value === true));
});

export const useWebpFeatureCheck = () => {
    return {
        isWebpLossySupported: lossy,
        isWebpLosslessSupported: lossless,
        isWebpAlphaSupported: supportsAlpha,
        areAllWebpFeaturesSupported,
        areWebpChecksCompleted: checksCompleted
    };
};
