
interface Clipboard {
    writeText(newClipText: string): Promise<void>;
}

// navigator.clipboard is not available during build, hence necessitating a cast to an interface where clipboard is nullable.
interface NavigatorClipboard {
    // Only available in a secure context.
    readonly clipboard?: Clipboard;
}

class ClipboardService {
    public async copyToClipboard(content: string) {
        if ((navigator as NavigatorClipboard).clipboard) {
            await (navigator as NavigatorClipboard).clipboard.writeText(content);
            return;
        }

        const input = document.createElement('textArea') as HTMLTextAreaElement;
        input.setAttribute('class', 'u-absolute u-bottom-0 u-w-px u-h-0 u-overflow-hidden u-pointer-events-none');
        input.value = content;
        document.body.appendChild(input);
        input.select();
        document.execCommand('copy');
        document.body.removeChild(input);
    }
}

export default new ClipboardService();
