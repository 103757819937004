import { onMounted, onBeforeUnmount } from 'vue';
import { useRootElement } from './rootElement';

export function useOnSelfResize(callback: () => void) {
    const { rootElement } = useRootElement();
    const resizeObserver = new ResizeObserver(() => {
        callback();
    });
    onMounted(() => {
        if (rootElement.value) {
            resizeObserver.observe(rootElement.value);
        }
    });
    onBeforeUnmount(() => resizeObserver.disconnect());
}
