import Http from '../../../core/http/http.service';
import ICreateUsernamePasswordForm from '@/ui/views/createusernamepassword/createusernamepasswordform.interface';
import CreateUsernamePasswordWebRequest = Vertica.LouisPoulsen.Application.Authentication.Requests.CreateUsernamePasswordWebRequest;
import CreateUsernamePasswordViewModel = Vertica.LouisPoulsen.Application.Authentication.ViewModels.CreateUsernamePasswordViewModel;

class CreateUsernamePasswordService {
    public async createUsernamePassword(formData: ICreateUsernamePasswordForm, token: string): Promise<CreateUsernamePasswordViewModel> {
        const { username, password, confirmPassword } = formData;

        const request: CreateUsernamePasswordWebRequest = {
            token,
            username,
            password,
            confirmPassword
        };

        return Http.post('authentication/username-and-password', request)
            .then((response: CreateUsernamePasswordViewModel) => {
                return response;
            });
    }
}

export default new CreateUsernamePasswordService();
