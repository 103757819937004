import axios, { AxiosResponse, AxiosRequestConfig, CancelTokenSource } from 'axios';
import HttpStatus from 'http-status-codes';
import Emitter, { emitterEvents } from '../emitter.service';
import VersionInterceptor from './version.interceptor';
import RedirectInterceptor from './redirect.interceptor';
import ApiResponseGoogleTagManagerInterceptor from './api-response-google-tag-manager.interceptor';

interface IClientResult<T> {
    model: T;
    displayErrorMessage: boolean;
}

interface IMessage {
    value: string,
    type: number
}

interface IClientErrorResult {
    messages: IMessage[];
}

export class Http {
    private pageCancelToken = null;
    private cancelTokens: { [key: string]: CancelTokenSource } = {};
    private responseInterceptors: Array<(value: AxiosResponse<any>) => AxiosResponse<any> | Promise<AxiosResponse<any>>> = [
        VersionInterceptor,
        RedirectInterceptor,
        ApiResponseGoogleTagManagerInterceptor
    ];

    private requestInterceptors: Array<(value: AxiosRequestConfig) => AxiosRequestConfig | Promise<AxiosRequestConfig>> = [];

    constructor() {
        this.registerInterceptors();
    }

    public async page(relativeUrl: string): Promise<IPageResult> {
        // cancel existing page requests
        if (this.pageCancelToken) {
            this.pageCancelToken.cancel(`Page request cancelled by new request to ${relativeUrl}`);
        }
        // generate a new cancel token for this request
        this.pageCancelToken = axios.CancelToken.source();
        let res: AxiosResponse<PageDataViewModel>;
        try {
            res = await axios.get<PageDataViewModel>(relativeUrl, {
                headers: { Accept: 'application/json' },
                params: { xhr: true },
                cancelToken: this.pageCancelToken.token
            });
        } catch (error) {
            if (error.response.status === 404 && error.response.data) {
                return { data: error.response.data, statusCode: 404 };
            }
            throw error;
        }
        // erase the token when the request completes
        this.pageCancelToken = null;
        return { data: res.data, statusCode: res.status };
    }

    public async get<T>(relativeUrl: string, params?: any, config?: AxiosRequestConfig): Promise<T> {
        const requestConfig = params ? { ...config, params } : config;
        return axios
            .get(this.getUrl(relativeUrl), requestConfig)
            .then(res => this.handleResponse(res))
            .catch(err => this.handleErrorResponse(err));
    }

    public async put<T>(relativeUrl: string, payload?: any, config?: AxiosRequestConfig): Promise<T> {
        return axios
            .put(this.getUrl(relativeUrl), payload, config)
            .then(res => this.handleResponse(res))
            .catch(err => this.handleErrorResponse(err));
    }

    public async patch<T>(relativeUrl: string, payload?: any, config?: AxiosRequestConfig): Promise<T> {
        return axios
            .patch(this.getUrl(relativeUrl), payload, config)
            .then(res => this.handleResponse(res))
            .catch(err => this.handleErrorResponse(err));
    }

    public async post<T>(relativeUrl: string, payload?: any, config?: AxiosRequestConfig): Promise<T> {
        return axios
            .post(this.getUrl(relativeUrl), payload, config)
            .then(res => this.handleResponse(res))
            .catch(err => this.handleErrorResponse(err));
    }

    public async remove<T>(relativeUrl: string): Promise<T | undefined> {
        return axios
            .delete(this.getUrl(relativeUrl))
            .then(res => this.handleResponse(res))
            .catch(err => this.handleErrorResponse(err));
    }

    public async cancelableRequest<T>(method: string, relativeUrl: string, cancelToken: string, payload?: any): Promise<T> {
        if (this.cancelTokens[cancelToken]) { this.cancelTokens[cancelToken].cancel(); }

        this.cancelTokens[cancelToken] = axios.CancelToken.source();

        return axios.request({
            method,
            headers: { Accept: 'application/json' },
            url: this.getUrl(relativeUrl),
            cancelToken: this.cancelTokens[cancelToken].token,
            data: payload
        })
            .then(res => this.handleResponse(res))
            .catch(err => {
                return this.handleErrorResponse(err);
            });
    }

    private handleResponse<T>(response: AxiosResponse<T>) : T {
        if (response.status === HttpStatus.NO_CONTENT) {
            return;
        }
        if (response.status !== HttpStatus.OK) {
            this.handleNotOk(response);
        }
        return this.modelOrEmpty(response);
    }

    private handleErrorResponse(error: any): any {
        if (axios.isCancel(error)) { return; }

        const data = error.response ? error.response.data : null;

        if (error.response.status === 401 && !data) {
            window.location.reload();
            return;
        }

        if (data && this.instanceOfClientErrorResult(data)) {
            data.messages.forEach(message => Emitter.emit(emitterEvents.ApiErrorEvent, message.value));
        } else if (this.instanceOfIClientResult(data)) {
            return this.dataModel(error.response);
        } else {
            Emitter.emit(emitterEvents.ApiErrorEvent);
        }
        throw new Error(error);
    }

    private modelOrEmpty<T>(response: AxiosResponse<T>) : T {
        return this.instanceOfIClientResult(response.data) ? this.dataModel(response) : this.emptyModel();
    }

    private emptyModel<T>() {
        return {} as T;
    }

    private dataModel<T>(response: AxiosResponse) {
        return response.data.model as T;
    }

    private instanceOfIClientResult<T>(object: any): object is IClientResult<T> {
        return 'model' in object;
    }

    private instanceOfClientErrorResult(object: any): object is IClientErrorResult {
        return !('model' in object) && 'messages' in object;
    }

    private handleNotOk(response: AxiosResponse): void {
        // todo handle statuses
        switch (response.status) {
        case 304:
            break;
        default:
            break;
        }
        throw new Error(response.statusText);
    }

    private getUrl(relativeUrl: string): string {
        relativeUrl = this.removeLeadigSlash(relativeUrl);
        return `/api/${relativeUrl}`;
    }

    private removeLeadigSlash(url: string) {
        return url.charAt(0) === '/' ? url.substr(1) : url;
    }

    private registerInterceptors() {
        this.responseInterceptors.forEach(i => axios.interceptors.response.use(i));
        this.requestInterceptors.forEach(i => axios.interceptors.request.use(i));
    }
}

export default new Http();
